import { YAXisComponentOption } from 'echarts'
import { GenericSolidField } from '../types'
import { hasSecondYAxis } from '../utils'
import { niceAlign, niceTicks } from '../utils/NiceScale'
import { GenericChartData } from './GenericChart'

const DefaultBoundaryGap = undefined // ['5%', '5%']

const getLimit = (fieldNames: string[], ds: any) => {
  let min: number | undefined
  let max: number | undefined
  ds.source.forEach((row: any) => {
    fieldNames.forEach(fieldName => {
      if (row[fieldName] != null) {
        if (min === undefined || min > row[fieldName]) {
          min = row[fieldName]
        }
        if (max === undefined || max < row[fieldName]) {
          max = row[fieldName]
        }
      }
    })
  })
  return [min, max]
}

const calcScale = (fields: GenericSolidField[], ds: any[]) => {
  const [min, max] = getLimit(fields.map(field => field.label), ds)
  if (min === undefined || max === undefined) {
    return undefined
  }

  return niceTicks(min, max)
}

const hasYAxis = (props: GenericChartData) => {
  return props.visType.type !== 'pie'
}

const setupYAxis = (props: GenericChartData, ds: any): YAXisComponentOption | YAXisComponentOption[] | undefined => {
  let yAxis: YAXisComponentOption | YAXisComponentOption[] | undefined = undefined

  // pie chart doesn't have y axis
  if (hasYAxis(props)) {
    if (hasSecondYAxis(props)) {
      const _leftScale = calcScale(props.fields.filter(field => !field.onSecondYAxis), ds)
      const _rightScale = calcScale(props.fields.filter(field => field.onSecondYAxis), ds)
      const [lscale, rscale] = niceAlign(_leftScale, _rightScale)
      if (lscale === undefined && rscale === undefined) {
        return [{ type: 'value' }, { type: 'value' }]
      }
      yAxis = [{
        name: props.fields.filter(field => !field.onSecondYAxis).map(field => field.label).join(','),
        type: 'value',
        ...lscale
      }, {
        name: props.fields.filter(field => field.onSecondYAxis).map(field => field.label).join(','),
        type: 'value',
        ...rscale
      }]
    } else {
      yAxis = {
        type: 'value',
        boundaryGap: DefaultBoundaryGap,
        scale: true,
      }
    }
  }

  return yAxis
}

export default setupYAxis
