import * as echarts from 'echarts'
import { FieldVisType } from '../types'
import { hasSecondYAxis } from '../utils'
import { GenericChartData } from './GenericChart'

type ChartFieldVisTypeName = Exclude<FieldVisType['type'], 'table'>

const normalizeSeriesType = (series: ChartFieldVisTypeName | undefined, chart: GenericChartData['visType']['type']): ChartFieldVisTypeName => {
  if (chart === 'pie') {
    return chart
  } else {
    if (series && ['line', 'bar', 'scatter'].includes(series)) {
      return series
    } else {
      return chart
    }
  }
}

const encode = (type: ChartFieldVisTypeName, legend: string, dimension: string) => {
  if (type === 'pie') {
    return {
      itemName: legend,
      value: dimension
    }
  } else {
    return {
      x: legend,
      y: dimension
    }
  }
}

const setupSeries = (props: GenericChartData, dataset: echarts.DatasetComponentOption): echarts.SeriesOption[] => {
  if (props.xAxis.index === 'field' && props.legend.index === 'field') {
    return [{
      name: '',
      type: props.visType.type,
      encode: encode(props.visType.type, '_field', 'value'),
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      animation: false
    }]
  }

  const legendName =  dataset.dimensions![0] as string
  const seriesNames = (dataset.dimensions! as string[]).slice(1)
  return seriesNames.map(seriesName => {
    if (props.fields.length > 0) {
      const field = props.fields.find(f => f.label === seriesName) || props.fields[0]
      const fieldVisTypeName = field.visType?.type as (ChartFieldVisTypeName | undefined)
      const series: echarts.SeriesOption = {
        name: (hasSecondYAxis(props) && field.onSecondYAxis) ? seriesName + '(右轴)' : seriesName,
        type: normalizeSeriesType(fieldVisTypeName, props.visType.type),
        areaStyle: field.visType?.type === 'line' ? field.visType?.areaStyle : undefined,
        showSymbol: false,
        connectNulls: true,
        encode: encode(fieldVisTypeName || props.visType.type, legendName, seriesName),
        yAxisIndex: (hasSecondYAxis(props) && field.onSecondYAxis) ? 1 : 0,
        label: { show: false, formatter: '{b}' },
        animation: false
      }
      return series
    } else {
      return {}
    }
  })
}

export default setupSeries
