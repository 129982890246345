import _ from "lodash"
import React from 'react'
import shortid from 'shortid'

export interface WidgetProps<T> {
  widgetId: string
  widgetData: T
}

export interface IValueProvider<T> {
  getValue: () => Promise<T> | T
}

export type WidgetEditorProps<T> = {
  value: T
  setValue: React.Dispatch<React.SetStateAction<T>>
  setValueProvider: (provider: IValueProvider<T> | null) => void
}

export type WidgetType = string

export type WidgetMeta<T> = {
  widgetType: WidgetType
  widget: React.ComponentType<WidgetProps<T>>
  name: string
  description?: string
  insertable?: boolean
  editor?: React.ComponentType<WidgetEditorProps<T>>
  defaultProps: T
}

const WIDGET_INDEX: {
  [widgetType: WidgetType]: WidgetMeta<any>
} = {
}

export function registerWidget<T>(info: WidgetMeta<T>) {
  WIDGET_INDEX[info.widgetType] = info
  return info
}

export const getWidgetMeta = (type: string): WidgetMeta<any> | undefined => {
  return WIDGET_INDEX[type]
}

export const getInsertableWidgetMetas = () => {
  return _.values(WIDGET_INDEX).filter(winfo => winfo.insertable)
}

export const generateWidgetId = (widgetType: WidgetType) => {
  return widgetType + '_' + shortid.generate()
}

export const isWidgetType = (widgetId: string, widgetType: WidgetType) => {
  return widgetId.startsWith(widgetType + '_')
}
