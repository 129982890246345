import React from 'react'
import { withEditBar } from './EditBar'
import { WidgetProps, WidgetEditorProps, registerWidget } from "./WidgetRegistry"
import { EuiMarkdownFormat, EuiMarkdownEditor } from "@elastic/eui"
import { TextBoxData } from '../Services/WidgetTypes'


export const TextBox = React.memo(({ widgetData }: WidgetProps<TextBoxData>) => {
  return <EuiMarkdownFormat>{widgetData.content}</EuiMarkdownFormat>
})

const TextBoxEditor = ({ value, setValue }: WidgetEditorProps<TextBoxData>) => {
  return (
      <EuiMarkdownEditor
        style={{ width: 800 }}
        height={600}
        aria-label="TextBox editor"
        value={value.content} onChange={content => setValue({ content })}></EuiMarkdownEditor>)
}


// registerWidget({
//   widgetType: "TextBox",
//   widget: TextBox,
//   name: "文本框",
//   insertable: true,
//   defaultProps: {
//     content: ""
//   }
// })

registerWidget(withEditBar({
  widgetType: "TextBox",
  widget: TextBox,
  editor: TextBoxEditor,
  name: "文本框",
  insertable: true,
  defaultProps: {
    content: ""
  }
}))
