import moment from 'moment'
import React from 'react'
import '@elastic/eui/dist/eui_theme_light.css'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { config as configRzApi } from './Services/Api'

configRzApi({
  appVer: `Web ${process.env.REACT_APP_VERSION}`,
  salt: "cfc82114-a8a5-40f4-ace7-2546c290060f"
})

require('moment/locale/zh-cn')
moment.locale('zh-cn')

const render = () => ReactDOM.render(<App />, document.getElementById('root'))

render()

if ((module as any).hot) {
  (module as any).hot.accept('./App', render)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
