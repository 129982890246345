import { GenericChartData } from './GenericChart'

const setupToolbox = (props: GenericChartData): echarts.EChartsOption['toolbox'] => {
  return {
    show: false,
    feature: {
      dataZoom: { // "区域缩放"和"区域缩放重置"功能
        show: true,
        yAxisIndex: false
      },
      dataView: {
        show: true,
        readOnly: true,
        optionToContent: function (opt: any) {
          const ds = opt.dataset[0]
          let table = `
            <table class="euiTable chart-data-view">
              <tbody>
                <tr>
                  ${ds.dimensions.map((dim: string) => '<th>' + dim + '</th>').join('')}
                </tr>`
          for (let i = 0, l = ds.source.length; i < l; i++) {
            const rowData = ds.source[i]
            table += `
                <tr>
            ${ds.dimensions.map((dim: string) => {
              const formatCell = (value: string | number) => {
                if (typeof(value) === 'number') {
                  return value.toFixed(2)
                } else {
                  return value ?? ''
                }
              }
              if (dim === '_month-day') {
                return '<td>' + formatCell(rowData[dim]).slice(5) + '</td>'
              } else {
                return '<td>' + formatCell(rowData[dim]) + '</td>'
              }
            }).join('')}
                </tr>`
          }
          table += `
              </tbody>
            </table>`
          return table
        }
      }
    }
  }
}

export default setupToolbox
