import { DropResult, EuiButtonEmpty, EuiButtonIcon, EuiDragDropContext, EuiDraggable, EuiDroppable, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { useContext } from 'react'
import { GenericVisContext } from '../../GenericVisStore'

export type DataFieldsProps = {
  selected: number
  onSelect: (selected: number) => void
}

const DataFields = ({
  selected,
  onSelect
}: DataFieldsProps) => {
  const state = useContext(GenericVisContext)

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (source && destination) {
      if (source.droppableId === destination.droppableId) {
        state.actions.dragDropReorderFields(source.index, destination.index)
      }
    }
  }

  return <EuiDragDropContext onDragEnd={onDragEnd}>
    <EuiDroppable droppableId="DataFields" type="DataFields">
      {state.pref.fields.map((field, index) =>
        <EuiDraggable key={index} draggableId={index.toString()} index={index} customDragHandle={true} spacing="s">
          {provided =>
            <EuiFlexGroup key={index} gutterSize="none" alignItems="baseline">
              <EuiFlexItem grow={false} {...provided.dragHandleProps}>
                <EuiIcon type="sortable" style={{ margin: 3 }} />
              </EuiFlexItem>
              <EuiFlexItem grow={true} >
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                      size="s"
                      onClick={() => onSelect(index)}
                      isSelected={index === selected}
                      isDisabled={state.isSingleField && index !== 0}>
                      {field.label || `数据项${String.fromCharCode(65 + index)}`}
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                  <EuiFlexItem grow={true}></EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon aria-label="delete" iconType="trash" onClick={() => { state.actions.removeField(index) }} />
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        </EuiDraggable>
      )}
    </EuiDroppable>
  </EuiDragDropContext>
}

export default DataFields
