import { EChartsOption } from 'echarts'
import { GenericChartData } from './GenericChart'

const colors_24 = [
  '#c12e34',
  '#e6b600',
  '#0098d9',
  '#2b821d',
  '#005eaa',
  '#b2df8a',
  '#6a3d9a',
  '#fb9a99',
  '#ff7f00',
  '#cab2d6',
  '#c12e34',
  '#e6b600',
  '#0098d9',
  '#2b821d',
  '#005eaa',
  '#b2df8a',
  '#6a3d9a',
  '#fb9a99',
  '#ff7f00',
  '#cab2d6',
  '#c12e34',
  '#e6b600',
  '#0098d9',
  '#2b821d'
]

const setupColor = (props: GenericChartData, series: EChartsOption['series']) => {
  if (series) {
    const seriesCount = (series as any).length || 0
    if (props.legend.index !== 'field') {
      if (seriesCount > 0) {
        return colors_24.slice(0, seriesCount).reverse()
      }
    }
  }
  return colors_24
}

export default setupColor
