import { XAXisComponentOption } from 'echarts'
import _ from 'lodash'
import moment from 'moment'
import { canUseTimeXAxis, isSeasonType } from '../utils'
import { GenericChartData } from './GenericChart'

const DefaultTimeLabelFormatter = {
  year: '{yyyy}',
  month: '{MMM}',
  day: '{d}',
  hour: '{HH}:{mm}',
  minute: '{HH}:{mm}',
  second: '{HH}:{mm}:{ss}',
  millisecond: '{hh}:{mm}:{ss} {SSS}',
  none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
}

const MonthDayFormatter = {
  year: '',
  month: '{MMMM}', // 一月、二月、……
  day: '{d}日', // 1日、2日、……
  hour: '',
  minute: '',
  second: '',
  millisecond: ''
}

const hasXAxis = (props: GenericChartData): boolean => {
  return props.visType.type !== 'pie'
}
const setupXAxis = (props: GenericChartData): XAXisComponentOption | undefined => {
  let xAxis: XAXisComponentOption | undefined

  // pie chart doesn't have x axis
  if (hasXAxis(props)) {
    xAxis = {}
    if (isSeasonType(props)) {
      xAxis = {
        type: 'time',
        axisPointer: { snap: true },
        minInterval: 1 * 3600 * 24 * 1000,
        maxInterval: 30 * 3600 * 24 * 1000,
        interval: 30 * 3600 * 24 * 1000,
        axisLabel: {
          formatter: (value: number) => {
            const m = moment(value)
            return m.date() === 1
              ? m.format('M月')
              : m.format('{[tiny|]MM-DD}')
          },
          rich: {
            tiny: { fontSize: 9 },
          }
        }
      }
    } else if (canUseTimeXAxis(props.xAxis.index)) {
      xAxis = {
        type: 'time',
        axisLabel: { formatter: DefaultTimeLabelFormatter }
      }
      if (_.isEqual(props.xAxis.index, ['month', 'day'])) {
        xAxis.axisLabel!.formatter = MonthDayFormatter
      }
    } else {
      xAxis = { type: 'category' }
    }
    xAxis.inverse = props.xAxis.inverse
  }
  return xAxis
}

export default setupXAxis
