import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiTitle } from '@elastic/eui'
import { useContext } from 'react'
import { LayoutSectionData } from '../../Services/WidgetTypes'
import { PageIdContext, pageStoreActions, usePageInfo } from '../../Stores/PageStore'
import { registerWidget, WidgetProps } from "../WidgetRegistry"

export const LayoutSection = ({ widgetId, widgetData }: WidgetProps<LayoutSectionData>) => {
  const pageId = useContext(PageIdContext)
  const isEditing = usePageInfo(pageId, page => page?.isEditing)

  const setText = (text: string) => {
    pageStoreActions.setWidgetData(pageId, widgetId, { text })
  }

  const renderText = () => <EuiTitle size="s"><h3>{widgetData.text}</h3></EuiTitle>
  const renderEditor = () => <EuiFieldText value={widgetData.text} onChange={e => setText(e.target.value)}></EuiFieldText>

  return (
    <EuiFlexGroup gutterSize="none" justifyContent="spaceAround" className="layout-section">
      <EuiFlexItem grow={false} style={{ margin: 12 }}>
        {isEditing ? renderEditor() : renderText()}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

registerWidget<LayoutSectionData>({
  widgetType: "LayoutSection",
  name: "标题",
  widget: LayoutSection,
  defaultProps: { text: "标题" }
})
