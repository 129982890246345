import { EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiText } from '@elastic/eui'
import { useContext } from 'react'
import { GenericVisContext } from '../../GenericVisStore'
import { DateHierarchy } from '../../utils/HierarchicalDate'

type Frequency = 'monthly' | 'quarterly' | 'yearly'

const h2f = (h: DateHierarchy[]): Frequency => {
  if (h.includes('month')) {
    return 'monthly'
  }
  if (h.includes('quarter')) {
    return 'quarterly'
  }
  return 'yearly'
}

const f2h = (f: Frequency): DateHierarchy[] => {
  switch (f) {
    case 'monthly':
      return ['year', 'month']
    case 'quarterly':
      return ['year', 'quarter']
    default:
      return ['year']
  }
}

const options = [
  { value: 'yearly', text: '年度' },
  { value: 'quarterly', text: '季度' },
  { value: 'monthly', text: '月度' }
]

const VerticalTablePanel = () => {
  const state = useContext(GenericVisContext)
  return (
    <EuiPanel style={{ overflowY: 'auto' }} paddingSize="s">
      <EuiText>图表</EuiText>
      <EuiForm>
        <EuiFormRow label="标题" display="rowCompressed">
          <EuiFieldText
            value={state.pref.title}
            onChange={e => state.actions.setTitle(e.target.value)}
            compressed />
        </EuiFormRow>
        <EuiFormRow label="频率" display="rowCompressed">
          <EuiSelect
            value={h2f(state.pref.legend.index as DateHierarchy[])}
            options={options}
            onChange={f => {
              state.actions.setLegendIndex(f2h(f.target.value as Frequency))
            }}
            compressed />
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  )
}

export default VerticalTablePanel
