import create from 'zustand'

import { createHub, useHubIsConnected } from '../Utils/RemoteMessageHub'

export type CopiedWidgetSketch = { id: string, type: string, props: any }

export const useAppUtilityStore = create(() => ({
  copiedWidget: null as CopiedWidgetSketch | null
}))

const hub = createHub('/api/app-utility', false)

export function useAppUtilityHubState() {
  return useHubIsConnected(hub)
}

hub.on('OnWidgetCopied', (widgetData: string) => {
  useAppUtilityStore.setState({
    copiedWidget: JSON.parse(widgetData)
  })
})

hub.on('OnWidgetUncopied', (widgetData: string) => {
  useAppUtilityStore.setState({
    copiedWidget: null
  })
})

async function connectHub() {
  await hub.connect()
}

async function disconnectHub() {
  await hub.disconnect()
}

async function setCopiedWidget(widget: CopiedWidgetSketch) {
  const str = JSON.stringify(widget)
  hub.send('SetCopiedWidget', str)
}

export const appUtilityStoreActions = {
  connectHub,
  disconnectHub,
  setCopiedWidget
}
