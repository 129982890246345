import { EuiPopover } from '@elastic/eui'
import { nextTick } from 'process'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createEditor, Descendant, Editor, Element as SlateElement, Path, Range, Text, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from 'slate-react'
import rzApi, { DataDefSearchResult } from '../../Services/Api'
import { formulaInputDataDefsEvent } from '../../Services/MessageBus'
import DataDefSelectable from './DataDefSelectable'
import { RenderCustomElementProps, RenderDataDefSearchProps } from './types'

const DataDefEditor = {
  ...Editor,
  insertSearch: (editor: Editor) => {
    const el: SlateElement = { type: 'dataDefSearch', children: [{ text: '' }] }
    Transforms.insertNodes(editor, el)
    Transforms.move(editor)
  },
  insertDataDef: (editor: Editor, dataDef: DataDefSearchResult) => {
    Transforms.insertText(editor, `{${dataDef.id}}`)
  },
  doneSearch: (editor: Editor) => {
    let searchPath: Path | undefined
    for (const [node, path] of Editor.nodes(editor, { at: [0] })) {
      const el = node as SlateElement
      if (el.type === 'dataDefSearch') {
        searchPath = path
        break
      }
    }
    if (searchPath) {
      Transforms.removeNodes(editor, { at: searchPath })
    }
  },
}

const DataDefSearchElementComp = ({ attributes, children, element, editor }: RenderDataDefSearchProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const closePopover = () => {
    setIsOpen(false)
    nextTick(() => {
      DataDefEditor.doneSearch(editor)
    })
  }
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      closePopover()
      nextTick(() => {
        ReactEditor.focus(editor)
      })
    }
  }
  return (
    <span>
      <EuiPopover
        button={<></>}
        anchorPosition="downLeft"
        isOpen={isOpen}
        onKeyDown={onKeyDown}
        closePopover={closePopover}
      >
        <DataDefSelectable
          onChange={def => {
            DataDefEditor.insertDataDef(editor, def)
            closePopover()
            nextTick(() => {
              ReactEditor.focus(editor)
            })
          }} />
      </EuiPopover>
      {children}
    </span>
  )
}

const isFillWithEmpty = (id: string | undefined) => {
  return id && id.endsWith('!')
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  const title = useMemo(() => {
    if (leaf.name) {
      if (isFillWithEmpty(leaf.id)) {
        return leaf.name + '（空值填充）'
      } else {
        return leaf.name
      }
    } else {
      return leaf.id
    }
  }, [leaf.name, leaf.id])
  return (
    <span
      {...attributes}
      title={title}
      style={{
        ...(leaf.id
          ? {
            margin: '0 1px',
            padding: '3px 3px 2px',
            verticalAlign: 'baseline',
            display: 'inline-block',
            borderRadius: '4px',
            fontWeight: 'bold',
            fontSize: '0.9em',
          }
          : {}),
      }}
    >
      {children}
    </span>
  )
}

const Element = (props: RenderCustomElementProps) => {
  const { attributes, children, element } = props
  switch (element.type) {
    case 'dataDefSearch':
      return <DataDefSearchElementComp {...props as RenderDataDefSearchProps} />
    default:
      return <span {...attributes}>{children}</span>
  }
}

const withSingleLine = (editor: Editor) => {
  const { normalizeNode } = editor
  // editor.insertBreak = () => { }
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor)
      }
    }
    return normalizeNode([node, path])
  }
  return editor
}

const withDataDef = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor

  editor.isInline = element => {
    return (element.type === 'dataDefSearch') ? true : isInline(element)
  }

  editor.isVoid = element => {
    return (element.type === 'dataDefSearch') ? true : isVoid(element)
  }

  return editor
}

const useEditor = () => {
  return useMemo(
    () =>
      withDataDef(
        withSingleLine(
          withHistory(
            withReact(
              createEditor())))),
    []
  )
}

const useDecorate = () => {
  const [names, setNames] = useState<{ [id: string]: string }>({})

  useEffect(() => {
    const subscription = formulaInputDataDefsEvent.subscribe(ids => {
      const f = async () => {
        if (ids.length > 0) {
          const dataDefs = await rzApi.getDataDefs(ids)
          dataDefs.forEach(dataDef => {
            names[dataDef.Id] = dataDef.Name
          })
          setNames({ ...names })
        }
      }
      f()
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [names, setNames])

  return useCallback(([node, path]) => {
    const ranges: Range[] = []
    if (!Text.isText(node)) {
      return ranges
    }
    const regex = /\{\s*([\w\d]+)\s*(!?)\s*\}/g
    let match = regex.exec(node.text)
    const newRawIds: Set<string> = new Set()
    while (match) {
      const dataDef = {
        id: match[1] + match[2],
        rawId: match[1],
      }
      if (!names[dataDef.rawId]) {
        newRawIds.add(dataDef.rawId)
      }
      ranges.push({
        id: dataDef.id,
        rawId: dataDef.rawId,
        name: names[dataDef.rawId],
        anchor: { path, offset: match.index },
        focus: { path, offset: regex.lastIndex }
      })
      match = regex.exec(node.text)
    }
    if (newRawIds.size > 0) {
      // 为了在useEffect(formulaInputDataDefsEvent.subscribe)之后再调用formulaInputDataDefsEvent.next，这里加个延时
      setTimeout(() => {
        formulaInputDataDefsEvent.next([...newRawIds])
      }, 1)
    }
    return ranges
  }, [names])
}

export type FormulaInputProps = {
  className?: string
  initialValue: Descendant[]
  placeholder?: string
  isInvalid?: boolean
  onChange: (value: Descendant[]) => void
  onBlur: () => void
}

const FormulaInput = ({
  className,
  initialValue,
  placeholder,
  isInvalid,
  onChange,
  onBlur
}: FormulaInputProps) => {
  const [value, setValue] = useState<Descendant[]>(initialValue)
  const editor = useEditor()
  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} editor={editor} />, [editor])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const handleChange = useCallback(value => {
    setValue(value)
    onChange(value)
  }, [onChange])
  const decorate = useDecorate()

  return <Slate
    editor={editor}
    value={value}
    onChange={handleChange}>
    <Editable
      className={className}
      style={{
        width: '100%',
        fontSize: 14,
        overflow: 'hidden',
        ...(isInvalid ? {
          backgroundImage: 'linear-gradient(to top, #BD271E, #BD271E 2px, transparent 2px, transparent 100%)',
          backgroundSize: '100%'
        } : {})
      }}
      placeholder={placeholder}
      renderLeaf={renderLeaf}
      renderElement={renderElement}
      decorate={decorate}
      onBlur={onBlur}
      onKeyDown={event => {
        if (event.ctrlKey) {
          switch (event.key) {
            case '/':
              event.preventDefault()
              DataDefEditor.insertSearch(editor)
              break
          }
        } else {
          switch (event.key) {
            case 'Enter':
              onBlur()
              break
          }
        }
      }} />
  </Slate>
}

export default FormulaInput
