import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui'
import { useContext, useState } from 'react'
import { GenericVisContext } from '../../GenericVisStore'
import { DateOrOffset } from '../../utils/DateOrOffset'
import DataFields from './DataFields'
import DateOrOffsetPicker from './DateOrOffsetPicker'
import FieldEditor from './FieldEditor'

export const DateRangePanel = () => {
  const state = useContext(GenericVisContext)

  const onStartDateChange = (dateOrOffset: DateOrOffset) => {
    state.actions.setDateRange({ ...state.pref.dateRange, start: dateOrOffset })
  }

  const onEndDateChange = (dateOrOffset: DateOrOffset) => {
    state.actions.setDateRange({ ...state.pref.dateRange, end: dateOrOffset })
  }

  return (
    <EuiPanel paddingSize="s" grow={false}>
      <EuiText>数据范围</EuiText>
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem>
          <DateOrOffsetPicker prefix="从" value={state.pref.dateRange.start} onChange={onStartDateChange} />
        </EuiFlexItem>
        <EuiFlexItem>
          <DateOrOffsetPicker prefix="到" value={state.pref.dateRange.end} onChange={onEndDateChange} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

const DataPanel = () => {
  const state = useContext(GenericVisContext)
  const [selected, setSelected] = useState(-1)

  const titleWithButton = (
    <EuiFlexGroup justifyContent="spaceBetween" gutterSize="none" alignItems="baseline" >
      <EuiFlexItem><EuiText>数据项列表</EuiText></EuiFlexItem>
      <EuiFlexItem style={{ marginRight: 6 }} grow={false} className={state.pref.fields.length === 0 ? 'notice-me' : undefined}>
        <EuiButtonEmpty iconType="plus" size="xs" onClick={() => {
          state.actions.addNewField()
          setSelected(state.pref.fields.length - 1)
        }}>添加数据项</EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const renderFieldEditorPanel = () => (
    selected >= 0
      ? <FieldEditor
        field={state.pref.fields[selected]}
        visType={state.pref.visType}
        onChange={field => { state.actions.setField(selected, field) }}
        onDismiss={() => { setSelected(-1) }} />
      : null)

  return (<>
    {renderFieldEditorPanel()}
    {selected === -1 ?
      <>
        <DateRangePanel />
        <EuiPanel paddingSize="s" style={{ overflowY: 'auto', marginTop: 6 }} grow={true}>
          {titleWithButton}
          <DataFields
            selected={selected}
            onSelect={setSelected}
          />
        </EuiPanel>
      </> : null}
  </>)
}

export default DataPanel
