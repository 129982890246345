import React, { useMemo } from 'react';
import _ from 'lodash'

type Span = [number, number, number, number]

const parseCellCoord = (coord: string): [number, number] =>
  [parseInt(coord.slice(1)) - 1, coord.charCodeAt(0) - 65]

const parseTableSpan = (rt: string | Span) : Span => {
  if (typeof(rt) === 'string') {
    const parts = rt.split(':')
    const lt = parseCellCoord(parts[0])
    const rb = parseCellCoord(parts[1])
    return [ lt[0], lt[1], rb[0], rb[1] ]
  } else {
    return rt
  }
}

const isCulledBySpan = (span: Span, row: number, col: number) => {
  const [r1, c1, r2, c2] = span
  if (span && r1 <= row && r2 >= row && c1 <= col && c2 >= col) {
    return r1 !== row || c1 !== col
  } else {
    return false
  }
}

const isCulledBySpans = (spans: Span[] | undefined, row: number, col: number) =>
  _.some(spans, span => isCulledBySpan(span, row, col))

const getSpan = (spans: Span[] | undefined, row: number, col: number) =>
  _.find(spans, span => span[0] === row && span[1] === col)

const getSpanRows = (span: Span | undefined) => {
  if (span) {
    const [r1, _c1, r2, _c2] = span
    return span && r2 > r1 ? r2 - r1 + 1 : undefined
  }
}
const getSpanCols = (span: Span | undefined) => {
  if (span) {
    const [_r1, c1, _r2, c2] = span
    return span && c2 > c1 ? c2 - c1 + 1 : undefined
  }
}

interface TableCellProps {
  className?: string
  rowSpan?: number
  colSpan?: number
  data?: string | number | JSX.Element
  color?: string
  style?: React.CSSProperties
}
const TableCell = ({className, rowSpan, colSpan, data, color, style}: TableCellProps) => {
  return (
    <td className={"euiTableRowCell " + (className ? className : "")}
        rowSpan={rowSpan} colSpan={colSpan}
        style={style}>
      <div style={{ minHeight: 16, color }}>{data}</div>
    </td>
  )
}

interface TableProps {
  title?: string
  header?: JSX.Element
  className?: string
  rows: number
  cols: number
  data: (row: number, col: number) => string | number | undefined | JSX.Element
  spans?: (string | Span)[]
  cellClassNames?: (row: number, col: number) => string | undefined
  cellColor?: (row: number, col: number) => string | undefined
  cellStyle?: (row: number, col: number) => React.CSSProperties | undefined
}
export const Table = ({ title, className, header, rows, cols, spans: _spans, data, cellClassNames, cellColor, cellStyle}: TableProps) => {
  const spans = useMemo(() => {
    if (!_spans) {
      return
    }
    return _spans.map(parseTableSpan)
  }, [_spans])

  return <table className={(className || "") + " euiTable"}>
      {
        !header && title ? <thead><tr><th colSpan={cols} className="title-cell">{ title }</th></tr></thead> : null
      }
      { header }
      <tbody>{
        _.range(rows).map(row =>
          <tr key={row} className="euiTableRow">{
            _.range(cols).map(col => {
              if (isCulledBySpans(spans, row, col)) {
                return null
              } else {
                const curSpan = getSpan(spans, row, col)
                return (
                  <TableCell key={col}
                    className={cellClassNames ? cellClassNames(row, col) : undefined}
                    rowSpan={getSpanRows(curSpan)}
                    colSpan={getSpanCols(curSpan)}
                    data={data(row, col)}
                    color={cellColor ? cellColor(row, col) : undefined}
                    style={cellStyle ? cellStyle(row, col): undefined}/>
                )
              }
            })
          }</tr>
        )
      }</tbody>
    </table>
}