import { DateOrOffsetRange } from "./utils/DateOrOffset"
import { DateHierarchy } from "./utils/HierarchicalDate"

export type VisLine = {
  type: 'line'
  areaStyle?: any
}
export type VisBar = {
  type: 'bar'
}
export type VisPie = {
  type: 'pie'
}
export type VisScatter = {
  type: 'scatter'
}
export type ColorsArray = Array<'up' | 'down' | 'future' | 'row_min'>
export type ConditionalFormatting = 'gradientFill' | 'solidFill' | 'colorScales'
export type VisTable = {
  type: 'table'
  colors?: ColorsArray
  conditionalFormatting?: ConditionalFormatting
  head?: number
}
export type VisType = VisLine | VisBar | VisPie | VisTable | VisScatter
export type FieldVisType = VisLine | VisBar | VisPie | VisTable | VisScatter

export type ChartTemplateProps = {
  name: string
  icon: string
  fixed?: boolean
}

export const ChartTemplateNameMap = {
  DailyChart: { name: '日度图', icon: 'visLine' },
  SeasonChart: { name: '季节图', icon: 'visAreaStacked' },
  HorizontalTable: { name: '横向表', icon: 'visTable' },
  VerticalTable: { name: '平衡表', icon: 'tableDensityCompact' },
  Custom: { name: '自定义', icon: 'user' },
  WarrantPriceTable: { name: '仓单价格表', icon: 'visTable', fixed: true },
}

export type ChartTemplate = keyof typeof ChartTemplateNameMap

export type AggMethod = 'sum' | 'first' | 'last' | 'mean'

export type AxisIndex = DateHierarchy[] | 'field'

export type GenericVisField = {
  label: string
  expr: string
  visType?: FieldVisType
  aggMethod?: AggMethod // default is 'last'
  onSecondYAxis?: boolean
  numberFormat?: string // default '0.00'
}

export type GenericVisPreference = {
  title: string
  template?: ChartTemplate
  visType: VisType
  dateRange: DateOrOffsetRange
  fields: GenericVisField[]
  xAxis: {
    index: AxisIndex
  }
  legend: {
    index: AxisIndex
  }
}

export type GenericSolidField = GenericVisField & {
  values: (number | null)[]
}

export type GenericSolidData = Omit<GenericVisPreference,
  'fields' | 'legend'> & {
    fields: GenericSolidField[]
    xAxis: {
      index: AxisIndex
      sortByValue?: 'asc' | 'desc' // 默认用第一列值
      inverse?: boolean
    }
    legend: {
      index: AxisIndex
      type?: 'legend' | 'slicer'
    }
    keepNullRows?: boolean
    showUpdateDate?: boolean
    skipWeekend?: boolean
  }
