import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import SuperTabs, { SuperTab } from '../Components/SuperTabs'
import rzApi from '../Services/Api'
import { EmptyPage, pageStoreActions, useActivePageInfo, usePageStore } from '../Stores/PageStore'
import { useSubheader } from '../Stores/UiStore'
import { usePrivilege } from '../Stores/UserStore'
import DataPage from './DataPage'

interface TopicParams {
  topicId: string,
  pageId?: string
}

const validatePageUrl = async (pageUrl: string): Promise<SuperTab | null> => {
  try {
    const url = new URL(pageUrl)
    const pathname = url.pathname + url.hash
    const matched = matchPath<{ pageId: string }>(pathname, {
      path: '/#/page/:pageId/'
    }) || matchPath<TopicParams>(pathname, {
      path: '/#/topic/:topicId/:pageId?'
    })
    const pagedId = pathname === '/#/' ? 'index'
      : matched!.params.pageId!
    const page = await rzApi.getPage(pagedId)
    return { id: page.Id, name: page.Title }
  } catch (err) {
    if (err instanceof TypeError) {
      // pageUrl is not a url
      return null
    } else {
      console.error(`normalizeTab url ${pageUrl} throw ${JSON.stringify(err)}}`)
      throw err
    }
  }
}

export const TopicTabBar = () => {
  const topicId = usePageStore(state => state.topicId)
  const topicTitle = usePageStore(state => state.topicName)
  const isEditingPage = useActivePageInfo(p => p?.isEditing)
  const tabs = usePageStore(state => state.pages.map(p => ({
    id: p.pageId,
    name: p.title
  })))
  const history = useHistory()

  const switchTab = useCallback((pageId?: string) => {
    history.replace(`/topic/${topicId}${pageId ? '/' + pageId : ''}`)
  }, [history, topicId])

  const activePageId = usePageStore(s => s.activePageId)
  const hasWritePagesPrivilege = usePrivilege('WritePages')

  const bar = useMemo(() => {
    if (isEditingPage || !topicId) {
      return null
    } else {
      const onTabRearranged = async (tabs: SuperTab[]) => {
        await rzApi.updateTopic(topicId, {
          PageIds: tabs.map(tab => tab.id),
          PageNames: tabs.map(tab => tab.name)
        })
        await pageStoreActions.loadTopic(topicId)
      }

      return (
        <EuiFlexGroup gutterSize="m" alignItems="center">
          <EuiFlexItem grow={false} key="title">
            <span className="euiTab euiTab__title">
              {topicTitle}
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false} key="super-tabs">
            <SuperTabs
              key={`super-tabs-${topicId}`}
              canEdit={!!topicId && hasWritePagesPrivilege}
              tabs={tabs}
              selectedTabId={activePageId}
              tabIdPprefix="page"
              enterEditingModeButtonFn={enter =>
                <EuiButtonEmpty iconType="indexEdit" aria-label="Edit" onClick={() => {
                  enter()
                }} >添加/删除子页面</EuiButtonEmpty>}
              onTabClick={tab => switchTab(tab.id)}
              onTabCreated={async (tab: SuperTab) => {
                const refTab = await validatePageUrl(tab.name)
                if (!refTab) {
                  await rzApi.addPage(tab.id, { Title: tab.name, Content: EmptyPage })
                  return tab
                } else {
                  return refTab
                }
              }}
              onTabRearranged={onTabRearranged} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
  }, [activePageId, hasWritePagesPrivilege, isEditingPage, switchTab, tabs, topicId, topicTitle])

  useSubheader(bar)

  return null
}

const CacheablePage = (props: { pageId: string }) => {
  const [pageIds, setPageIds] = useState<string[]>([])
  const pages = usePageStore(state => state.pages)
  const activePageId = usePageStore(state => state.activePageId)

  useEffect(() => {
    setPageIds(prev => prev.filter(id => _.some(pages, p => p.pageId === id)))
  }, [pages])

  useEffect(() => {
    if (props.pageId && pageIds.indexOf(props.pageId) < 0) {
      setPageIds([...pageIds, props.pageId])
    }
  }, [pageIds, props.pageId])

  return <>
    {pageIds.map(id => (
      <DataPage key={id} pageId={id} hidden={activePageId !== id} />
    ))}
  </>
}

const TopicPage = () => {
  //const pages = usePageStore(state => state.pages)
  const activePageId = usePageStore(state => state.activePageId)
  return (
    <CacheablePage pageId={activePageId} />
  )
}

export default TopicPage
