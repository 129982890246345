import {
  EuiHeader, EuiHeaderLink, EuiText, EuiTextColor
} from '@elastic/eui'
import html2canvas from 'html2canvas'
import React, { useCallback, useState } from 'react'


export const ScreenshotToolbar = () => {
  const [generated, setGenerated] = useState(false)

  const makeScreenshot = useCallback(async () => {
    const content = document.querySelector("#page-content")
    if (!content) {
      return
    }
    const canvas = await html2canvas(content.parentElement as HTMLElement)
    const img = document.createElement('img')
    img.src = canvas.toDataURL()
    document.querySelector("#page-content")!.replaceWith(img)
    setGenerated(true)
  }, [setGenerated])

  return <>{
    generated ?
      <EuiText><h3><EuiTextColor color="accent">请复制下面的图片，发送到微信群</EuiTextColor></h3></EuiText> :
      <EuiHeader position="fixed">
        <EuiHeaderLink iconType="image" onClick={makeScreenshot}> 等页面加载完毕后，点击生成截图 </EuiHeaderLink>
      </EuiHeader>
  }</>
}
