import { EuiFieldText, EuiIcon, EuiTab, EuiTabs } from "@elastic/eui"
import _ from "lodash"
import { useCallback, useMemo, useState } from "react"
import shortid from "shortid"

type TabLabelInputProps = {
  initialValue?: string
  onConfirm: (value: string) => void
  onCancel: (value: string) => void
}

const TabLabelInput = ({
  initialValue,
  onConfirm,
  onCancel
}: TabLabelInputProps) => {
  const [value, setValue] = useState(initialValue || '')
  return (<EuiFieldText
    value={value}
    autoFocus
    onFocus={e => e.target.select()}
    onBlur={() => { onConfirm(value) }}
    onKeyDown={event => {
      switch (event.key) {
        case 'Enter':
          onConfirm(value)
          break
        case 'Escape':
          onCancel(value)
          break
      }
    }}
    onChange={e => setValue(e.target.value)}
  />)
}

export type EditableTabProps = {
  id: string
  label: string
  closable?: boolean
}

export type EditableTabsProps = {
  tabs: EditableTabProps[]
  activeTabId?: string
  onChange?: (activeTabId: string) => void
  onEdit?: (tabs: EditableTabProps[]) => void
  renamable?: boolean
}

const EditableTabs = ({
  tabs,
  activeTabId,
  renamable,
  onChange,
  onEdit
}: EditableTabsProps) => {
  const [isEditingMode, setIsEditingMode] = useState(false)

  const changeActiveTabId = useCallback((newId: string) => {
    if (newId !== activeTabId) {
      onChange && onChange(newId)
    }
  }, [onChange, activeTabId])

  const add = useCallback(() => {
    const newId = shortid.generate()
    const newTabs = [...tabs, { id: newId, label: `未命名` }]
    onEdit && onEdit(newTabs)
    changeActiveTabId(newId)
  }, [changeActiveTabId, onEdit, tabs])

  const remove = useCallback((id: string) => {
    const selectedIndex = tabs.indexOf(tabs.find(tab => tab.id === id)!)
    const newSelectedIndex = _.clamp(selectedIndex, 0, tabs.length - 2)
    const newTabs = tabs.filter(tab => tab.id !== id)
    const newId = newTabs.length > 0 ? newTabs[newSelectedIndex].id : ''
    changeActiveTabId(newId)
    onEdit && onEdit(newTabs)
  }, [changeActiveTabId, onEdit, tabs])

  const selectedTab = useMemo(() => {
    return tabs.find(tab => tab.id === activeTabId)!
  }, [activeTabId, tabs])

  return (
    <EuiTabs>
      {tabs.map(tab => (
        <EuiTab
          key={tab.id}
          isSelected={activeTabId === tab.id}
          onClick={() => {
            changeActiveTabId(tab.id)
          }}
          append={tab.closable === false ? null : <EuiIcon
            type="cross"
            size="m"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              remove(tab.id)
            }} />}
        >
          <span onDoubleClick={() => renamable && setIsEditingMode(true)}>
            {isEditingMode && activeTabId === tab.id
              ? <TabLabelInput
                initialValue={selectedTab.label}
                onConfirm={value => {
                  setIsEditingMode(false)
                  selectedTab.label = value
                  onEdit && onEdit(tabs)
                }}
                onCancel={() => { setIsEditingMode(false) }}
              />
              : (tab.label + '      ').replace(/ /g, '\u00a0')}
          </span>
        </EuiTab>
      ))}
      <EuiTab
        key="_new"
        append={<EuiIcon type="plus" size="m" />}
        onClick={add}
      />
    </EuiTabs>
  )
}

export default EditableTabs
