import React from 'react'
import { EuiHeader, EuiHeaderLink, EuiHeaderLogo, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import _ from 'lodash'
import { pageStoreActions, usePageStore } from '../Stores/PageStore'

const EditingModeToolbar = () => {
  const pageId = usePageStore(state => state.activePageId)
  const title = usePageStore(state =>
    _.find(state.pages, p => p.pageId === pageId)?.title
  )

  return <>
    <EuiHeader position="fixed">
      <EuiHeaderSection side="left">
        <EuiHeaderSectionItem border="right">
          <EuiHeaderLogo iconType="spacesApp">
            {title}
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <EuiHeaderLink
            className="primary-fill"
            onClick={() => {
              pageStoreActions.savePageContent(pageId)
              pageStoreActions.setEditMode(false)
            }}> 保存修改 </EuiHeaderLink>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiHeaderLink
            onClick={() => {
              pageStoreActions.reloadPageInfo(pageId)
              pageStoreActions.setEditMode(false)
            }}> 放弃修改 </EuiHeaderLink>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  </>
}

export default EditingModeToolbar
