import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { config as configRzApi } from './Services/Api'
import { appUtilityStoreActions } from './Stores/AppUtilityStore'
import { siteStoreActions } from './Stores/SiteStore'
import { subscriptionActions } from './Stores/SubscriptionStore'
import { thirdPartyAuthStoreActions, useIsThirdPartyAuthed } from './Stores/ThirdPartyAuthStore'
import { useIsLogined, userStoreActions } from './Stores/UserStore'

const AppInit = () => {
  const history = useHistory()
  const isLogined = useIsLogined()
  const isThirdPartyAuthed = useIsThirdPartyAuthed()

  useEffect(() => {
    configRzApi({
      onChallenge: () => {
        userStoreActions.resetCurrentUser()
        if (history.location.pathname !== '/oauth') {
          history.push("/login")
        }
      },
      onVersionTooLow: () => {
        window.location.reload()
      },
      onServerError: (status, message) => {
        document.clear()
        document.write(message)
      },
      needRefreshToken: () => {
        return !Cookies.get('AccessToken') && !!Cookies.get('RefreshToken')
      },
    })
  }, [history])

  useEffect(() => {
    if (isLogined) {
      siteStoreActions.load()
      appUtilityStoreActions.connectHub()
    }

    return () => {
      appUtilityStoreActions.disconnectHub()
    }
  }, [isLogined])

  useEffect(() => {
    siteStoreActions.load()
    userStoreActions.queryMe()
    thirdPartyAuthStoreActions.init()
    subscriptionActions.connectHub()
    return () => {
      subscriptionActions.disconnectHub()
    }
  }, [])

  useEffect(() => {
    if (isThirdPartyAuthed) {
      userStoreActions.queryMe()
    }
  }, [isThirdPartyAuthed])

  return null
}

export default AppInit
