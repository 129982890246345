import { TooltipComponentOption } from 'echarts'
import { Dictionary } from 'lodash'
import { canUseTimeXAxis, formatNumeral, hasSecondYAxis, isSeasonType } from '../utils'
import { DateHierarchy, HierarchicalDate } from '../utils/HierarchicalDate'
import { GenericChartData } from './GenericChart'

const TOOLTIP_LINE_HEIGHT_CSS = 'line-height:1'

const replaceReg = /([&<>"'])/g
const replaceMap: Dictionary<string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '\'': '&#39;'
}

function encodeHTML(source: string): string {
  return source == null
    ? ''
    : (source + '').replace(replaceReg, function (str, c) {
      return replaceMap[c]
    })
}

const wrapBlockHTML = (encodedContent: string, topGap: number = 10) => {
  const clearfix = '<div style="clear:both"></div>'
  const marginCSS = `margin: ${topGap}px 0 0`
  return `<div style="${marginCSS};${TOOLTIP_LINE_HEIGHT_CSS};">`
    + encodedContent + clearfix
    + '</div>'
}

const wrapInlineValueHTML = (value: string, style: string = '') => {
  const paddingStr = '20px'
  const alignCSS = `float:right;margin-left:${paddingStr}`
  return `<span style="${alignCSS};${style}">`
    + encodeHTML(value)
    + '</span>'
}

const wrapInlineNameHtml = (name: string, leftHasMarker: boolean, style: string = '') => {
  const marginCss = leftHasMarker ? 'margin-left:2px' : ''
  return `<span style="${marginCss};${style}">`
    + encodeHTML(name)
    + '</span>'
}

const getTooltipTextStyle = (textStyle: any = {}) => {
  const nameFontColor = textStyle.color || '#6e7079';
  const nameFontSize = textStyle.fontSize || 14;
  const nameFontWeight = textStyle.fontWeight || '400';
  const valueFontColor = textStyle.color || '#464646';
  const valueFontSize = textStyle.fontSize || 14;
  const valueFontWeight = textStyle.fontWeight || '900';
  return {
    nameStyle: `font-size:${encodeHTML(nameFontSize + '')}px;color:${encodeHTML(nameFontColor)};font-weight:${encodeHTML(nameFontWeight + '')}`,
    valueStyle: `font-size:${encodeHTML(valueFontSize + '')}px;color:${encodeHTML(valueFontColor)};font-weight:${encodeHTML(valueFontWeight + '')}`
  }
}

const formatAxisValueLabel = (label: string, props: GenericChartData) => {
  if (canUseTimeXAxis(props.xAxis.index) || isSeasonType(props)) {
    const hd = HierarchicalDate.fromString(label)
    return hd.isValid() ? hd.format(props.xAxis.index as DateHierarchy[]) : label
  } else {
    return label
  }
}

const setupTooltip = (props: GenericChartData): TooltipComponentOption => {
  const tooltip: TooltipComponentOption = {
    trigger: 'none',
    axisPointer: {
      animation: false,
      label: {}
    },
    backgroundColor: '#fffd'
  }

  if (props.visType.type === 'pie') {
    tooltip.trigger = 'item'
  }

  if (props.visType.type === 'bar') {
    tooltip.trigger = 'axis'
    tooltip.axisPointer!.type = 'shadow'
  }

  if (props.visType.type === 'line') {
    tooltip.trigger = 'axis'
    tooltip.axisPointer!.type = 'line'

    if (hasSecondYAxis(props)) {
      tooltip.axisPointer!.label!.backgroundColor = '#6a7985'
    }
  }

  if (props.visType.type === 'scatter') {
    tooltip.trigger = 'item'
    tooltip.position = 'top'
  }

  tooltip.formatter = (params: any) => {
    const textStyle = getTooltipTextStyle()
    let res = ''
    if (params.length) {
      const paramsList = params
      res += formatAxisValueLabel(paramsList[0].axisValueLabel, props) + '<br/>'
      for (const params of paramsList) {
        const value = params.value[params.dimensionNames[params.encode.y[0]]]
        res += wrapBlockHTML(
          params.marker
          + wrapInlineNameHtml(params.seriesName, true, textStyle.nameStyle)
          + wrapInlineValueHTML(formatNumeral(value) || '-', textStyle.valueStyle)
        )
      }
    } else {
      if (params.encode.x && params.encode.y) {
        const name = params.value[params.dimensionNames[params.encode.x[0]]]
        const value = params.value[params.dimensionNames[params.encode.y[0]]]
        res += formatAxisValueLabel(name, props)
        res += wrapBlockHTML(
          params.marker
          + wrapInlineNameHtml(params.seriesName, true, textStyle.nameStyle)
          + wrapInlineValueHTML(formatNumeral(value) || '-', textStyle.valueStyle)
        )
      } else {
        const name = params.name ? (params.name + '<br/>') : ''
        const index = params.encode.value[0]
        const value = !params.value.length
          ? params.value[Object.keys(params.value)[index]]
          : params.value[params.dimensionNames[index]]
        res += name
        res += wrapBlockHTML(
          params.marker
          + wrapInlineNameHtml(params.seriesName, true, textStyle.nameStyle)
          + wrapInlineValueHTML(formatNumeral(value) || '-', textStyle.valueStyle)
        )
      }
    }
    return res
  }

  return tooltip
}

export default setupTooltip
