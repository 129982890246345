import { useEffect, useState } from 'react'

const useDelayedRender = (delay: number): any => {
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay)
    return () => clearTimeout(timeout)
  }, [delay])
  return (fn: any) => {
    if (!delayed) {
      return fn()
    } else {
      return null
    }
  }
}

const DelayedRender = ({ delay, children }: { delay: number, children: any }) => useDelayedRender(delay)(() => children)

export default DelayedRender
