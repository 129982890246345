import {
  DropResult, EuiButtonEmpty, EuiButtonIcon, EuiDragDropContext, euiDragDropReorder, EuiDraggable, EuiDroppable,
  EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPanel, EuiPopover, EuiTab, EuiTabs, htmlIdGenerator
} from '@elastic/eui'
import _ from 'lodash'
import React, { PropsWithChildren, useState } from 'react'

export interface SuperTab {
  id: string
  name: string
}

export interface SuperTabsProps {
  canEdit?: boolean
  tabs: SuperTab[]
  tabIdPprefix?: string
  /**
   * Use this prop to set the initially selected tab while letting the super tabs component
   * control selection state internally
   */
  initialSelectedTabId?: string
  /**
   * Use this prop if you want to control selection state within the owner component
   */
  selectedTabId?: string
  enterEditingModeButtonFn?: (enter: () => void) => React.ReactNode
  onTabClick?: (selectedTab: SuperTab) => void
  onTabCreated?: (newTab: SuperTab) => Promise<SuperTab> | SuperTab
  onTabRearranged: (tabs: SuperTab[]) => void
}

interface SuperTabsState {
  selectedTabId: string | undefined
  isEditingMode: boolean
  editingTabs: SuperTab[]
  newTabName: string
  newTabNameError?: string
  editedTab?: {
    id: string
    name: string
  }
}

const useSuperTabsState = (props: PropsWithChildren<SuperTabsProps>) => {
  const { initialSelectedTabId, selectedTabId: externalSelectedTabId, tabs } = props

  // Only track selection state if it's not controlled externally.
  let selectedTabId: string | undefined
  if (!externalSelectedTabId) {
    selectedTabId = initialSelectedTabId || (tabs.length > 0 ? tabs[0].id : undefined)
  }

  const initial: SuperTabsState = {
    selectedTabId,
    isEditingMode: false,
    editingTabs: [],
    newTabName: '',
    editedTab: undefined
  }

  return useState(initial)
}

const SuperTabs = (props: PropsWithChildren<SuperTabsProps>) => {
  const [state, setState] = useSuperTabsState(props)

  const setEditingMode = (isEditingMode: boolean) => {
    setState({
      ...state,
      isEditingMode,
      editingTabs: isEditingMode ? _.clone(props.tabs) : []
    })
  }

  const onTabClick = (selectedTab: SuperTab) => {
    const { onTabClick, selectedTabId: externalSelectedTabId } = props
    if (onTabClick) {
      onTabClick(selectedTab)
    }

    // Only track selection state if it's not controlled externally.
    if (!externalSelectedTabId) {
      setState({ ...state, selectedTabId: selectedTab.id })
    }
  }

  const performEditingTabName = (id: string) => {
    const { editingTabs: tabs, editedTab } = state
    const focus = tabs.find(tab => tab.id === id)
    if (focus) {
      focus.name = editedTab!.name
    }
    setState({ ...state, editedTab: undefined, editingTabs: tabs })
  }

  const performAddingTab = async () => {
    if (state.newTabName) {
      const { onTabCreated } = props
      try {
        let newTab = { id: htmlIdGenerator(props.tabIdPprefix)(), name: state.newTabName }
        if (onTabCreated) {
          newTab = await onTabCreated(newTab)
        }
        state.editingTabs.push(newTab)
        setState({ ...state, editingTabs: state.editingTabs, newTabName: '', newTabNameError: undefined })
      } catch (err) {
        setState({ ...state, newTabNameError: err.message })
      }
    }
  }

  const renderEditor = () => {
    const { editingTabs: tabs, editedTab } = state
    const onDragEnd = ({ source, destination }: DropResult) => {
      if (source && destination) {
        setState({ ...state, editingTabs: euiDragDropReorder(tabs, source.index, destination.index) })
      }
    }
    return (
      <EuiPopover
        anchorPosition="downLeft"
        hasArrow={false}
        offset={5}
        panelPaddingSize="s"
        button={
          <EuiDragDropContext onDragEnd={onDragEnd}>
            <EuiDroppable
              droppableId="SUPER_TABS_DROPPABLE_AREA"
              spacing="m"
              direction="horizontal"
              // withPanel
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {tabs.map(({ id, name }, idx) => (
                <EuiDraggable spacing="s" key={id} index={idx} draggableId={id}>
                  {(provided, dragState) => {
                    return (
                      <EuiPanel paddingSize="s" hasShadow={dragState.isDragging} hasBorder>
                        <EuiFlexGroup alignItems="center" gutterSize="m">
                          <EuiFlexItem grow={false}>
                            {editedTab?.id === id ? <EuiFieldText
                              inputRef={(ref) => { ref?.focus() }}
                              compressed
                              value={editedTab.name}
                              onChange={(e) => { setState({ ...state, editedTab: { id, name: e.target.value } }) }}
                              onKeyDown={(e) => { if (e.key === 'Enter') { performEditingTabName(id) } }}
                              onBlur={() => { performEditingTabName(id) }}
                            /> : name}
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon iconType="documentEdit" aria-label="Remove Tab" onClick={() => { setState({ ...state, editedTab: { id, name } }) }} />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon iconType="trash" aria-label="Remove Tab" onClick={() => { setState({ ...state, editingTabs: tabs.filter(tab => tab.id !== id) }) }} />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                    )
                  }}
                </EuiDraggable>
              ))}
            </EuiDroppable>
          </EuiDragDropContext>
        }
        isOpen={state.isEditingMode}
        closePopover={() => { }}
      >
        <EuiFormRow isInvalid={!!state.newTabNameError} error={[state.newTabNameError]}>
          <EuiFieldText
            style={{ width: 320 }}
            placeholder="输入新标签页名称或粘贴被引用的网址"
            value={state.newTabName}
            onChange={(e) => { setState({ ...state, newTabName: e.target.value.trim() }) }}
            onKeyDown={e => { if (e.key === 'Enter') { performAddingTab() } }}
            append={<EuiButtonIcon display="base" iconType="plus" aria-label="New Tab" onClick={performAddingTab} />}
            isInvalid={!!state.newTabNameError}
          />
        </EuiFormRow>
      </EuiPopover>
    )
  }

  const renderTabs = () => {
    const { selectedTabId: externalSelectedTab } = props

    // Allow the consumer to control tab selection.
    const selectedTabId = externalSelectedTab || state.selectedTabId

    const isSelected = (tab: SuperTab) => {
      return tab.id === selectedTabId
    }

    return (
      <EuiTabs>
        {props.tabs.map(tab => (
          <EuiTab
            onClick={() => onTabClick(tab)}
            isSelected={isSelected(tab)}
            key={tab.id}>
            {tab.name}
          </EuiTab>))
        }
      </EuiTabs>
    )
  }

  const commitRearrangement = () => {
    props.onTabRearranged(state.editingTabs)
  }

  const renderIcons = () => {
    return (
      state.isEditingMode ?
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiButtonIcon display="base" iconType="check" aria-label="Commit" onClick={() => {
              commitRearrangement()
              setEditingMode(false)
            }} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon display="base" iconType="cross" aria-label="Cancel" color="danger" onClick={() => {
              setEditingMode(false)
            }} />
          </EuiFlexItem>
        </EuiFlexGroup>
        : props.enterEditingModeButtonFn ? props.enterEditingModeButtonFn(() => setEditingMode(true))
          : <EuiButtonEmpty iconType="indexEdit" aria-label="Edit" onClick={() => {
            setEditingMode(true)
          }} ></EuiButtonEmpty>
    )
  }

  return (
    <EuiFlexGroup gutterSize="m" alignItems="center">
      <EuiFlexItem grow={false}>
        {state.isEditingMode ? renderEditor() : renderTabs()}
      </EuiFlexItem>
      {(props.canEdit ?
        <EuiFlexItem grow={false}>
          <span className="euiTab euiTab__icons">{renderIcons()}</span>
        </EuiFlexItem>
        : null)}
    </EuiFlexGroup>
  )
}

export default SuperTabs
