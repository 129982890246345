import create from 'zustand'
import { devtools } from 'zustand/middleware'
import * as dd from 'dingtalk-jsapi'
import rzApi, { ThirdPartyPlatform } from '../Services/Api'

export type AuthStatus = 'idle' | 'logging-in' | 'logged-in'

export interface ThirdPartyAuthStoreActions {
  init: () => Promise<void>
  auth: (code: string, platform: ThirdPartyPlatform, rememberMe: boolean) => Promise<void>
}

export const useThirdPartyAuthStore = create(devtools(() => ({
  status: 'idle' as AuthStatus
}), { name: 'ThirdPartyAuthStore' }))

export function useIsThirdPartyAuthing() {
  return useThirdPartyAuthStore(state => {
    return state.status === 'logging-in'
  })
}

export function useIsThirdPartyAuthed() {
  return useThirdPartyAuthStore(state => {
    return state.status === 'logged-in'
  })
}

function getCorpIdFromUrlSearch() {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('corpId')
}

export async function _init() {
  const corpId = getCorpIdFromUrlSearch()
  if (dd.env.platform !== 'notInDingTalk') {
    if (!corpId) {
      throw new Error('no corpId')
    } else {
      useThirdPartyAuthStore.setState({ status: 'logging-in' })
      try {
        const res = await dd.runtime.permission.requestAuthCode({ corpId })
        await rzApi.authCode(res.code, 'dingtalk')
        useThirdPartyAuthStore.setState({ status: 'logged-in' })
      } catch (err) {
        useThirdPartyAuthStore.setState({ status: 'idle' })
      }
    }
  }
}

export async function _auth(code: string, platform: ThirdPartyPlatform, rememberMe: boolean) {
  await rzApi.authTmpCode(code, platform, rememberMe)
  useThirdPartyAuthStore.setState({ status: 'logged-in' })
}

export const thirdPartyAuthStoreActions: ThirdPartyAuthStoreActions = {
  init: _init,
  auth: _auth,
}
