import { EuiFlexGroup } from '@elastic/eui'
import _ from 'lodash'
import React from 'react'
import { LayoutRowData } from '../../Services/WidgetTypes'
import { PageWidget } from '../Widget'
import { registerWidget, WidgetProps } from '../WidgetRegistry'

export const Row = React.memo(({ widgetData }: WidgetProps<LayoutRowData>) => {
  return (
    <EuiFlexGroup gutterSize="m" direction={ENV.isMobile ? "column" : "row"}>
      {_.map(widgetData.children, childId => <PageWidget key={childId} widgetId={childId} />)}
    </EuiFlexGroup>
  )
})

registerWidget<LayoutRowData>({
  widgetType: "Row",
  name: "横排",
  widget: Row,
  defaultProps: { children: [] }
})
