import {
  EuiButtonEmpty, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiIcon, EuiLink,
  EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer, EuiToolTip
} from '@elastic/eui'
import produce from 'immer'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useCallback, useEffect, useState } from 'react'
import { Axis, Chart, Legend, Line, Tooltip } from 'viser-react'
import { Table } from '../Components/Table'
import rzApi from '../Services/Api'
import { SpreadTableData } from "../Services/WidgetTypes"
import { useRealtimePrices, useSubscriptionHubState as useSubscriptionHubIsConnected } from '../Stores/SubscriptionStore'
import viserTheme from '../viser-theme.json'
import { withEditBar } from "./EditBar"
import { registerWidget, WidgetEditorProps, WidgetProps } from "./WidgetRegistry"

const evalExpr = (expr: string, values: number[]) => {
  const x = `
    ${values.map((value, index) => `var ${String.fromCharCode('A'.charCodeAt(0) + index)} = ${value === null ? undefined : value};`).join('')}
    ${expr}
  `
  try {
    // eslint-disable-next-line no-eval
    const result: number = eval(x)
    return result
  } catch {
    return null
  }
}

const SpreadModal = React.memo(({ data, dismiss }: { data: SpreadTableData, dismiss: () => void }) => {
  const [dailyData, setDailyData] = useState({})
  const [seasonData, setSeasonData] = useState({})
  const numYears = 10
  useEffect(() => {

    const fetchDailyData = async () => {
      const startFrom = moment().utc().subtract(3, 'months').startOf('day')
      const stopAt = moment().utc().startOf('day')
      const o: any[] = []
      const d: any = {}
      const batch = await Promise.all(data.dataItems.map(item => rzApi.xdataDaily(item.code, startFrom.toDate(), stopAt.toDate())))
      data.dataItems.forEach((item, index) => {
        d[item.code] = batch[index]
      })
      for (let m = moment(startFrom).startOf('day'), i = 0; m.diff(stopAt, 'days') <= 0; m.add(1, 'days'), i++) {
        const row = Object.keys(d).map(code => { return d[code][i] })
        const value = evalExpr(data.expr, row)
        o.push({ day: m.format('YYYY-MM-DD'), value: value == null ? NaN : value })
      }
      setDailyData(o)
    }
    const fetchSeasonData = async () => {
      const startFrom = moment().utc().startOf('year').subtract(numYears - 1, 'year').startOf('day')
      const stopAt = moment().utc().endOf('year').startOf('day')
      const o: any[] = []
      const d: any = {}
      const turnToMainContract = (code: string) => {
        const matched = code.match(/^([A-Za-z]+)(\d+)$/)
        if (matched && matched.length === 3) {
          return `${matched[1]}9999`
        } else {
          return ''
        }
      }
      const batch = await Promise.all(data.dataItems.map(item => rzApi.xdataDaily(turnToMainContract(item.code), startFrom.toDate(), stopAt.toDate())))
      data.dataItems.forEach((item, index) => {
        d[item.code] = batch[index]
      })
      for (let m = moment(startFrom).startOf('day'), i = 0; m.diff(stopAt, 'days') <= 0; m.add(1, 'days'), i++) {
        const row = Object.keys(d).map(code => { return d[code][i] })
        const value = evalExpr(data.expr, row)
        o.push({
          day: m.format('MM-DD'),
          year: m.year().toString(),
          value: value == null ? NaN : value
        })
      }
      setSeasonData(_.sortBy(o, ['day']))
    }
    const f = async () => {
      await Promise.all([fetchDailyData(), fetchSeasonData()])
    }
    f()
  }, [data])
  return (
    <EuiModal onClose={() => { dismiss() }} maxWidth={1280}>
      <EuiModalHeader>
        <EuiModalHeaderTitle><h1>{data.title}</h1></EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup>
          <EuiFlexItem>
            日度图
            <Chart height={400} data={dailyData}
              scale={[{
                dataKey: 'value',
                formatter: x => isNaN(x) ? "" : x.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
              }]}>
              <Tooltip />
              <Axis />
              <Legend />
              <Line position={"day*value"} color="label" />
            </Chart>
          </EuiFlexItem>
          <EuiFlexItem>
            主力合约季节图
            <Chart height={400} data={seasonData}
              scale={[{
                dataKey: 'day',
                ticks: _.range(12).map(i => moment(new Date(2020, i, 1)).dayOfYear() - 1)
              }, {
                dataKey: 'value',
                formatter: x => isNaN(x) ? "" : x.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
              }]}>
              <Tooltip />
              <Axis dataKey="day" />
              <Legend />
              <Line position={"day*value"} color={["year", _.reverse(viserTheme.colors_24.slice(0, numYears))]} />
            </Chart>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal >
  )
})

const SpreadTableEditor = ({ value, setValue }: WidgetEditorProps<SpreadTableData>) => {
  return (<>
    <EuiFlexGroup gutterSize="s" >
      <EuiFlexItem grow={false}>
        <EuiFormRow label="表格标题？" >
          <EuiFieldText
            value={value.title}
            onChange={
              e => {
                setValue({
                  ...value,
                  title: e.target.value
                })
              }
            } />
        </EuiFormRow>
        <EuiFormRow label="公式？" >
          <EuiFieldText
            style={{ width: 300 }}
            value={value.expr}
            onChange={
              e => {
                setValue({
                  ...value,
                  expr: e.target.value
                })
              }
            } />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size="s" />
    {
      value.dataItems.map((row, i) =>
        <EuiFlexGroup key={i} gutterSize="none">
          <EuiFlexItem >
            <EuiFieldText compressed value={row.code}
              placeholder="期货合约代码"
              prepend={String.fromCharCode('A'.charCodeAt(0) + i)}
              onChange={e => {
                const newValue = e.target.value
                setValue(produce(s => {
                  s.dataItems[i].code = newValue
                }))
              }} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty color="danger"
              onClick={() => {
                setValue(produce(s => {
                  s.dataItems.splice(i, 1)
                }))
              }}>删除数据列</EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    <EuiButtonEmpty onClick={() => {
      setValue(produce(s => {
        s.dataItems.push({ code: '' })
      }))
    }}>
      新增数据列
    </EuiButtonEmpty>
  </>)
}

export const SpreadTable = React.memo(({ widgetId, widgetData }: WidgetProps<SpreadTableData>) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  numeral.nullFormat('N/A')
  const prices = useRealtimePrices(widgetData.dataItems.map(item => item.code))
  const isHubConnected = useSubscriptionHubIsConnected()

  const dataFunc = (row: number, col: number) => {
    const item = widgetData.dataItems[row]
    if (col === 0) {
      return item.code
    } else if (col === 1) {
      return numeral(prices.get(item.code)).format('0.[00]')
    } else if (col === 2) {
      return numeral(evalExpr(widgetData.expr, widgetData.dataItems.map(item => prices.get(item.code)!))).format('0.00')
    } else {
      console.error(`wrong col ${col}`)
    }
  }

  const cellClassNameFunc = (row: number, col: number) => {
    if (isHubConnected) {
      if (col === 2) {
        return 'hero-cell'
      }
    } else {
      if (col !== 0) {
        return 'dimmed'
      }
    }
  }

  const onModalDismiss = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  return <>
    <Table className="widget-data-table"
      header={
        <thead>
          <tr>
            <th colSpan={3} style={{ textAlign: 'center' }}>
              <EuiToolTip position="top" content={widgetData.expr}>
                <EuiLink onClick={() => { setIsModalVisible(true) }}>
                  {widgetData.title}
                </EuiLink>
              </EuiToolTip>
              {
                isHubConnected ? null : <EuiIcon color='danger' type="alert" style={{ paddingLeft: 5 }} />
              }
            </th>
          </tr>
        </thead>
      }
      rows={widgetData.dataItems.length}
      cols={3}
      data={dataFunc}
      cellClassNames={cellClassNameFunc}
      spans={[`C1:C${widgetData.dataItems.length}`]}
    />
    {isModalVisible ? <SpreadModal data={widgetData} dismiss={onModalDismiss} /> : null}
  </>
})

registerWidget(withEditBar<SpreadTableData>({
  widgetType: "SpreadTableData",
  widget: SpreadTable,
  editor: SpreadTableEditor,
  name: "实时比价",
  insertable: true,
  defaultProps: {
    title: '',
    dataItems: [{ code: '' }, { code: '' }],
    expr: '',
  }
}))
