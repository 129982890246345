import React, { useContext } from 'react'
import { PageIdContext, usePageInfo } from '../Stores/PageStore'
import "./Layout/PageRoot"
import "./Layout/Row"
import "./Layout/Col"
import "./Layout/Section"
import './GenericVisual/GenericVis'
import './NewsStream'
import "./TextBox"
import './SpreadTable'
import './WarrantPricetable'
import { getWidgetMeta, WidgetProps } from "./WidgetRegistry"

type PageWidgetProps = {
  widgetId: string
  overrideWidgetType?: React.ComponentType<WidgetProps<any>>
}

export const PageWidget = React.memo(({widgetId, overrideWidgetType}: PageWidgetProps) => {
  const pageId = useContext(PageIdContext)
  const winfo = usePageInfo(pageId, page => page!.content[widgetId])
  if (!winfo) {
    return null
  } else {
    const Widget = overrideWidgetType || getWidgetMeta(winfo[0])?.widget
    if (Widget) {
      return React.createElement(
        Widget,
        {
          key: widgetId,
          widgetId,
          widgetData: winfo[1] != null ? winfo[1] : getWidgetMeta(winfo[0])!.defaultProps
        } as any)
    } else {
      return null
    }
  }
})

export function useWidgetInfo<T>(widgetId: string): [string, T] {
  const pageId = useContext(PageIdContext)
  return usePageInfo(pageId, page => {
    const winfo = page!.content[widgetId]
    return [
      winfo[0],
      winfo[1] != null ? winfo[1] : getWidgetMeta(winfo[0])!.defaultProps
    ]
  })
}
