import { EuiButtonEmpty, EuiDraggable, EuiDroppable, EuiFlexItem, EuiPanel } from '@elastic/eui'
import _ from 'lodash'
import React, { useCallback, useContext } from 'react'
import { LayoutColData } from '../../Services/WidgetTypes'
import { useAppUtilityStore } from '../../Stores/AppUtilityStore'
import { getWidgetDesc, PageIdContext, pageStoreActions, usePageInfo } from '../../Stores/PageStore'
import { uiStoreActions } from '../../Stores/UiStore'
import { PageWidget } from '../Widget'
import { registerWidget, WidgetProps } from '../WidgetRegistry'

export const Col = React.memo(({ widgetId, widgetData }: WidgetProps<LayoutColData>) => {
  const pageId = useContext(PageIdContext)
  const isEditingPage = usePageInfo(pageId, page => page!.isEditing)
  const copiedWidget = useAppUtilityStore(state => state.copiedWidget)

  const onAddWidget = useCallback(() => {
    uiStoreActions.inputWidget({
      title: "添加组件",
      commit: ([newType, newData]: [string, any]) => {
        pageStoreActions.addWidget(pageId, widgetId, newType, newData)
      }
    })
  }, [pageId, widgetId])

  const onPasteWidget = useCallback(() => {
    pageStoreActions.addWidget(pageId, widgetId, copiedWidget!.type, copiedWidget!.props)
  }, [pageId, widgetId, copiedWidget])

  const renderChildren = () =>
    _.map(
      widgetData.children,
      childId => (
        <div key={childId} style={{ paddingBottom: 10 }}>
          <PageWidget widgetId={childId} />
        </div>
      )
    )

  const renderDraggables = () =>
    _.map(
      widgetData.children,
      (childId, i) =>
        <EuiDraggable spacing="m" key={childId} index={i} draggableId={childId}>
          <div key={childId}>
            <PageWidget widgetId={childId} />
          </div>
        </EuiDraggable>
    )

  const renderPaste = () => {
    const desc = getWidgetDesc(copiedWidget!.type, copiedWidget!.props)
    return <EuiButtonEmpty size="s" iconType="copyClipboard" onClick={onPasteWidget}>粘贴已复制的{desc}</EuiButtonEmpty>
  }

  const renderEditMode = () =>
    <EuiPanel paddingSize="none">
      <EuiDroppable type="Col" droppableId={widgetId} grow={false} style={{ minHeight: 50 }}>
        {renderDraggables()}
      </EuiDroppable>
      <EuiButtonEmpty size="s" iconType="plusInCircleFilled" onClick={onAddWidget}>添加组件</EuiButtonEmpty>
      {copiedWidget ? renderPaste() : null}
    </EuiPanel>

  return (
    <EuiFlexItem grow={widgetData.grow as any} style={{ minHeight: 50 }}>
      {isEditingPage ? renderEditMode() : renderChildren()}
    </EuiFlexItem>
  )
})

registerWidget<LayoutColData>({
  widgetType: "Col",
  name: "竖排",
  widget: Col,
  defaultProps: { children: [] }
})
