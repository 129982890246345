import moment from 'moment'
import DataTable from '../utils/DataTable'
import { GenericChartData } from './GenericChart'

const setupTitle = (props: GenericChartData, dt: DataTable): echarts.EChartsOption['title'] => {
  const updateDate = props.showUpdateDate && props.fields.length > 0
    ? ' @ ' + moment(dt.updateDate).format('YYYY-MM-DD')
    : ''
  return {
    text: (props.title || '未命名的图表') + updateDate,
    subtext: undefined,
    left: 'center',
    textStyle: {
      color: '#1a1c21',
      fontSize: ENV.isMobile ? 18 : 20,
      fontWeight: 500,
      lineHeight: 32
    }
  }
}

export default setupTitle
