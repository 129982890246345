import produce from 'immer'
import _ from 'lodash'
import { createContext, useState, useMemo, useEffect } from 'react'
import { createAbortableApi } from '../../Services/Api'
import { useOriginalObjectIfUnchanged } from '../../Utils/Miscs'
import { AxisIndex, ChartTemplate, GenericSolidData, GenericVisField, GenericVisPreference, VisType } from './types'
import { DateOrOffsetRange, toDateValue } from './utils/DateOrOffset'

const DefaultXAxisIndex: AxisIndex = ['year', 'month', 'day']
const DefaultLegendIndex: AxisIndex = 'field'

export const useConsolidatedPref = (pref: GenericVisPreference, fieldValues: any[][]): GenericSolidData => {
  return useMemo(() => {
    const solid: GenericSolidData = {
      ...pref,
      fields: pref.fields.map((f, index) => ({ ...f, values: fieldValues[index] || [] }))
    }
    if (solid.visType.type === 'table') {
      solid.visType = { ...solid.visType, head: 100 }
      if (solid.xAxis.index !== 'field' && solid.legend.index === 'field') {
        solid.xAxis = { ...solid.xAxis, inverse: true }
        solid.visType = { ...solid.visType, colors: _.union(solid.visType.colors, ['up']) }
      } else if (solid.xAxis.index === 'field' && solid.legend.index !== 'field') {
        solid.visType = { ...solid.visType, colors: _.union(solid.visType.colors, ['future']) }
      }
    }
    solid.keepNullRows = solid.visType.type === 'table'
    solid.showUpdateDate = solid.visType.type !== 'table'
    solid.skipWeekend = solid.visType.type === 'table' && _.isEqual(solid.xAxis.index, ['year', 'month', 'day']) && solid.legend.index === 'field'
    // handle no name
    solid.fields.forEach((f, i) => {
      if (!f.label) {
        solid.fields[i].label = `数据项${String.fromCharCode(65 + i)}`
      }
    })
    // filter empty expr
    solid.fields = solid.fields.filter(f => !!f.expr)
    return solid
  }, [pref, fieldValues])
}

export function useGenericVisState(pref: GenericVisPreference, prefSetter?: (pref: GenericVisPreference) => void) {
  const setPref = prefSetter || (() => {})
  const [isLoading, setIsLoading] = useState(false)
  const [fieldData, setFieldData] = useState<any[][]>([])
  const [api] = useState(() => createAbortableApi())
  const exprs = useOriginalObjectIfUnchanged(pref.fields.map(f => f.expr))

  useEffect(() => {
    const loadData = async() => {
      setIsLoading(true)
      setFieldData([])

      try {
        const results = await api.rqlDailyBatch(exprs, toDateValue(pref.dateRange.start), toDateValue(pref.dateRange.end))
        setFieldData(results.map(x => x.Values || []))
      } catch (err) {
        if (err instanceof DOMException && err.name === 'AbortError') {
          // ignore AbortError
        } else {
          console.error(`DataList: rqlDailyBatch error`, err)
        }
      }
      setIsLoading(false)
    }
    loadData()
    return () => api.abort()
  }, [exprs, pref.dateRange, setFieldData, setIsLoading, api])

  return {
    isLoading,
    pref,
    fieldData,
    isSingleField: pref.xAxis.index !== 'field' && pref.legend.index !== 'field',

    actions: {
      setTitle: (title: string) => {
        setPref({
          ...pref,
          title
        })
      },
      setVisType: (visType: VisType) => {
        setPref({
          ...pref,
          visType
        })
      },
      setXAxisIndex(axisIndex: AxisIndex | null) {
        setPref(produce(pref, pref => {
          pref.xAxis.index = axisIndex || DefaultXAxisIndex
        }))
      },
      setLegendIndex(axisIndex: AxisIndex | null) {
        setPref(produce(pref, pref => {
          pref.legend.index = axisIndex || DefaultLegendIndex
        }))
      },
      setDateRange(dateRange: DateOrOffsetRange) {
        setPref({ ...pref, dateRange})
      },
      setFields(fields: GenericVisField[]) {
        setPref({ ...pref, fields})
      },
      setField(index: number, field: GenericVisField) {
        setPref(produce(pref, pref => {
          pref.fields[index] = field
        }))
      },
      removeField(index: number) {
        setPref(produce(pref, pref => {
          pref.fields.splice(index, 1)
        }))
      },
      addNewField() {
        setPref(produce(pref, pref => {
            pref.fields.push({
              label: '',
              expr: '',
            })
          }
        ))
      },
      dragDropReorderFields(startIndex: number, endIndex: number) {
        setPref(produce(pref, pref => {
          pref.fields.splice(endIndex, 0, pref.fields.splice(startIndex, 1)[0])
        }))
      },
      switchTemplate(template: ChartTemplate) {
        setPref(produce(pref, pref => {
          pref.template = template
          if (template === 'DailyChart') {
            pref.visType = { type: 'line' }
            pref.xAxis = { index: ['year', 'month', 'day'] }
            pref.legend = { index: 'field' }
          } else if (template === 'SeasonChart') {
            pref.visType = { type: 'line' }
            pref.xAxis = { index: ['month', 'day'] }
            pref.legend = { index: ['year'] }
          } else if (template === 'HorizontalTable') {
            pref.visType = { type: 'table' }
            pref.xAxis = { index: ['year', 'month', 'day'] }
            pref.legend = { index: 'field' }
          } else if (template === 'VerticalTable') {
            pref.visType = { type: 'table' }
            pref.xAxis = { index: 'field' }
            pref.legend = { index: ['year'] }
          } else if (template === 'WarrantPriceTable') {
            pref.visType = { type: 'table', colors: ['row_min'] }
            pref.xAxis = { index: ['year', 'month', 'day'] }
            pref.legend = { index: 'field' }
          }
        }))
      }
    }
  }
}

export type GenericVisState = ReturnType<typeof useGenericVisState>

export const GenericVisContext = createContext<GenericVisState>(undefined as any)
