import * as aq from 'arquero'
import ColumnTable from 'arquero/dist/types/table/column-table'
import _ from 'lodash'
import { ExclusiveUnion } from '../../../utils'
import { AggMethod, AxisIndex } from '../types'
import { DateOrOffsetRange, fulfillDateRange } from './DateOrOffset'
import { DateHierarchy } from './HierarchicalDate'


/**
 * Add aruqero custom op functions
 */
aq.addAggregateFunction('first', {
  create: () => ({
    init: (state: any) => state.first = null,
    add: (state: any, value) => state.first = state.first ?? value,
    rem: state => { },
    value: (state: any) => state.first
  }),
  param: [1, 0] // 1 field input, 0 extra parameters
}, { override: true })

aq.addAggregateFunction('last', {
  create: () => ({
    init: (state: any) => state.last = null,
    add: (state: any, value) => state.last = value ?? state.last,
    rem: state => { },
    value: (state: any) => state.last
  }),
  param: [1, 0] // 1 field input, 0 extra parameters
}, { override: true })

aq.addAggregateFunction('sum', {
  create: () => ({
    init: (s: any) => s.sum = 0,
    add: (s: any, v) => s.sum += +v,
    rem: (s: any, v) => s.sum -= +v,
    value: (s: any) => s.valid ? s.sum : null
  }),
  param: [1, 0] // 1 field input, 0 extra parameters
}, { override: true })

export type DataTableField = {
  label: string
  values: (number | null)[]
  aggMethod?: AggMethod
}

type TransformData = {
  reverse?: boolean
  head?: number
  size?: number
  offset?: number
}

const getDateExpr = (hierarchiey: DateHierarchy) => {
  switch (hierarchiey) {
    case 'year':
      return (d: any) => aq.op.year(d['_date'])
    case 'quarter':
      return (d: any) => aq.op.quarter(d['_date']) + 1
    case 'month':
      return (d: any) => aq.op.month(d['_date']) + 1
    case 'week':
      return (d: any) => aq.op.week(d['_date'], 1)
    case 'day':
      return (d: any) => aq.op.date(d['_date'])
  }
}

type DataTableCloneProps = {
  fields: DataTableField[]
  xAxis: AxisIndex
  legend: AxisIndex
  keepNullRows?: boolean
  skipWeekend?: boolean
  dates: Date[]
  table: ColumnTable
  updateDate: Date | undefined
  untransTable: ColumnTable
  transformData: TransformData
}

type DataTableCreatorProps = ExclusiveUnion<DataTableProps, DataTableCloneProps>

export type DataTableProps = {
  dateRange: DateOrOffsetRange
  fields: DataTableField[]
  xAxis: AxisIndex
  legend: AxisIndex
  keepNullRows?: boolean
  skipWeekend?: boolean
}

class DataTable {
  private dates: Date[]
  private readonly fields: DataTableField[]
  private readonly xAxis: AxisIndex
  private readonly legend: AxisIndex
  private readonly keepNullRows: boolean | undefined
  private readonly skipWeekend: boolean | undefined
  private table!: ColumnTable
  private _updateDate: Date | undefined
  private untransTable: ColumnTable
  private transformData: TransformData = {}

  private constructor({
    fields,
    xAxis,
    legend,
    keepNullRows,
    skipWeekend,
    ...props
  }: DataTableCreatorProps
  ) {
    // console.time(`DataTable`)
    if (props.table) {
      // clone
      this.dates = props.dates
      this.xAxis = xAxis
      this.legend = legend
      this.fields = this.legend !== 'field' && this.xAxis !== 'field'
        ? fields.slice(0, 1)
        : fields
      this.keepNullRows = keepNullRows
      this.skipWeekend = skipWeekend
      this.table = props.table
      this._updateDate = props.updateDate
      this.untransTable = props.untransTable
      this.transformData = props.transformData
    } else {
      this.dates = fulfillDateRange(props.dateRange)
      this.xAxis = xAxis
      this.legend = legend
      this.fields = this.legend !== 'field' && this.xAxis !== 'field'
        ? fields.slice(0, 1)
        : fields
      this.keepNullRows = keepNullRows
      this.skipWeekend = skipWeekend
      this.createTable()
      this.getUpdateDate()
      this.deriveDateHierarchies()
      if (this.skipWeekend) {
        this.trimWeekend()
      }
      this.aggregate()
      this.deriveJoinedDateHierarchy()
      if (!this.keepNullRows) {
        this.dropna()
      }
      this.pivot()
      this.pruneColumns()
      this.untransTable = this.table
    }
    // console.timeEnd(`DataTable`)
    // this.print()
  }

  static from(props: DataTableProps) {
    return new DataTable(props)
  }

  private clone() {
    return new DataTable({
      fields: this.fields,
      xAxis: this.xAxis,
      legend: this.legend,
      keepNullRows: this.keepNullRows,
      dates: this.dates,
      table: this.table,
      updateDate: this._updateDate,
      untransTable: this.untransTable,
      transformData: this.transformData
    })
  }

  private createTable() {
    const columns = _.fromPairs([
      [`_date`, this.dates],
      ...this.fields.map((f, index) => [
        'field_' + index,
        f.values.length > 0 ? f.values : Array(this.dates.length)
      ])
    ])
    this.table = aq.table(columns)
  }

  private deriveDateHierarchies() {
    const xDateHierarchies = this.xAxis === 'field' ? [] : this.xAxis
    const legendDateHierarchies = this.legend === 'field' ? [] : this.legend
    const axisDateHierarchies = _.union(xDateHierarchies, legendDateHierarchies)
    const exprs = _.fromPairs(
      axisDateHierarchies.map(h => ['_' + h, getDateExpr(h)])
    )
    this.table = this.table.derive(exprs)
  }

  private trimWeekend() {
    // trimWeekend need guarder
    if (this.fields.length === 0) {
      return
    }
    this.table = this.table
      .derive({ _dayofweek: (d: any) => aq.op.dayofweek(d['_date']) })
      .derive({ _notEmpty: this.fields.map((_, index) => `d["${'field_' + index}"] != null`).join('||') })
      .filter((d: any) => (d['_dayofweek'] !== 0 && d['_dayofweek'] !== 6) || d['_notEmpty'])
      .select(aq.not(['_dayofweek', '_notEmpty']))
  }

  private aggregate() {
    const xDateHierarchies = this.xAxis === 'field' ? [] : this.xAxis
    const legendDateHierarchies = this.legend === 'field' ? [] : this.legend
    const dhs = _.union(xDateHierarchies, legendDateHierarchies).map(c => '_' + c)
    this.table = this.table.groupby(dhs)

    const exprs = _.fromPairs(this.fields
      .map((field, index) => [
        'field_' + index,
        `d => op.${field.aggMethod || 'last'}(d["${'field_' + index}"])`
      ]))
    this.table = this.table.rollup(exprs)
  }

  private deriveJoinedDateHierarchy() {
    const genExpr = (dhs: DateHierarchy[]) => {
      return aq.escape((d: any) => {
        return dhs.map(h => {
          const v = d['_' + h]
          if (h === 'month' || h === 'day') {
            return v < 10 ? '0' + v : String(v)
          } else if (h === 'quarter') {
            return 'Q' + v
          } else {
            return v
          }
        }).join('-')
      })
    }
    if (this.xAxis !== 'field') {
      const x = '_' + this.xAxis.join('-')
      this.table = this.table
        .derive({ [x]: genExpr(this.xAxis) })
        .relocate(x as any, { before: 0 } as any)
    }
    if (this.legend !== 'field') {
      const legend = '_' + this.legend.join('-')
      this.table = this.table
        .derive({ [legend]: genExpr(this.legend) })
        .relocate(legend as any, { before: 0 } as any)
    }
  }

  private dropna() {
    this.table = this.table
      .filter(this.fields.map((_, index) => `d["${'field_' + index}"] != null`).join('||'))
  }

  private pivot() {
    // pivot need guarder
    if (this.fields.length === 0) {
      return
    }

    if (this.xAxis === 'field' && this.legend === 'field') {
      this.table = aq.table({
        'empty-legend': this.table.columnNames(),
        '_field': this.table.columnNames(),
        'value': this.table.columnNames().map(c => this.table.get(c, 0))
      })
    } else if (this.xAxis === 'field' && this.legend !== 'field') {
      this.table = this.table
        .fold(this.fields.map((_, index) => 'field_' + index), { as: ['_field', 'value'] })
        .groupby('_field')
        .pivot('_' + this.legend.join('-'), 'value')
        .relocate('_field' as any, { before: 0 } as any)
    } else if (this.legend !== 'field' && this.xAxis !== 'field') {
      const x = '_' + this.xAxis.join('-')
      const legend = '_' + this.legend.join('-')
      this.table = this.table
        .groupby(x)
        .pivot(legend, 'field_0')
        .relocate(x as any, { before: 0 } as any)
        .orderby(x)
      // TODO: dropna by month-day
      // this.dropna()
    }
  }

  private pruneColumns() {
    const xDateHierarchies = this.xAxis === 'field' ? [] : this.xAxis
    const legendDateHierarchies = this.legend === 'field' ? [] : this.legend
    const axisDateHierarchies = _.union(xDateHierarchies, legendDateHierarchies)
    const xJoined = this.xAxis === 'field' ? undefined : '_' + this.xAxis.join('-')
    const legendJoined = this.legend === 'field' ? undefined : '_' + this.legend.join('-')
    this.table = this.table.select(aq.not(axisDateHierarchies.filter(c => '_' + c !== xJoined && '_' + c !== legendJoined).map(c => '_' + c)))
  }

  private getUpdateDate() {
    try {
      this._updateDate = this.table
        .orderby(aq.desc('_date'))
        .filter(this.fields.map((_, index) => `d["${'field_' + index}"] != null`).join('||'))
        .get('_date', 0)
    } catch (err) {
      // TODO:
    }
  }

  private mapFieldNames(names: string[]) {
    const mapper = _.fromPairs(
      this.fields.map((field, index) => [
        'field_' + index,
        field.label
      ])
    )
    return names.map(s => mapper[s] || s)
  }

  getXAxis(): string[] {
    // the first column is xAxis in table
    const xAxis = this.table.array(this.table.columnNames()[0]) as string[]
    if (this.legend === 'field' || this.xAxis === 'field') {
      return this.mapFieldNames(xAxis)
    } else {
      return xAxis
    }
  }

  getYAxis(): string[] {
    // the [1:] columns is yAxis in table
    const yAxis = this.table.columnNames().slice(1)
    if (this.legend === 'field' || this.xAxis === 'field') {
      // restore field labels
      return this.mapFieldNames(yAxis)
    } else {
      return yAxis
    }
  }

  getValues(index: number, axis: 0 | 1 = 0) {
    if (axis === 0) {
      const legends = this.table.columnNames()
      return legends.map(legend => this.table.get(legend, index))
    } else {
      const columnNames = this.table.columnNames()
      return this.table.array(columnNames[index])
    }
  }

  getCellValue(row: number, col: number) {
    let value: string | number | undefined
    value = this.table.get(this.table.columnNames()[row + 1], col)
    return value
  }

  getBoundary({ row, col }: { row: number, col: number }): [number | null | undefined, number | null | undefined] {
    if (this.legend === 'field') {
      const colName = this.table.columnNames()[row + 1]
      let series = this.table.array(colName)
      // if transformData.head is not 0 or undefined, the last item of a series should be skipped
      if (this.transformData.head) {
        series = series.slice(0, -1)
      }
      return [_.min(series), _.max(series)]
    } else if (this.xAxis === 'field') {
      const arr = this.getValues(col - 1, 0).slice(1)
      return [_.min(arr), _.max(arr)]
    } else {
      return [null, null]
    }
  }

  getMinRow(): number | undefined {
    if (this.legend === 'field') {
      try {
        let table = this.untransTable
          .filter(this.fields.map((_, index) => `d["${'field_' + index}"] != null`).join('||'))
        if (this.transformData.reverse) {
          const columnNames = table.columnNames()
          table = aq.table(_.fromPairs(
            columnNames.map(c => {
              let row = table.array(c)
              row = row.reverse()
              return [c, row]
            })
          ))
        }
        let minRow = undefined
        let min: number | null = null
        for (const [key, value] of Object.entries(table.object(0))) {
          if (typeof value === 'number') {
            if (min === null || value < min) {
              min = value
              minRow = parseInt(key.split('_')[1], 10)
            }
          }
        }
        return minRow === undefined ? undefined : minRow + (this.transformData.offset ?? 0)
      } catch (err) {
        // ignore
      }
    }
  }

  toDataset() {
    let table = this.table.select(aq.not('_date'))
    // restore field labels
    if (this.legend === 'field' && this.xAxis !== 'field') {
      table = table.rename(_.fromPairs(
        this.fields.map((field, index) => [
          'field_' + index,
          field.label
        ])))
    } else {
      if (table.columnNames().includes('_field')) {
        table = table
          .params({ labels: this.fields.map(f => f.label) })
          .derive({
            _field: (d: any, $: any) => $.labels[
              aq.op.parse_int(aq.op.match(d['_field'], /field_(\d+)/, 1), 10)
            ]
          })
      }
      if (table.columnNames().includes('empty-legend')) {
        table = table.derive({ 'empty-legend': (d: any) => d['_field'] })
      }
    }
    return {
      dimensions: table.columnNames(),
      source: table.objects()
    }
  }

  transform({ reverse, size, offset, head }: TransformData, override?: boolean) {
    // restore table
    this.table = this.untransTable

    if (override) {
      // override transform data
      _.assign(this.transformData, { reverse, size, offset, head })
    } else {
      // update transform data partially
      _.merge(this.transformData, { reverse, size, offset, head })

      reverse = this.transformData.reverse
      offset = this.transformData.offset
      size = this.transformData.size
      head = this.transformData.head
    }

    const columnNames = this.table.columnNames()
    if (reverse || head) {
      this.table = aq.table(_.fromPairs(
        columnNames.map(c => {
          let row = this.table.array(c)
          if (reverse) {
            row = row.reverse()
          }
          if (head) {
            row = row.slice(0, head + 1)
          }
          return [c, row]
        })
      ))
    }

    if (offset || size) {
      const cols = columnNames.slice(1).slice(offset || 0, size ? (offset || 0) + size : undefined)
      this.table = this.table.select([columnNames[0], ...cols])
    }

    return this.clone()
  }

  get updateDate(): Date | undefined {
    return this._updateDate
  }

  print() {
    this.table.print()
  }
}

export default DataTable
