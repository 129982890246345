import { EuiCard, EuiFlexGrid, EuiFlexItem, EuiHorizontalRule, EuiSpacer, EuiTitle } from '@elastic/eui'
import _ from 'lodash'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { useSiteStore } from '../Stores/SiteStore'
import { uiStoreActions, useSetDocTitle } from '../Stores/UiStore'

/**
 * SiteNav
 *    -> Name
 *    -> Category
 *      -> Name
 *      -> Section
 *        -> Name
 *        -> SectionItem 
 *          -> Name
 *          -> TopicId or PageId
 */
type CatNavParams = {
  catName: string
}

const useCat = (catName: string) => {
  return useSiteStore(siteStore => {
    return _.find(siteStore.siteNav, nav => nav.Name === catName)
  })
}

const CatNavPage = () => {
  const history = useHistory()
  const { catName } = useParams<CatNavParams>()
  const secs = useCat(catName)?.Sections
  useSetDocTitle(catName)
  return <>
    {
      _.map(secs, (sec, index) => {
        return (<div
          key={index}>
          <EuiTitle size="s"><h3>{sec.Name}</h3></EuiTitle>
          <EuiSpacer size="s"/>
          <EuiFlexGrid columns={3} responsive={false}>
            {
              _.map(sec.Items, (item, index) => {
                return (<EuiFlexItem key={index}>
                  <EuiCard
                    title={item.Name}
                    titleElement="span"
                    titleSize="xs"
                    description=""
                    onClick={() => {
                      uiStoreActions.setDocTitle(item.Name)
                      const path = item.PageId
                        ? `/page/${item.PageId}`
                        : `/topic/${item.TopicId}`
                      history.push(path)
                    }}
                  />
                </EuiFlexItem>)
              })
            }
          </EuiFlexGrid>
          <EuiSpacer />
          <EuiHorizontalRule size="full" margin="xs"/>
        </div>)
      })
    }
  </>
}

const SiteNavPage = () => {
  const history = useHistory()
  const siteStore = useSiteStore()
  useSetDocTitle('首页')
  return (
    <>
      <EuiFlexGrid columns={2} responsive={false}>
        {
          _.map(siteStore.siteNav, cat => {
            return (<EuiFlexItem
              key={cat.Name}>
              <EuiCard
                title={cat.Name}
                titleElement="span"
                titleSize="xs"
                description=""
                onClick={() => { history.push(`/nav/cat/${cat.Name}`) }}
              />
            </EuiFlexItem>)
          })
        }
      </EuiFlexGrid>
    </>
  )
}

const NavPage = () => {
  return <Switch>
    <Route path="/nav/cat/:catName" component={CatNavPage} />
    <Route path="/nav" component={SiteNavPage} />
    <Route component={SiteNavPage} />
  </Switch>
}

export default NavPage
