import _ from 'lodash'
import moment from 'moment'

export type DateOffset = { type: 'offset', value: number }
export type AbsoluteDate = { type: 'date', value: string }

export type DateOrOffset = DateOffset | AbsoluteDate

const ignoreNegativeZero = (n: number) => {
  return n === -0 ? 0 : n
}

export const toOffset = (doo: DateOrOffset): DateOffset => {
  if (doo.type === 'offset') {
    return doo
  } else {
    return {
      type: 'offset',
      value: ignoreNegativeZero(moment.utc(doo.value).diff(moment.utc().startOf('day'), 'days'))
    }
  }
}

export const toAbsoluteDate = (doo: DateOrOffset): AbsoluteDate => {
  if (doo.type === 'date') {
    return doo
  } else {
    return {
      type: 'date',
      value: moment.utc().startOf('days').add(doo.value, 'days').format('YYYY-MM-DD')
    }
  }
}

export const toDateValue = (doo: DateOrOffset): Date => {
  if (doo.type === 'date') {
    return moment.utc(doo.value).toDate()
  } else {
    return moment.utc().startOf('days').add(doo.value, 'days').toDate()
  }
}

export type DateOrOffsetRange = {
  start: DateOrOffset
  end: DateOrOffset
}

export const isDateOrOffsetRangeEqual = (a: DateOrOffsetRange, b: DateOrOffsetRange) => {
  return _.isEqual(a, b)
}

export const fulfillDateRange = (dateRange: DateOrOffsetRange): Date[] => {
  let dates: Date[] = []
  const date = toDateValue(dateRange.start)
  const stop = toDateValue(dateRange.end)
  while (date <= stop) {
    dates.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return dates
}
