import {
  DropResult,
  EuiButtonEmpty, EuiDragDropContext, euiDragDropMove, euiDragDropReorder, EuiDraggable, EuiDroppable, EuiFlexGroup, EuiFlexItem,
  EuiHorizontalRule
} from '@elastic/eui'
import _ from 'lodash'
import { useCallback, useContext } from 'react'
import PageRootEmptyPrompt from '../../Components/PageRootEmptyPrompt'
import PageRootLoading from '../../Components/PageRootLoading'
import { PageRootData } from '../../Services/WidgetTypes'
import { isEmptyPage, PageIdContext, pageStoreActions, usePageInfo } from '../../Stores/PageStore'
import { EditBar } from '../EditBar'
import { PageWidget } from '../Widget'
import { registerWidget, WidgetProps } from "../WidgetRegistry"

//
// Root
//
function handleDragEnd(pageId: string, { source, destination }: DropResult) {
  if (source && destination) {
    if (source.droppableId === destination.droppableId) {
      const colId = source.droppableId
      const list = pageStoreActions.getWidgetChildren(pageId, colId)
      const items = euiDragDropReorder(list, source.index, destination.index)
      pageStoreActions.setWidgetChildren(pageId, colId, items)
    } else {
      const srcId = source.droppableId;
      const dstId = destination.droppableId;
      const result = euiDragDropMove(
        pageStoreActions.getWidgetChildren(pageId, srcId),
        pageStoreActions.getWidgetChildren(pageId, dstId),
        source,
        destination
      )
      pageStoreActions.setWidgetChildren(pageId, srcId, result[srcId])
      pageStoreActions.setWidgetChildren(pageId, dstId, result[dstId])
    }
  }
}

export const PageRoot = ({ widgetId, widgetData }: WidgetProps<PageRootData>) => {
  const pageId = useContext(PageIdContext)
  const isLoadingPage = usePageInfo(pageId, page => page!.isLoading)
  const isEditingPage = usePageInfo(pageId, page => page!.isEditing)
  const isEmpty = usePageInfo(pageId, isEmptyPage)

  const onDragEnd = useCallback(
    (dropResult: DropResult) => handleDragEnd(pageId, dropResult),
    [pageId])

  const appendRow = useCallback((cols: number) => {
    const rowId = pageStoreActions.addWidget(pageId, widgetId, 'Row', { children: [] })
    _.range(cols).forEach(() => {
      return pageStoreActions.addWidget(pageId, rowId, 'Col', { children: [] })
    })
  }, [pageId, widgetId])

  const appendRow1 = useCallback(() => appendRow(1), [appendRow])
  const appendRow2 = useCallback(() => appendRow(2), [appendRow])
  const appendRow3 = useCallback(() => appendRow(3), [appendRow])
  const appendRow4 = useCallback(() => appendRow(4), [appendRow])

  const appendSection = useCallback(() => {
    pageStoreActions.addWidget(pageId, widgetId, 'LayoutSection', { text: "" })
  }, [pageId, widgetId])

  const renderRowInserter = () =>
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" iconType="push" onClick={appendSection}>新增标题</EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" iconType="push" onClick={appendRow1}>新增整行</EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" iconType="push" onClick={appendRow2}>新增 2 列</EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" iconType="push" onClick={appendRow3}>新增 3 列</EuiButtonEmpty>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty size="s" iconType="push" onClick={appendRow4}>新增 4 列</EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>

  const renderChildren = () => widgetData.children.map(childId => <PageWidget key={childId} widgetId={childId} />)
  const renderDraggables = () => widgetData.children.map((childId, i) =>
    <EuiDraggable key={childId} draggableId={childId} index={i} customDragHandle={true} spacing="s">
      {provided =>
        <>
          <EditBar widgetId={childId} dragProps={provided.dragHandleProps} />
          <PageWidget widgetId={childId} />
          <EuiHorizontalRule margin="s" />
        </>}
    </EuiDraggable>
  )
  const renderEditMode = () =>
    <EuiDragDropContext onDragEnd={onDragEnd}>
      <EuiDroppable droppableId={widgetId} type="PageRoot">
        {renderDraggables()}
      </EuiDroppable>
      {renderRowInserter()}
    </EuiDragDropContext>

  return <div>{
    isLoadingPage ? <PageRootLoading /> : (
      isEditingPage ? renderEditMode() :
        (isEmpty ? <PageRootEmptyPrompt /> : renderChildren())
    )
  }</div>
}

registerWidget<PageRootData>({
  widgetType: "PageRoot",
  name: "页面框架",
  widget: PageRoot,
  defaultProps: { children: [] }
})
