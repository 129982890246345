import React, { useContext } from 'react'
import { useConsolidatedPref, useGenericVisState } from './GenericVisStore'
import { PageIdContext, usePageInfo } from '../../Stores/PageStore'
import { withEditBar } from '../EditBar'
import { registerWidget, WidgetProps } from '../WidgetRegistry'
import GenericChart, { GenericChartProps } from './Chart/GenericChart'
import GenericEditor from './Editor/GenericEditor'
import GenericTable, { GenericTableData } from './Table/GenericTable'
import { GenericSolidData,  GenericVisPreference } from './types'

export type GenericVisPreviewProps = {
  data: GenericSolidData
  size?: 's' | 'm' | 'l'
  isLoading?: boolean
  disableScroll?: boolean
}

export const GenericVisPreview = ({ data, isLoading, disableScroll, size }: GenericVisPreviewProps) => {
  return data.visType.type === 'table'
    ? React.createElement(GenericTable, {
      ...data,
      disableScroll,
      maxRowsPerPage: size === 's' ? 12 : 24
    } as GenericTableData)
    : React.createElement(GenericChart, {
      data,
      isLoading,
      height: size === 'l' ? 550 : 350
    } as GenericChartProps)
}


export const GenericVis = React.memo(({ widgetData }: WidgetProps<GenericVisPreference>) => {
  const state = useGenericVisState(widgetData)
  const solidData = useConsolidatedPref(state.pref, state.fieldData)
  const pageId = useContext(PageIdContext)
  const isEditingPage = usePageInfo(pageId, page => page ? page.isEditing : false)
  return <GenericVisPreview
    data={solidData}
    isLoading={state.isLoading}
    disableScroll={isEditingPage} />
})

registerWidget(withEditBar<GenericVisPreference>({
  widgetType: 'GenericVis',
  widget: GenericVis,
  editor: GenericEditor,
  name: '通用图表',
  insertable: true,
  defaultProps: {
    title: '',
    template: 'DailyChart',
    visType: { type: 'line' },
    dateRange: {
      start: { type: 'offset', value: -1800 },
      end: { type: 'offset', value: 0 }
    },
    fields: [
    ],
    xAxis: { index: ['year', 'month', 'day'] },
    legend: { index: 'field' }
  }
}))
