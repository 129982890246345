import {
  EuiButton, EuiEmptyPrompt, EuiFieldPassword, EuiFieldText, EuiFlexGroup, EuiFlexItem,
  EuiForm, EuiFormRow, EuiHeaderLogo, EuiLoadingSpinner, EuiSpacer, EuiSwitch, EuiTab, EuiTabs, EuiText
} from '@elastic/eui'
import { PropsWithChildren, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import DingLoginForm from '../Components/DingLoginForm'
import IcpFooter from '../Components/IcpFooter'
import { useIsThirdPartyAuthing } from '../Stores/ThirdPartyAuthStore'
import { useIsLogined, userStoreActions } from '../Stores/UserStore'

function usePwdLoginFormState() {
  const hisotry = useHistory()
  const [state, setState] = useState({
    name: '',
    password: '',
    error: '',
    rememberMe: false
  })

  return {
    state,
    setState,
    async login() {
      try {
        await userStoreActions.signIn(state.name, state.password, state.rememberMe)
        hisotry.push("/")
      } catch (e) {
        setState(s => ({
          ...s, error: e.message
        }))
      }

    }
  }
}

const PwdLoginForm = () => {
  const { state, setState, login } = usePwdLoginFormState()
  return (
    <EuiForm>
      <EuiFormRow label="用户名" helpText="用户名一般为字母拼音">
        <EuiFieldText
          value={state.name}
          onChange={e => setState(s => ({
            ...s, name: e.target.value
          }))} />
      </EuiFormRow>
      <EuiFormRow label="密码">
        <EuiFieldPassword
          value={state.password}
          onChange={e => setState(s => ({
            ...s, password: e.target.value
          }))} />
      </EuiFormRow>

      <EuiFormRow>
        <EuiSwitch
          label="下次自动登录"
          checked={state.rememberMe}
          onChange={e => setState(s => ({
            ...s, rememberMe: e.target.checked
          }))} />
      </EuiFormRow>

      <EuiFormRow>
        <EuiButton type="submit" fill onClick={login}> 登录 </EuiButton>
      </EuiFormRow>
      <EuiText color="danger">
        <p>{state.error}</p>
      </EuiText>
    </EuiForm>
  )
}

export const AutoRedirect = () => {
  const history = useHistory()
  useEffect(() => {
    history.push("/")
  }, [history])
  return (<div>
    <Link to="/">已登录，自动跳转到首页</Link>
  </div>)
}


const FormContainer = ({ hidden, children }: PropsWithChildren<{ hidden: boolean }>) => {
  return <div style={hidden ? { visibility: 'hidden', maxHeight: 0 } : {}}>
    <EuiSpacer />
    {children}
  </div>
}

type LoginFormId = 'password-login-form' | 'dingtalk-login-form'

const LoginFormTabs: { id: LoginFormId, name: string }[] =
  ENV.isMobile
    ? [{ id: 'password-login-form', name: '账号登录' }]
    : [
      { id: 'dingtalk-login-form', name: '钉钉扫码登录' },
      { id: 'password-login-form', name: '账号登录' },
    ]

const LoginForm = () => {
  const [selectedTabId, setSelectedTabId] = useState<LoginFormId>(LoginFormTabs[0].id)
  const renderTabs = () => {
    return LoginFormTabs.map((tab, index) => (
      <EuiTab
        key={index}
        onClick={() => setSelectedTabId(tab.id)}
        isSelected={tab.id === selectedTabId}
      >
        {tab.name}
      </EuiTab>
    ))
  }
  return (
    <div style={{ width: 300 }}>
      <EuiTabs>
        {renderTabs()}
      </EuiTabs>
      <FormContainer hidden={selectedTabId !== 'password-login-form'}>
        <PwdLoginForm />
      </FormContainer>
      <FormContainer hidden={selectedTabId !== 'dingtalk-login-form'}>
        <DingLoginForm />
      </FormContainer>
    </div>
  )
}

const LoginPage = () => {
  const isLogined = useIsLogined()
  const isThirdPartyAuthing = useIsThirdPartyAuthing()
  return (
    isThirdPartyAuthing
      ? <EuiEmptyPrompt
        icon={<EuiLoadingSpinner size="xl" />}
        title={<h2>{`正在使用第三方登录`}</h2>}
      />
      : <div>
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiHeaderLogo iconType="logoObservability">登录润洲数据</EuiHeaderLogo>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            {isLogined ? <AutoRedirect /> : <LoginForm />}
          </EuiFlexItem>
        </EuiFlexGroup>
        <IcpFooter />
      </div>
  );
}

export default LoginPage
