import create from 'zustand'
import shallow from 'zustand/shallow'
import { devtools } from 'zustand/middleware'
import rzApi, { User, Privilege } from '../Services/Api'
import { useEffect } from 'react'

export const useUserStore = create(devtools(() => ({
  me: undefined as User | undefined,
  users: new Map<string, User>(),
  privileges: [] as Privilege[],
  role: 'user'
}), {name: 'UserStore'}))


export function useIsLogined() {
  return useUserStore(state => {
    return !!state.me
  })
}

export function usePrivilege(privilege: Privilege) {
  return useUserStore(state => {
    return state.privileges.indexOf(privilege) >= 0
  })
}

export function useUserDisplayNames(usernames: string[]) {
  useEffect(() => {
    usernames.forEach(queryDisplayName)
  }, [usernames])
  return useUserStore(state => {
    return usernames.map(name => state.users.get(name)?.DisplayName || name)
  }, shallow)
}

export function useUserDisplayName(username: string) {
  useEffect(() => {
    queryDisplayName(username)
  }, [username])
  return useUserStore(state => {
    return state.users.get(username)?.DisplayName || username
  })
}

///////////////////////////////////////////////////////////////////////////////
// Actions
//
async function queryDisplayName(name: string) {
  const users = useUserStore.getState().users
  const displayName = users.get(name)?.DisplayName
  if (displayName) {
    return
  }
  const user = await rzApi.getUserByName(name)
  if (user) {
    useUserStore.setState(state => ({
      users: new Map(state.users).set(name, user)
    }))
  }
}

async function queryMe() {
  if (!useUserStore.getState().me) {
    let user = await rzApi.whoAmI()
    let privileges = await rzApi.whatCanIDo()
    useUserStore.setState({
      me: user!,
      privileges
    })
  }
}

async function signIn(name: string, password: string, rememberMe: boolean) {
  await rzApi.signIn(name, password, rememberMe)
  await queryMe()
}

async function signOut() {
  useUserStore.setState({
    me: undefined,
    privileges: []
  })
  await rzApi.signOut()
}

function resetCurrentUser() {
  useUserStore.setState({
    me: undefined,
    privileges: []
  })
}

export const userStoreActions = {
  queryDisplayName,
  queryMe,
  signIn,
  signOut,
  resetCurrentUser
}