import { useState, useEffect } from 'react'
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { BehaviorSubject } from 'rxjs'

interface IRemoteMessageHub {
  isConnected: BehaviorSubject<boolean>

  connect(): Promise<void>
  disconnect(): Promise<void>

  send(methodName: string, ...args: any[]): Promise<void>
  on(methodName: string, newMethod: (...args: any[]) => void): void
  off(methodName: string, method: (...args: any[]) => void): void
  off(methodName: string): void
}

type ObstructedCall = (hub: HubConnection) => void

class RemoteMessageHub implements IRemoteMessageHub {
  isConnected = new BehaviorSubject<boolean>(false)
  hub: HubConnection | null
  obstructedCalls = [] as ObstructedCall[]
  autoResend: boolean
  url: string

  constructor(url: string, autoResend: boolean) {
    this.url = url
    this.autoResend = autoResend
    this.hub = null
  }

  async connect() {
    const appver = `Web ${process.env.REACT_APP_VERSION}`
    if (!this.hub) {
      this.hub = new HubConnectionBuilder()
        .withUrl(`${this.url}?RZC-Client=${encodeURIComponent(appver)}`)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext: signalR.RetryContext) => {
            return 5 * 1000;
          }
        })
        .build()

      this.hub.onreconnected(() => {
        this.isConnected.next(true)
        if (this.autoResend) {
          this.flushObstructedCalls()
        }
      })
      this.hub.onreconnecting(() => {
        this.isConnected.next(false)
      })
      this.hub.onclose(() => {
        this.isConnected.next(false)
      })
      try {
        await this.hub.start()
      } catch {
      }
    }

    this.isConnected.next(true)
    this.flushObstructedCalls()
  }

  async disconnect() {
    if (this.hub) {
      const hub = this.hub
      this.hub = null
      this.isConnected.next(false)
      await hub.stop()
    }
  }

  flushObstructedCalls() {
    const calls = this.obstructedCalls
    this.obstructedCalls = []
    for (const f of calls) {
      f(this.hub!)
    }
  }

  async send(methodName: string, ...args: any[]) {
    if (this.hub && this.hub.state === HubConnectionState.Connected) {
      await this.hub.send(methodName, ...args)
    } else {
      this.obstructedCalls.push(hub => {
        hub.send(methodName, ...args)
      })
    }
  }

  on(methodName: string, newMethod: (...args: any[]) => void) {
    if (this.hub && this.hub.state === HubConnectionState.Connected) {
      this.hub.on(methodName, newMethod)
    } else {
      this.obstructedCalls.push(hub => {
        hub.on(methodName, newMethod)
      })
    }
  }

  off(methodName: string, method?: (...args: any[]) => void): void {
    if (this.hub && this.hub.state === HubConnectionState.Connected) {
      if (method) {
        this.hub.off(methodName, method)
      } else {
        this.hub.off(methodName)
      }
    } else {
      this.obstructedCalls.push(hub => {
        if (method) {
          hub.off(methodName, method)
        } else {
          hub.off(methodName)
        }
      })
    }
  }
}

export function createHub(url: string, autoResend: boolean): IRemoteMessageHub {
  return new RemoteMessageHub(url, autoResend)
}

export function useHubIsConnected(hub: IRemoteMessageHub) {
  const [value, setValue] = useState(hub.isConnected.value)
  useEffect(() => {
    const s = hub.isConnected.subscribe(setValue)
    return () => s.unsubscribe()
  }, [hub.isConnected])
  return value
}