import { EuiEmptyPrompt, EuiLoadingSpinner } from '@elastic/eui'
import DelayedRender from './DelayRender'

const PageRootLoading = () => {
  return <DelayedRender delay={400}>
    <EuiEmptyPrompt
      title={<EuiLoadingSpinner size="xl" />}
    />
  </DelayedRender>
}

export default PageRootLoading
