import { useResizeObserver } from '@elastic/eui'
import Konva from 'konva'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

const MarginLeft = 6
const ScrollerWidth = 40 + MarginLeft
const AxisLineColor = '#69707D'
const AxisTickColor = '#98A2B3'
const AxisLabelColor = '#343741'
const HandleColor = '#D2DBEE66'
const HandleBorderColor = '#8fb0f799'

type Metrics = [string, number][]

const StepFormatter = {
  'year': 'YYYY',
  'quarter': 'YYYY-QQ',
  'month': 'YYYY-MM'
}

const stepify = (y: number, count: number, height: number) => {
  const index = Math.round(count * y / height)
  const stepificY = index * height / count
  return stepificY
}

const normalizeHandleY = (y: number, height: number, size: number, spanSize: number) => {
  const index = Math.floor(size * y / height)
  const clampedIndex = _.clamp(index, 0, size - spanSize)
  return {
    y: clampedIndex * (height / size),
    value: clampedIndex
  }
}

const calcHandleArgs = (size: number, value: number, spanSize: number, height: number) => {
  return {
    y: value * height / size,
    height: spanSize * height / size
  }
}

const createTrack = (height: number) => {
  const rect = new Konva.Line({
    points: [0, 0, 0, height],
    stroke: AxisLineColor,
    strokeWidth: 0.5
  })
  return rect
}

const createTick = (width: number, height: number, size: number, metrics: Metrics) => {
  const group = new Konva.Group()
  let curr = 0
  metrics.forEach((metric, index) => {
    const [_, spanSize] = metric
    curr += spanSize * height / size

    if (index !== metrics.length - 1) {
      const line = new Konva.Line({
        points: [0, curr, width / 2, curr],
        stroke: AxisTickColor,
        strokeWidth: 0.5
      })
      group.add(line)
    }
  })
  return group
}

const createTickLabels = (width: number, height: number, size: number, metrics: Metrics) => {
  const group = new Konva.Group()
  let curr = 0
  metrics.forEach((metric, index) => {
    const [label, spanSize] = metric
    curr += spanSize * height / size

    const text = new Konva.Text({
      y: curr - spanSize * height / size,
      fontSize: 14,
      // fontStyle: 'bold',
      text: label,
      align: 'center',
      verticalAlign: 'middle',
      fill: AxisLabelColor,
      width,
      height: spanSize * height / size,
      listening: false
    })
    group.add(text)
  })
  return group
}

const createClicker = (width: number, height: number) => {
  const rect = new Konva.Rect({
    width, height,
    fill: '#00000000'
  })
  // add cursor styling
  rect.on('mouseover', function () {
    document.body.style.cursor = 'pointer'
  })
  rect.on('mouseout', function () {
    document.body.style.cursor = 'default'
  })
  return rect
}

const createHandle = (
  y: number,
  width: number, height: number,
  count: number, trackHeight: number,
  normalizeFunc: (y: number) => { y: number, value: number },
  onChange?: (value: number) => void,
  freezing?: boolean
) => {
  const rect = new Konva.Rect({
    width, height, fill: HandleColor,
    // cornerRadius: 5,
    stroke: HandleBorderColor,
    strokeWidth: 1
  })
  const group = new Konva.Group({ draggable: true })
  group.y(y)
  group.add(rect)

  // add cursor styling
  group.on('mouseover', () => { document.body.style.cursor = 'grab' })
  group.on('mouseout', () => { document.body.style.cursor = 'default' })

  let dragStartTargetY: number = y
  let dragStartOffsetY: number = 0
  let draggedIndex: number | null = null
  if (!freezing) {
    group.on('dragstart', ((e: any) => {
      dragStartTargetY = e.evt.clientY + e.evt.offsetY - e.target.y()
      dragStartOffsetY = e.evt.offsetY
    }))

    group.on('dragmove', (e: any) => {
      group.x(0)
      const newY = normalizeFunc(stepify(dragStartOffsetY + e.evt.clientY - dragStartTargetY, count, trackHeight))
      group.y(newY.y)
      onChange && onChange(newY.value)
      draggedIndex = newY.value
    })

    group.on('dragend', (e: any) => {
      if (draggedIndex !== null) {
        onChange && onChange(draggedIndex)
        draggedIndex = null
      }
    })
  } else {
    group.on('dragmove', (e: any) => {
      group.x(0)
    })
  }

  return group
}

type CanvasProps = {
  container: HTMLDivElement | null
  width: number
  height: number
  initValue: number
  spanSize: number
  metrics: Metrics
  freezing?: boolean
  onChange?: (value: number) => void
}

const useCanvas = ({ container, width, height, initValue, spanSize, metrics, freezing, onChange }: CanvasProps) => {
  useEffect(() => {
    if (container) {
      const stage = new Konva.Stage({ container, width, height })
      const layer = new Konva.Layer()
      layer.x(MarginLeft)
      stage.add(layer)

      const track = createTrack(height)
      layer.add(track)

      const size = _.sum(metrics.map(m => m[1]))

      const tick = createTick(width - (MarginLeft + 1), height, size, metrics)
      layer.add(tick)

      const clicker = createClicker(width, height)
      layer.add(clicker)

      const normalizeHandleYFunc = (y: number) => {
        return normalizeHandleY(y, height, size, spanSize)
      }

      if (!freezing) {
        clicker.on('click', (e: any) => {
          const y = normalizeHandleYFunc(e.evt.offsetY)
          handle.y(y.y)
          onChange && onChange(y.value)
        })
      }

      const { y: handleY, height: handleHeight } = calcHandleArgs(size, initValue, spanSize, height)
      const handle = createHandle(
        handleY, width - (MarginLeft + 1), handleHeight, size, height,
        normalizeHandleYFunc,
        onChange,
        freezing)
      layer.add(handle)

      const tickLabels = createTickLabels(width - (MarginLeft + 1), height, size, metrics)
      layer.add(tickLabels)

      return () => {
        stage.destroy()
      }
    }
  }, [container, height, metrics, onChange, spanSize, initValue, width, freezing])
}

const diff = (a: moment.Moment, b: moment.Moment, step: YearScrollerProps['step']): number => {
  if (step === 'year') {
    return a.isAfter(b) ? 0 : 1
  } else if (step === 'quarter') {
    if (a.year() !== b.year()) {
      return 4 - a.quarter() + 1
    } else {
      return b.quarter() - a.quarter() + 1
    }
  } else if (step === 'month') {
    if (a.year() !== b.year()) {
      return 12 - a.month()
    } else {
      return b.month() - a.month() + 1
    }
  } else {
    return 0
  }
}

const useMetrics = ({ start, end, step }: Pick<YearScrollerProps, 'start' | 'end' | 'step'>) => {
  return useMemo(() => {
    const metrics: Metrics = []
    let s = moment(start, StepFormatter[step])
    const e = moment(end, StepFormatter[step])
    if (!s.isValid() || !e.isValid()) {
      return []
    }
    while (!s.isAfter(e)) {
      metrics.push([String(s.year()), diff(s, e, step)])
      s = s.add(1, 'year').startOf('year')
    }
    return metrics // [['2010', 2], ['2011', 4], ['2012', 4], ['2013', 4], ['2014', 4]] as Metrics
  }, [end, start, step])
}

export type YearScrollerProps = {
  start: string
  end: string
  step: 'year' | 'month' | 'quarter'
  spanLength: number
  freezing?: boolean
  onChange?: (value: number) => void
}
const YearScroller = ({
  start,
  end,
  step,
  spanLength,
  freezing,
  onChange
}: YearScrollerProps) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const dimensions = useResizeObserver(container, 'height')
  const metrics = useMetrics({ start, end, step })
  const size = _.sum(metrics.map(m => m[1]))
  const initValue = size - spanLength
  useCanvas({
    container,
    width: ScrollerWidth,
    height: dimensions.height,
    metrics,
    initValue,
    spanSize: spanLength,
    freezing,
    onChange
  })
  return <div ref={setContainer} style={{ width: ScrollerWidth }}></div>
}

export default YearScroller
