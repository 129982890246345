import { EuiListGroup, EuiListGroupItem } from '@elastic/eui'
import React, { useMemo, useState, useCallback } from 'react'
import { getInsertableWidgetMetas, getWidgetMeta, IValueProvider } from "../Widgets/WidgetRegistry"
import { CommitResult, Dialog } from './Dialogs'

///////////////////////////////////////////////////////////////////////////////
//
// InputDataDefDialog
//
export interface InputWidgetDialogProps {
  title: string
  typeIsReadonly?: boolean
  initialValue?: [string, any]
  commit: (value: [string, any]) => CommitResult
  dismiss: () => void
}
export const InputWidgetDialog = (props: InputWidgetDialogProps) => {
  const allMetas = useMemo(() => getInsertableWidgetMetas(), [])
  const [state, setState] = useState(
    props.initialValue ? { wtype: props.initialValue[0] ||'', wdata: props.initialValue[1] }
                 : { wtype: '', wdata: null }
  )
  const [valueProvider, setValueProvider] = useState<IValueProvider<any> | null>(null)
  const setValue = useCallback((newValue: any) => {
    if (newValue instanceof Function) {
      setState(prev => ({ ...prev, wdata: newValue(prev.wdata)}))
    } else {
      setState(prev => ({
        ...prev, wdata: newValue
      }))
    }
  }, [setState])

  const meta = getWidgetMeta(state.wtype)

  const renderWidgetSelector = () => {
    return state.wtype
      ? null
      : <EuiListGroup bordered>
        {allMetas.map((info, index) =>
          <EuiListGroupItem
            key={index}
            onClick={() => {
              setState({
                wtype: info.widgetType,
                wdata: getWidgetMeta(info.widgetType)!.defaultProps
              })
            }}
            label={info.name}
            name={`widget_selector_item_${index}`} />
        )}
      </EuiListGroup>
  }

  const renderEditor = () => {
    const editor = meta?.editor
    if (editor) {
      return React.createElement(editor, {
        value: state.wdata,
        setValue,
        setValueProvider
      })
    } else {
      return null
    }
  }

  return (
    <Dialog title={props.title + (state.wtype ? ' - ' + meta!.name : '')} initialFocus="[name=widget_selector_item_0]"
      maxWidth={1600}
      commit={async () => {
        if (!state.wtype) {
          return "没有选择组件"
        }
        if (valueProvider) {
          try {
            const wdata = valueProvider.getValue()
            if (typeof wdata?.then === 'function') {
              props.commit([state.wtype, await wdata])
            } else {
              props.commit([state.wtype, wdata])
            }
          } catch (err) {
            return err.message
          }
        } else {
          props.commit([state.wtype, state.wdata])
        }
      }}
      dismiss={props.dismiss}>
      {renderWidgetSelector()}
      {renderEditor()}
    </Dialog>
  )
}
