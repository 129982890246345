import produce from 'immer'
import _ from 'lodash'
import { useState } from 'react'
import { EditableTabProps } from '../Components/EditableTabs'
import { GenericVisPreference } from '../Widgets/GenericVisual/types'
import { getWidgetMeta } from '../Widgets/WidgetRegistry'

export const InitialFieldCount = 5

const LOCAL_STORAGE_KEY = 'ExplorePageStore'

const createDefaultPref = () => ({
  ...getWidgetMeta('GenericVis')!.defaultProps,
  title: '未命名',
  fields: [...new Array(InitialFieldCount)].map((_, i) => (
    { label: `数据项${String.fromCharCode(65 + i)}`, expr: '' }
  ))
})

type ExplorePageState = {
  tabs: EditableTabProps[]
  prefs: Record<string, GenericVisPreference>
  activeTabId: string
}

function loadOrInitState(): ExplorePageState {
  const storeStr = localStorage.getItem(LOCAL_STORAGE_KEY)
  try {
    if (storeStr) {
      const data = JSON.parse(storeStr) as ExplorePageState
      // check if InitialFieldCount is changed
      for (const pref of Object.values(data.prefs)) {
        while (pref.fields.length < InitialFieldCount) {
          pref.fields.push({ label: ``, expr: '' })
        }
      }
      return data
    }
  } catch (e) {
  }
  return {
    tabs: [{
      id: 'default-tab',
      label: '未命名',
      closable: true
    }] as EditableTabProps[],
    prefs: {
      'default-tab': createDefaultPref()
    } as Record<string, GenericVisPreference>,
    activeTabId: 'default-tab'
  }
}

export function useExplorePageState() {
  const [state, setState] = useState(loadOrInitState)

  const saveState = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
  }

  const actions = {
    setActiveId(activeId: string) {
      setState(produce(s => {
        s.activeTabId = activeId
        if (!s.prefs[activeId]) {
          s.prefs[activeId] = createDefaultPref()
        }
      }))
      saveState()
    },
    setActivePref(pref: GenericVisPreference) {
      setState(produce(s => {
        s.prefs[s.activeTabId] = pref
        // sync to tab label
        const activeTab = s.tabs.find(tab => tab.id === s.activeTabId)
        if (activeTab) {
          activeTab.label = s.prefs[s.activeTabId].title
        }
      }))
      saveState()
    },
    setTabs(tabs: EditableTabProps[]) {
      setState(s => ({
        ...s,
        tabs,
        prefs: _.pick(s.prefs, s.tabs.map(tab => tab.id))
      }))
      saveState()
    }
  }

  return {
    state,
    actions
  }
}
