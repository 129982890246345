import { useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { PageIdContext, usePageInfo, usePageStore } from '../Stores/PageStore'
import { useSetDocTitle } from '../Stores/UiStore'
import { PageWidget } from "../Widgets/Widget"
import ErrorPage from './ErrorPage'

///////////////////////////////////////////////////////////////////////////////
//
// UserPage
//
interface DataPageProps {
  pageId?: string
  hidden?: boolean
}

const useLeaveWithoutSavePrompt = (isPageModified: boolean) => {
  useEffect(() => {
    const alertFunc = (e: any) => {
      if (!isPageModified) {
        return
      }
      e.preventDefault()
      e.returnValue = "修改还未保存，确定离开吗？"
    }
    window.onbeforeunload = alertFunc
    return () => {
      if (window.onbeforeunload === alertFunc) {
        window.onbeforeunload = null
      }
    }
  }, [isPageModified])
}

const DataPage = (props: DataPageProps) => {
  const pageId = usePageStore(state => props.pageId || state.activePageId)
  const hasPage = usePageInfo(pageId, page => !!page)
  const loadingError = usePageInfo(pageId, page => page?.loadingError || '')
  const isModified = usePageInfo(pageId, page => page ? page.isModified : false)
  const pageTitle = usePageInfo(pageId, page => page?.title)
  useSetDocTitle(pageTitle ?? '')

  useLeaveWithoutSavePrompt(isModified)

  if (!hasPage) {
    return null
  } else if (loadingError) {
    return <ErrorPage errMsg={loadingError} />
  } else {
    return <div id="page-content" className={props.hidden ? 'stealthy' : ''}>
      <PageIdContext.Provider value={pageId}>
        <PageWidget key="_root" widgetId="_root" />
      </PageIdContext.Provider>
      <Prompt when={isModified} message="数据还未保存，确定离开吗？" />
    </div>
  }
}

export default DataPage
