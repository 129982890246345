import React, { useContext } from 'react'
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from '@elastic/eui'
import { appUtilityStoreActions, useAppUtilityHubState, useAppUtilityStore } from '../Stores/AppUtilityStore'
import { PageIdContext, pageStoreActions, usePageInfo } from '../Stores/PageStore'
import { uiStoreActions } from '../Stores/UiStore'
import { PageWidget, useWidgetInfo } from './Widget'
import { getWidgetMeta, WidgetMeta, WidgetProps } from "./WidgetRegistry"

type EditBarProps = {
  widgetId: string
  dragProps?: any
}
export const EditBar = ({ widgetId, dragProps }: EditBarProps) => {
  const [type, data] = useWidgetInfo<any>(widgetId)
  const meta = getWidgetMeta(type)!
  const pageId = useContext(PageIdContext)

  const onCopy = () => {
    appUtilityStoreActions.setCopiedWidget({ id: widgetId, type, props: data })
  }

  const isHubConnected = useAppUtilityHubState()
  const copiedWidget = useAppUtilityStore(state => state.copiedWidget)

  const onEdit = () => {
    uiStoreActions.inputWidget({
      title: "编辑组件",
      typeIsReadonly: true,
      initialValue: [type, data],
      commit: ([newType, newData]: [string, any]) => {
        pageStoreActions.setWidgetData(pageId, widgetId, newData)
      }
    })
  }

  const onDelete = () => {
    uiStoreActions.confirm({
      title: "确定要删除页面组件吗？",
      color: "danger",
      commit: () => pageStoreActions.removeWidget(pageId, widgetId)
    })
  }

  const renderCopyButton = () => {
    const renderNormal = () => <EuiButtonEmpty iconType="copy" size="s" onClick={onCopy}>复制</EuiButtonEmpty>
    const renderDisabled = (prompt: string) => (
      <EuiToolTip
        position="top"
        content={prompt}>
        <EuiButtonEmpty iconType="copy" size="s" disabled={true} onClick={onCopy}>复制</EuiButtonEmpty>
      </EuiToolTip>
    )
    if (!isHubConnected) {
      return renderDisabled('失去连接')
    } else if (copiedWidget?.id === widgetId) {
      return renderDisabled('已复制')
    } else {
      return renderNormal()
    }
  }

  return (
    <EuiFlexGroup gutterSize="none" alignItems="center" className="edit-bar" {...dragProps}>
      <EuiFlexItem grow={true} ><EuiText>{meta.name}</EuiText></EuiFlexItem>
      {
        meta.editor ?
          <EuiFlexItem grow={false} >
            {renderCopyButton()}
          </EuiFlexItem> :
          null
      }
      {
        meta.editor ?
          <EuiFlexItem grow={false} >
            <EuiButtonEmpty iconType="documentEdit" size="s" onClick={onEdit}>修改</EuiButtonEmpty>
          </EuiFlexItem> :
          null
      }
      <EuiFlexItem grow={false} >
        <EuiButtonEmpty iconType="trash" size="s" color="danger" onClick={onDelete}>删除</EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export interface EditorProps<P> {
  value: P
  setValue: (value: P) => void
}
export function withEditBar<P>(info: WidgetMeta<P>): WidgetMeta<P> {
  const WidgetWithEditBar = (props: WidgetProps<any>) => {
    const pageId = useContext(PageIdContext)
    const isEditing = usePageInfo(pageId, p => p?.isEditing)
    return (
      <div style={isEditing ? { border: "1px solid #333" } : {}}>
        {isEditing ? <EditBar widgetId={props.widgetId} /> : null}
        <PageWidget widgetId={props.widgetId} overrideWidgetType={info.widget} />
      </div>
    )
  }
  return {
    ...info,
    widget: WidgetWithEditBar
  }
}
