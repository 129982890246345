type OverlapTitleProps = {
  title?: string
}

const OverlapTitleForSearch = ({ title }: OverlapTitleProps) => {
  return (
    title
      ? <div
        style={{
          position: 'relative',
          textAlign: 'center',
          top: -340,
          zIndex: -1
        }}>
        {title}
      </div>
      : null
  )
}

export default OverlapTitleForSearch
