import { withEditBar } from './EditBar'
import GenericEditor from './GenericVisual/Editor/GenericEditor'
import { GenericVis } from './GenericVisual/GenericVis'
import { GenericVisPreference } from './GenericVisual/types'
import { registerWidget } from './WidgetRegistry'

registerWidget(withEditBar<GenericVisPreference>({
  widgetType: 'WarrantPriceTable',
  widget: GenericVis,
  editor: GenericEditor,
  name: '仓单价格表',
  insertable: true,
  defaultProps: {
    title: '',
    template: 'WarrantPriceTable',
    visType: { type: 'table', colors: ['row_min'] },
    dateRange: {
      start: { type: 'offset', value: -6 },
      end: { type: 'offset', value: 0 }
    },
    fields: [
    ],
    xAxis: { index: ['year', 'month', 'day'] },
    legend: { index: 'field' }
  }
}))
