import create from 'zustand'
import { devtools } from 'zustand/middleware'
import rzApi, { SiteNav, SiteNavItem } from '../Services/Api'

export type SiteNavItemCache = SiteNavItem & {
  Breadcrumb: string[]
}

export const useSiteStore = create(devtools(() => ({
  siteNav: [] as SiteNav,
  dataCategories: [] as string[]
}), {name: 'SiteStore'}))

async function load() {
  useSiteStore.setState({
    siteNav: await rzApi.getNav(),
    //dataCategories: await rzApi.getDataBookCategories()
  })
}

export const siteStoreActions = {
  load
}