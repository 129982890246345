import { EuiFormControlLayout } from '@elastic/eui'
import classNames from 'classnames'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { Descendant, Node } from 'slate'
import rzApi from '../../Services/Api'
import FormulaInput from './FormulaInput'

export const deserialize = (value: string): Descendant[] => {
  return [{ children: [{ text: value }] }]
}

const serialize = (value: Descendant[]): string => {
  const str = value.map(n => Node.string(n)).join('')
  return str
}

const useFormulaTester = (initialValue: string, isEmptyValid?: boolean): [(formula: string) => void, boolean, boolean] => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const testFormula = useCallback((formula: string) => {
    setIsLoading(true)
      ; (async () => {
        let error: string | undefined
        if (isEmptyValid && formula === '') {
        } else {
          try {
            await rzApi.rqlDaily(formula, moment.utc().startOf('day').toDate(), moment.utc().startOf('day').toDate())
          } catch (err) {
            error = err.message
          }
        }
        setIsInvalid(!!error)
      })()
        .finally(() => {
          setIsLoading(false)
        })
  }, [isEmptyValid])

  useEffect(() => {
    testFormula(initialValue)
  }, [testFormula, initialValue])

  return [testFormula, isInvalid, isLoading]
}

export type FormulaEditorProps = {
  initialValue: string
  onChange: (value: string) => void
  compressed?: boolean
  isEmptyValid?: boolean
}

const FormulaEditor = ({
  initialValue,
  onChange,
  compressed,
  isEmptyValid
}: FormulaEditorProps) => {
  const [value, setValue] = useState(() => deserialize(initialValue))
  const [testFormula, isInvalid, isLoading] = useFormulaTester(initialValue, isEmptyValid)

  const onBlur = useCallback(() => {
    const formula = serialize(value)
    testFormula(formula)
    onChange(formula)
  }, [testFormula, onChange, value])

  const className = classNames(
    'euiFieldText',
    {
      'euiFieldText--compressed': compressed
    }
  )

  return (
    <EuiFormControlLayout compressed={compressed} isLoading={isLoading}>
      <FormulaInput
        className={className}
        initialValue={value}
        isInvalid={isInvalid}
        placeholder="请输入公式，可输入Ctrl+/ 进行搜索"
        onChange={setValue}
        onBlur={onBlur}
      />
    </EuiFormControlLayout>
  )
}

export default FormulaEditor
