
import { useContext } from "react"
import { GenericVisContext } from "../../GenericVisStore"
import CustomPanel from "./CustomPanel"
import DailyChartPanel from "./DailyChartPanel"
import HorizontalTablePanel from "./HorizontalTablePanel"
import SeasonChartPanel from "./SeasonChartPanel"
import VerticalTablePanel from "./VerticalTablePanel"

const ChartPanel = () => {
  const state = useContext(GenericVisContext)
  switch (state.pref.template) {
    case 'DailyChart':
      return <DailyChartPanel />
    case 'SeasonChart':
      return <SeasonChartPanel />
    case 'HorizontalTable':
      return <HorizontalTablePanel />
    case 'VerticalTable':
      return <VerticalTablePanel />
    case 'WarrantPriceTable':
      return <HorizontalTablePanel />
    default:
      return <CustomPanel />
  }
}

export default ChartPanel
