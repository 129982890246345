import { EuiHeader, EuiProvider } from '@elastic/eui'
import React, { useEffect } from 'react'
import { HashRouter as Router, Route, Switch } from "react-router-dom"
import './App.scss'
import AppInit from './AppInit'
import EditingModeToolbar from './Components/EditingModeToolbar'
import { Header } from './Components/Header'
import { ScreenshotToolbar } from './Components/Screenshot'
import DataPage from './Pages/DataPage'
import ExplorePage from './Pages/ExplorePage'
import HomePage from './Pages/HomePage'
import LoginPage from './Pages/LoginPage'
import NavPage from './Pages/NavPage'
import OAuthPage from './Pages/OAuthPage'
import TopicPage, { TopicTabBar } from './Pages/TopicPage'
import { PageStoreInitializer, useActivePageInfo } from './Stores/PageStore'
import { useUiStore } from './Stores/UiStore'
import { usePrivilege } from './Stores/UserStore'

const DialogManager = () => {
  const dialogs = useUiStore(state => state.dialogs)
  return <div>{
    dialogs.map(x => React.createElement(x.dlg, x.args))
  }</div>
}

const Subheader = () => {
  const subHeader = useUiStore(state => state.subHeader)
  useEffect(() => {
    if (subHeader) {
      document.body.classList.add('euiBody--headerIsFixed--double')
    } else {
      document.body.classList.remove('euiBody--headerIsFixed--double')
    }
  }, [subHeader])
  return <>
    {subHeader ?
      <EuiHeader position="fixed">{subHeader}</EuiHeader>
      : null}
  </>
}

const App = () => {
  const hasWritePagesPrivilege = usePrivilege('WritePages')
  const isEditingPage = useActivePageInfo(page => page?.isEditing)

  return (
    <EuiProvider colorMode="light" modify={{base: 16}}>
      <Router>
        <AppInit />
        <div>
          <Switch>
            {
              hasWritePagesPrivilege && isEditingPage ?
                <Route path="/page/:pageId">
                  <EditingModeToolbar />
                </Route> :
                null
            }
            {
              hasWritePagesPrivilege && isEditingPage ?
                <Route path="/topic/:topicId/:pageId?">
                  <EditingModeToolbar />
                </Route> :
                null
            }
            {
              hasWritePagesPrivilege && isEditingPage ?
                <Route path="/">
                  <EditingModeToolbar />
                </Route> :
                null
            }
            <Route path="/login"></Route>
            <Route path="/oauth"></Route>
            <Route path="/screenshot">
              <ScreenshotToolbar />
            </Route>
            <Route path="/">
              <Header />
            </Route>
          </Switch>
          <TopicTabBar />
          <Subheader />

          <div style={{ padding: 10 }}>
            <Switch>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/oauth">
                <OAuthPage />
              </Route>
              <Route path="/page/:pageId">
                <PageStoreInitializer />
                <DataPage />
              </Route>
              <Route path="/topic/:topicId/:pageId?">
                <PageStoreInitializer />
                <TopicPage />
              </Route>
              <Route path="/screenshot/:pageId">
                <PageStoreInitializer />
                <DataPage />
              </Route>
              <Route path="/explore">
                <ExplorePage />
              </Route>
              <Route path="/nav">
                <NavPage />
              </Route>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </div>
          <DialogManager />
        </div>
      </Router>
    </EuiProvider>
  )
}

export default App
