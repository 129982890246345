import { EuiContextMenuItem, EuiContextMenuPanel, EuiDatePicker, EuiFieldNumber, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useMemo, useRef } from 'react'
import ExpressionEditor, { IExpressionPopover } from '../../../../Components/ExpressionEditor'
import { DateOrOffset, toAbsoluteDate, toOffset } from '../../utils/DateOrOffset'

type DateOrOffsetType = DateOrOffset['type']
type DateOrOffsetValue = DateOrOffset['value']

const TypeMapper = {
  offset: '相对今天',
  date: '绝对日期'
}

const DirectionMapper = {
  past: '以前',
  future: '以后'
}

type DateOrOffsetTypeEditorProps = {
  value: DateOrOffsetType
  description: string
  onChange: (value: DateOrOffsetType) => void
}

const DateOrOffsetTypeEditor = ({
  value,
  description,
  onChange
}: DateOrOffsetTypeEditorProps) => {
  const typeEditorRef = useRef<IExpressionPopover>(null)
  return (
    <ExpressionEditor
      ref={typeEditorRef}
      description={description}
      value={TypeMapper[value]}
      ownFocus={false}>
      <EuiContextMenuPanel
        items={
          Object.entries(TypeMapper).map(([value, text]) =>
            <EuiContextMenuItem key={value}
              style={{ padding: 5 }}
              onClick={() => {
                onChange(value as DateOrOffset['type'])
                typeEditorRef.current!.close()
              }}>
              {text}
            </EuiContextMenuItem>
          )}
      />
    </ExpressionEditor >
  )
}

type OffsetEditorProps = {
  value: number
  onChange: (value: DateOrOffsetValue) => void
}

const OffsetEditor = ({
  value,
  onChange
}: OffsetEditorProps) => {
  const directionEditorRef = useRef<IExpressionPopover>(null)
  const daysEditorRef = useRef<IExpressionPopover>(null)
  const daysInputRef = useRef<HTMLInputElement>(null)
  const onChangeDirection = (direction: keyof typeof DirectionMapper) => {
    const newValue = direction === 'past'
      ? -Math.abs(value)
      : +Math.abs(value)
    onChange(newValue)
  }
  const onChangeDays = (keys: number) => {
    onChange(1 / value > 0 ? keys : -keys)
  }
  const valueDirection = useMemo(() =>
    1 / value > 0
      ? DirectionMapper['future']
      : DirectionMapper['past']
    , [value])

  return (
    <EuiFlexGroup gutterSize="s">
    <EuiFlexItem grow={false}>
      <ExpressionEditor
        ref={daysEditorRef}
        description='['
        value={Math.abs(value).toString()}>
        <EuiFieldNumber
          inputRef={daysInputRef}
          value={Math.abs(value)}
          onChange={e => onChangeDays(e.target.valueAsNumber || 0)}
          onFocus={() => { daysInputRef.current!.select() }}
        />
      </ExpressionEditor >
    </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <ExpressionEditor
          ref={directionEditorRef}
          description="] 天"
          value={valueDirection}
          ownFocus={false}>
          <EuiContextMenuPanel
            items={
              Object.entries(DirectionMapper).map(([value, text]) => (
                <EuiContextMenuItem key={value}
                  style={{ padding: 5 }}
                  onClick={() => {
                    onChangeDirection(value as keyof typeof DirectionMapper)
                    directionEditorRef.current!.close()
                  }}>
                  {text}
                </EuiContextMenuItem>
              ))
            }
          />
        </ExpressionEditor >
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

type DateEditorProps = {
  value: string
  onChange: (value: string) => void
}

const DateEditor = ({
  value,
  onChange
}: DateEditorProps) => {
  const dateEditorRef = useRef<IExpressionPopover>(null)
  return <ExpressionEditor
    ref={dateEditorRef}
    description=""
    value={value}
    ownFocus={false}>
    <EuiDatePicker
      selected={moment.utc(value)}
      onSelect={m => {
        onChange(m.format('YYYY-MM-DD'))
        dateEditorRef.current!.close()
      }}
      aria-label="Date"
      showTimeSelect={false}
      dateFormat="YYYY-MM-DD" />
  </ExpressionEditor >
}

export type DateOrOffsetPickerProps = {
  value: DateOrOffset
  prefix: string
  onChange: (value: DateOrOffset) => void
}

const DateOrOffsetPicker = ({
  value,
  prefix,
  onChange
}: DateOrOffsetPickerProps) => {
  const onChangeType = useCallback((type: DateOrOffsetType) => {
    if (type === 'offset') {
      onChange(toOffset(value))
    } else {
      onChange(toAbsoluteDate(value))
    }
  }, [value, onChange])

  const onChangeValue = useCallback((dateValue: DateOrOffsetValue) => {
    const newValue = { ...value }
    newValue.value = dateValue
    onChange(newValue)
  }, [value, onChange])

  return (
    <EuiFlexGroup gutterSize='s'>
      <EuiFlexItem grow={false}>
        <DateOrOffsetTypeEditor value={value.type} onChange={onChangeType} description={prefix} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {value.type === 'offset'
          ? <OffsetEditor value={value.value} onChange={onChangeValue} />
          : <DateEditor value={value.value} onChange={onChangeValue} />}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default DateOrOffsetPicker
