import { EuiSpacer } from '@elastic/eui'
import _ from 'lodash'
import moment from 'moment'
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from '../../../Components/Table'
import { useResizeObserver } from '@elastic/eui'
import { GenericSolidData, GenericVisField, VisTable } from '../types'
import { formatNumeral } from '../utils'
import DataTable from '../utils/DataTable'
import { DateHierarchy, HierarchicalDate } from '../utils/HierarchicalDate'
import YearScroller from './YearScroller'

const PredictionColor = '#AA6556'
const PosCellFillColor = '#f8696b'
const NegCellFillColor = '#63c384'

const useHorizontal = (props: GenericTableData) => {
  return useMemo(() => {
    return props.legend.index === 'field'
  }, [props])
}

const useXAxis = (dt: DataTable, props: GenericTableData) => {
  return useMemo(() => {
    let headers = dt.getXAxis()
    if (props.xAxis.index !== 'field' && _.isEqual(props.xAxis.index, ['year', 'month', 'day'])) {
      return headers.map(str => {
        const hd = HierarchicalDate.fromString(str, props.xAxis.index as DateHierarchy[])
        return hd.format(['month', 'day'])
      })
    }
    return headers
  }, [dt, props.xAxis.index])
}

const useYAxis = (dt: DataTable) => {
  return useMemo(() => {
    return dt.getYAxis()
  }, [dt])
}

const getCellValue = (dt: DataTable, row: number, col: number) => {
  let value: string | number | undefined
  if (col === 0) {
    value = dt.getYAxis()[row]
  } else {
    value = dt.getCellValue(row, col - 1)
  }
  return value
}

const getCellField = (row: number, col: number, props: GenericTableData): GenericVisField | undefined => {
  if (col > 0) {
    if (props.legend.index === 'field') {
      return props.fields[row]
    } else if (props.xAxis.index === 'field') {
      return props.fields[col - 1]
    }
  }
}

const useCellColor = (dt: DataTable, props: GenericTableData) => {
  return useCallback((row, col) => {
    if (_.includes(props.visType.colors, 'future')) {
      if (props.legend.index !== 'field') {
        const dateStr = dt.getYAxis()[row]
        const hd = HierarchicalDate.fromString(dateStr, props.legend.index)
        if (hd.isValid() && moment(hd.date).endOf(props.legend.index[props.legend.index.length - 1]).isAfter(moment())) {
          return PredictionColor
        }
      } else if (props.xAxis.index !== 'field') {
      }
    }
    if (_.includes(props.visType.colors, 'up')) {
      const value = getCellValue(dt, row, col)
      let preValue
      if (props.legend.index !== 'field') {
        preValue = row > 0 ? getCellValue(dt, row - 1, col) : undefined
      } else if (props.xAxis.index !== 'field') {
        if (props.xAxis.inverse) {
          preValue = col < dt.getXAxis().length ? getCellValue(dt, row, col + 1) : undefined
        } else {
          preValue = col > 0 ? getCellValue(dt, row, col - 1) : undefined
        }
      }
      if (typeof value === 'number' && typeof preValue === 'number') {
        return value > preValue ? 'red' : 'green'
      }
    }
  }, [dt, props])
}

const useCellStyle = (dt: DataTable, props: GenericTableData) => {
  const minRow = useMemo(() => {
    if (_.includes(props.visType.colors, 'row_min')) {
      return dt.getMinRow()
    }
  }, [dt, props])

  return useCallback((row: number, col: number): CSSProperties | undefined => {
    const field = getCellField(row, col, props)
    if (((field?.visType) as VisTable | undefined)?.conditionalFormatting === undefined) {
      if (_.includes(props.visType.colors, 'row_min')) {
        if (minRow === row) {
          return { background: '#FFE699' } // Excel金色，个性色4，淡色 40%
        } else {
          return
        }
      } else {
        return
      }
    }
    // disable conditional formatting in daily table
    if (props.legend.index === 'field') {
      return
    }
    if (col === 0) {
      return
    }
    const [min, max] = dt.getBoundary({ row, col })
    const value = getCellValue(dt, row, col)
    if (!min || !max || typeof value !== 'number' || (max === 0 && min === 0)) {
      return
    }
    if (min >= 0) {
      const stop = 100 * value / max
      return { background: `linear-gradient(90deg, ${PosCellFillColor} 0%, ${PosCellFillColor}77 ${stop}%, white ${stop}%)` }
    } else if (max <= 0) {
      const stop = 100 * value / min
      return { background: `linear-gradient(-90deg, ${NegCellFillColor} 0%, ${NegCellFillColor}77 ${stop}%, white ${stop}%)` }
    } else {
      if (value > 0) {
        const start = Math.abs(min / (max - min)) * 100
        const stop = start + (100 - start) * value / max
        const color = PosCellFillColor
        return { background: `linear-gradient(90deg, white 0%, white ${start}%, ${color} ${start}%, ${color}77 ${stop}%, white ${stop}%)` }
      } else {
        const start = Math.abs(max / (max - min)) * 100
        const stop = start + (100 - start) * value / min
        const color = NegCellFillColor
        return { background: `linear-gradient(-90deg, white 0%, white ${start}%, ${color} ${start}%, ${color}77 ${stop}%, white ${stop}%)` }
      }
    }
  }, [dt, props, minRow])
}

const useDataFunc = (dt: DataTable, props: GenericTableData) => {
  return useCallback((row, col) => {
    // FIXME:
    // if (dt.numRows() === 0) {
    //   return undefined
    // }

    let value: string | number | undefined
    if (col === 0) {
      value = dt.getYAxis()[row]
    } else {
      value = getCellValue(dt, row, col)
    }
    const format = getCellField(row, col, props)?.numberFormat
    return typeof value === 'string'
      ? value
      : formatNumeral(value, format)
  }, [dt, props])
}

const useTransformedTable = (dt: DataTable, offset: number, size: number) => {
  return useMemo(() => {
    return dt.transform({ offset, size })
  }, [dt, offset, size])
}

const useTable = (dt: DataTable, props: GenericTableData, offset: number, size: number) => {
  dt = useTransformedTable(dt, offset, size)
  const isHorizontal = useHorizontal(props)
  const xAxis = useXAxis(dt, props)
  const yAxis = useYAxis(dt)
  const cellColor = useCellColor(dt, props)
  const cellStyle = useCellStyle(dt, props)
  const dataFunc = useDataFunc(dt, props)
  return useMemo(() => {
    return <Table
      className={`widget-data-table generic-table ${isHorizontal ? (ENV.isMobile ? 'mobile-fixed' : 'fixed') : ''}`}
      header={
        <thead>
          <tr><th />{xAxis.map((d, i) => <th key={i} className="date">{d}</th>)}</tr>
        </thead>
      }
      rows={yAxis.length}
      cols={xAxis.length + 1}
      data={dataFunc}
      cellColor={cellColor}
      cellStyle={cellStyle}
    />
  }, [cellColor, cellStyle, dataFunc, isHorizontal, xAxis, yAxis.length])
}

const hasYearScroller = (props: GenericTableData) => {
  const dateHierarchies = [
    ['year'],
    ['year', 'quarter'],
    ['year', 'month']
  ]
  return _.some(dateHierarchies, dh => _.isEqual(dh, props.legend.index))
}

type ContentProps = {
  dt: DataTable
  props: GenericTableData
  width?: number
}

const TableContent = ({ dt, props, width }: ContentProps) => {
  const maxRowsPerPage = props.maxRowsPerPage || 24
  const yAxis = useYAxis(dt)
  const initOffsetY = yAxis.length - maxRowsPerPage < 0 ? 0 : yAxis.length - maxRowsPerPage
  const [offsetY, setOffsetY] = useState(initOffsetY)
  useEffect(() => {
    // reset offset y to initOffsetY if initOffsetY is changed
    setOffsetY(initOffsetY)
  }, [initOffsetY])
  const onChange = useCallback(value => {
    setOffsetY(value)
  }, [])
  const table = useTable(dt, props, offsetY, maxRowsPerPage)

  return <div
    style={{ display: 'flex', flexDirection: 'row', width: width ?? '100%' }}>
    {hasYearScroller(props) && yAxis.length > maxRowsPerPage && !ENV.isMobile ? <YearScroller
      start={yAxis[0]}
      end={yAxis[yAxis.length - 1]}
      step={props.legend.index[props.legend.index.length - 1] as 'year' | 'month' | 'quarter'}
      spanLength={maxRowsPerPage}
      freezing={props.disableScroll}
      onChange={onChange} /> : null}
    <div className="generic-table-container" style={{ display: 'flex', width: '100%', overflow: 'auto' }}>
      {table}
    </div>
  </div>
}

export type GenericTableData = Omit<GenericSolidData, 'visType'> & {
  visType: VisTable
  disableScroll?: boolean
  maxRowsPerPage?: number
}

const useDataTable = (props: GenericTableData): DataTable => {
  return useMemo(() => {
    return DataTable
      .from({
        dateRange: props.dateRange,
        fields: props.fields.map(f => _.pick(f, ['label', 'expr', 'aggMethod', 'values'])),
        xAxis: props.xAxis.index,
        legend: props.legend.index,
        keepNullRows: props.keepNullRows,
        skipWeekend: props.skipWeekend
      })
      .transform({ reverse: props.xAxis.inverse, head: props.visType.head })
  }, [props.dateRange, props.fields, props.keepNullRows, props.legend.index, props.skipWeekend, props.visType.head, props.xAxis.index, props.xAxis.inverse])
}

const GenericTable = (props: GenericTableData) => {
  const dt = useDataTable(props)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const tableDimensions = useResizeObserver(container, 'width')

  const title = <div style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bolder', fontFamily: 'sans-serif' }}>
    {(props.title || '未命名的表格') + ((props.showUpdateDate && dt.updateDate) ? ' @ ' + moment(dt.updateDate).format('YYYY-MM-DD') : '')}
  </div >

  return <div ref={setContainer} style={{ position: 'relative' }}>
    <EuiSpacer size="xs" />
    {title}
    <EuiSpacer size="xs" />
    {dt ? <TableContent
      dt={dt}
      props={props}
      width={tableDimensions.width} /> : null}
  </div>
}

export default GenericTable
