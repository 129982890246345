import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiHighlight, EuiSelectable, EuiSelectableMessage, EuiSelectableOption } from '@elastic/eui'
import _ from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createAbortableApi, DataDefSearchResult } from '../../Services/Api'
import { userStoreActions, useUserStore } from '../../Stores/UserStore'

const useDataDefs = (searchValue: string): [DataDefSearchResult[], boolean] => {
  const [dataDefs, setDataDefs] = useState<DataDefSearchResult[]>([])
  const [isSearching, setIsSearching] = useState(false)
  useEffect(() => {
    let unmounted = false
    const api = createAbortableApi()
    const f = async () => {
      const defs = await api.searchDataDef(searchValue, 0, 10)
      Promise.all(defs.map(async (def) => {
        await userStoreActions.queryDisplayName(def.data_update_by)
        const displayName = useUserStore.getState().users.get(def.data_update_by)?.DisplayName
        if (displayName) {
          def.data_update_by = displayName
        }
      }))
      setDataDefs(defs)
    }
    setIsSearching(true)
    f()
      .finally(() => {
        if (!unmounted) {
          setIsSearching(false)
        }
      })
    return () => {
      unmounted = true
      api.abort()
    }
  }, [searchValue])
  return [dataDefs, isSearching]
}

const useOptions = (defs: DataDefSearchResult[]) => {
  const options = defs.map(def => {
    const option: EuiSelectableOption = {
      label: def.name,
      key: def.id
    }
    if (def.data_update_by && def.data_update_time) {
      option.append = <small>
        {`${def.data_update_by} ${moment.utc(def.data_update_time).fromNow()}`}
      </small>
    }
    return option
  })
  return options
}


const NoMatchesMessage = ({ searchValue }: { searchValue: string }) => {
  return <EuiSelectableMessage>
    <strong>{searchValue}</strong> 未匹配到数据项
  </EuiSelectableMessage>
}

type DataDefSelectablePrps = {
  onChange: (dataDef: DataDefSearchResult) => void
}

const DataDefSelectable = ({ onChange }: DataDefSelectablePrps) => {
  const [value, setValue] = useState('')
  const [dataDefs, isSearching] = useDataDefs(value)
  const options = useOptions(dataDefs)
  const onSelectableChange = (selectedOptions: EuiSelectableOption[]) => {
    const selectedOption = selectedOptions.find(opt => opt.checked)!
    const selectedDataDef = dataDefs.find(def => def.id === selectedOption.key)!
    onChange(selectedDataDef)
  }
  const onSearch = useCallback((searchValue: string, matchingOptions: EuiSelectableOption[]) => { setValue(searchValue) }, [])
  const debouncedOnSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch])
  const renderDataDef = (option: EuiSelectableOption, searchValue: string) => {
    return <EuiFlexGroup gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiBadge color="hollow">{option.key}</EuiBadge>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
      </EuiFlexItem>
    </EuiFlexGroup>
  }
  const customRenderprops = {
    // height: 'full' as 'full',
    renderOption: renderDataDef,
    listProps: {
      rowHeight: 42,
      showIcons: false
    }
  }
  return (
    <EuiSelectable
      searchable
      singleSelection="always"
      isLoading={isSearching}
      isPreFiltered={true}
      loadingMessage="匹配数据项中"
      noMatchesMessage={<NoMatchesMessage searchValue={value} />}
      searchProps={{
        placeholder: '搜索数据项',
        defaultValue: '',
        compressed: true,
        onChange: debouncedOnSearch
      }}
      options={options}
      onChange={onSelectableChange}
      {...customRenderprops}
    >
      {(list, search) => (
        <div style={{ width: 400 }}>
          {search}
          {list}
        </div>
      )}
    </EuiSelectable>
  )
}

export default DataDefSelectable
