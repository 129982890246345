import { ExclusiveUnion } from '../utils'

export type RpcError = {
    status: number
    message: string
}

export type ThirdPartyPlatform = 'dingtalk' | 'wechat'

export type User = {
    Name: string
    DisplayName: string
    Roles: string[]
}

export type Privilege = "ReadDataBook" | "WriteMarketData" | "WriteData" | "WritePages"

export type UserInput = {
    Name: string
    DisplayName: string
    Password: string
    Roles: string[]
}

export type OperationLog = [string, string]

export type DataBookInfo = {
    Id          : string
    Name        : string
    Category    : string
    IsInTrash   : boolean
    DataUpdateBy: OperationLog
    CreateBy    : OperationLog
    UpdateBy    : OperationLog
    TrashBy     : OperationLog
}

export type DataBook = {
    Id          : string
    Name        : string
    Category    : string
    Columns     : string []    // def id
    IsInTrash   : boolean
    DataUpdateBy: OperationLog
    CreateBy    : OperationLog
    UpdateBy    : OperationLog
    TrashBy     : OperationLog
}

export type DataBookInput = {
    Name?       : string
    Category?   : string
    Columns?    : string []  // def id
}

export type PageNavItem = {
    PageId: string
    Name: string
}

export type TopicNavItem = {
    TopicId: string
    Name: string
}

export type SiteNavItem = ExclusiveUnion<PageNavItem, TopicNavItem>

export type SiteNavCategory = {
    Name: string
    Sections: {
        Name: string
        Items: SiteNavItem[]
    }[]
}

export type SiteNav = SiteNavCategory[]

export type DataFormat_Number = { Case: "NumberFormat", Fields: { Decimal: number, Unit: string }}
export type DataFormat_Enum = { Case: "EnumFormat", Fields: { Options: string[] }}
export type DataFormat_Text = { Case: "TextFormat" }
export type DataFormat = DataFormat_Number | DataFormat_Enum | DataFormat_Text

export type DataSource_Manual = { Case: "Manual", Fields: { IsAligned: boolean }}
export type DataSource_Wind ={ Case: "Wind", Fields: { Code: string }}
export type DataSource_Bloomberg = { Case: "Bloomberg", Fields: { Code: string }}
export type DataSource_Web = { Case: "Web", Fields: { Url: string }}
export type DataSource = DataSource_Manual | DataSource_Wind | DataSource_Bloomberg | DataSource_Web

export type BatchSeriesResult = {
    Expr: string
    Values?: any []
    Error?: string
}[]

export type DataDef = {
    Id              : string
    Name            : string
    Category        : string
    Description     : string
    Format          : DataFormat
    Source          : DataSource
    CreateBy        : OperationLog
    UpdateBy        : OperationLog
    DataUpdateBy    : OperationLog
}

export type DataDefInput = {
    Name?        : string
    Category?    : string
    Description? : string
    Format?      : DataFormat
}

export type DataDefSearchResult = {
    id                  : string
    name                : string
    category            : string
    description         : string
    data_update_by      : string
    data_update_time    : Date
    from_date           : Date
    stop_date           : Date
}

export type DataItem = {
    Time        : string
    Value       : any
}

export type PageWidget = {
    Type: string
    Data: any
}

export type Page = {
    Id              : string
    Title           : string
    Description     : string
    Domain          : string
    Content         : any
    IsInTrash       : boolean
    CreateBy        : OperationLog
    UpdateBy        : OperationLog
    TrashBy         : OperationLog
}

export type PageInput = {
    Title?       : string
    Description? : string
    Domain?      : string
    Content?     : any
}

export type WidgetDataDict = { [widgetId: string]: [string, any] }

export type AddPageInput = {
    Title        : string
    Description? : string
    Domain?      : string
    Content      : WidgetDataDict
}

// TODO: make ApiException class extended Error class, and handle error catch properly
export class ApiException {
    constructor(public status: number, public message: string) {}
}

export type Topic = {
    Id: string
    Title: string
    Description: string
    Domain: string
    Pages: Page[]
    IsInTrash: boolean
    CreateBy: OperationLog
    UpdateBy: OperationLog
    TrashBy: OperationLog
}

export type TopicInput = {
    PageNames: string[]
    PageIds: string[]
}

export type AddTopicInput = {
    Title: string
    Description: string
    Domain: string
    PageNames: string[]
    PageIds: string[]
}

export type NewsStream = {
    Id: number
    Title: string
    UpdateBy: OperationLog
}

export type NewsStreamItem = {
    Id: number
    NewsStreamId: number
    QuoteId?: number
    Content: string
    UpdateBy: OperationLog
}

export type PaginatedNewsStreamItems = {
    PageCount: number
    ActivePage: number
    Items: NewsStreamItem[]
}

export interface IRzCloudApi {
    signIn(username: string, password: string, rememberMe: boolean): Promise<void>
    getToken(username: string, password: string, expires: Date): Promise<string>
    signOut(): Promise<void>
    authCode(code: string, platform: ThirdPartyPlatform): Promise<void>
    authTmpCode(code: string, platform: ThirdPartyPlatform, rememberMe: boolean): Promise<void>

    whoAmI(): Promise<User | null>
    whatCanIDo(): Promise<Privilege[]>
    getUserByName(username: string): Promise<User>
    changePassword(curPwd: string, newPwd: string): Promise<void>

    getDataBookCategories(): Promise<string[]>
    getAllDataBookInfo(): Promise<DataBookInfo[]>
    getDataBook(bookId: string): Promise<DataBook>
    addDataBook(book: DataBookInput): Promise<DataBook>
    updateDataBook(id: string, book: DataBookInput): Promise<void>
    trashDataBook(id: string): Promise<void>
    logDataBookUpdate(id: string): Promise<void>

    getDataDef(id: string): Promise<DataDef>
    getDataDefs(ids: string[]): Promise<DataDef[]>
    addDataDef(def: DataDefInput, source: DataSource): Promise<DataDef>
    updateDataDef(id: string, def: DataDefInput): Promise<void>
    searchDataDef(keyword: string, from: number, size: number): Promise<DataDefSearchResult[]>

    getDataItems(id: string, startFrom: Date, stopAt: Date): Promise<DataItem[]>
    bulkGetDataItems(ids: string[], startFrom: Date, stopAt: Date): Promise<DataItem[][]>
    updateDataItems(id: string, items: DataItem[]): Promise<void>

    getNav(): Promise<SiteNav>
    updateNav(nav: SiteNav): Promise<void>
    getPage(id: string): Promise<Page>
    addPage(id: string | null, page: AddPageInput): Promise<Page>
    updatePage(id: string, page: PageInput): Promise<void>
    trashPage(id: string): Promise<void>

    addTopic(id: string | null, topic: AddTopicInput): Promise<string>
    getTopic(id: string): Promise<Topic>
    updateTopic(id: string, topic: TopicInput): Promise<void>
    trashTopic(id: string): Promise<void>

    addNewsStream(title: string): Promise<NewsStream>
    updateNewsStream(newsStreamId: number, title: string): Promise<boolean>
    getNewsStream(newsStreamId: number): Promise<NewsStream>
    getNewsStreamByTitle(title: string): Promise<NewsStream>
    addNewsStreamItem(newsStreamId: number, content: string, quoteId: number | null): Promise<NewsStreamItem>
    updateNewsStreamItem(newsStreamId: number, content: string): Promise<boolean>
    getLatestNewsStreamItems(newsStreamId: number, days: number): Promise<NewsStreamItem[]>
    getPaginatedNewsStreamItems(newsStreamId: number, pageSize: number, activePage: number): Promise<PaginatedNewsStreamItems>
    getLatestOneNewsStreamItems(newsStreamId: number): Promise<NewsStreamItem[]>

    rql(expr: string, date: Date): Promise<any>
    rqlDaily(expr: string, startFrom: Date, stopAt: Date): Promise<any[]>
    rqlDailyBatch(exprs: string[], startFrom: Date, stopAt: Date): Promise<BatchSeriesResult>
    rqlMinutely(expr: string, ranges: [Date, Date][]): Promise<any[][]>

    xdataDaily(expr: string, startFrom: Date, stopAt: Date): Promise<any[]>

    addUser(user: UserInput): Promise<void>
    changeUserRoles(username: string, rolesToAdd: string[], rolesToRemove: string[]): Promise<void>
    resetPassword(username: string, newPassword: string): Promise<void>
}

export interface IAbortableRzCloudApi extends IRzCloudApi {
    abort(reason?: string): void
}

export type RzCloudApiOptions = {
    appVer: string
    salt: string
    baseUrl: string
    onVersionTooLow?: () => void
    onChallenge?: () => void
    onServerError?: (status: number, message: string) => void
    needRefreshToken?: () => boolean
}

class RzCloudApi implements IAbortableRzCloudApi {
    static options: RzCloudApiOptions = {
        baseUrl: "/api",
        appVer: "",
        salt: "",
    }
    abortController: AbortController | null = null
    private refreshTokenTask?: Promise<void>

    constructor(public token?: string) {
    }

    private generateAppSig(url: string) {
        return {
            "RZC-Client": RzCloudApi.options.appVer
        }
    }

    async refreshToken() {
        await fetch(RzCloudApi.options.baseUrl + '/auth/refreshAccessToken', {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...this.generateAppSig('/api')
            },
            body: '[""]'
        })
    }

    private async post(url: string, controller: AbortController | null, ...params: any[]) {
        if (this.refreshTokenTask) {
            await this.refreshTokenTask
        } else {
            if (RzCloudApi.options.needRefreshToken && RzCloudApi.options.needRefreshToken()) {
                this.refreshTokenTask = this.refreshToken()
                await this.refreshTokenTask
                this.refreshTokenTask = undefined
            }
        }
        const res = await fetch(RzCloudApi.options.baseUrl + url, {
            method: 'POST',
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...this.token ? {
                    Authorization: `Bearer ${this.token}`,
                } : null,
                ...this.generateAppSig('/api' + url)
            },
            ...params ? {
                body: JSON.stringify(params.length > 1 ? params : params[0])
            } : null,
            signal: controller?.signal
        })
        if (res.status === 200) {
            return res.json()
        } else if (res.status >= 400) {
            const error = await res.text()
            if (res.status === 400 && error.startsWith("Require minimal version:")) {
                if (RzCloudApi.options.onVersionTooLow) {
                    RzCloudApi.options.onVersionTooLow()
                }
                return new Promise(() => { })
            } else if (res.status === 401) {
                if (RzCloudApi.options.onChallenge) {
                    RzCloudApi.options.onChallenge()
                }
                return new Promise(() => { })
            } else if (res.status === 502) {
                const message = error
                if (RzCloudApi.options.onServerError) {
                    RzCloudApi.options.onServerError(res.status, message)
                }
                return new Promise(() => { })
            } else {
                const message = error
                throw new ApiException(res.status, message)
            }
        }
    }

    private apiEntry = (url: string, ...params: any[]) => this.post(url, this.abortController, ...params)

    abort(reason?: string) {
        if (this.abortController) {
            this.abortController.abort()
            this.abortController = new AbortController()
        }
    }

    signIn                  (...params: any[]) { return this.apiEntry("/auth/signin", ...params) }
    getToken                (...params: any[]) { return this.apiEntry("/auth/getToken", ...params) }
    signOut                 (...params: any[]) { return this.apiEntry("/auth/signout", ...params) }
    authCode                (...params: any[]) { return this.apiEntry("/auth/authCode", ...params) }
    authTmpCode             (...params: any[]) { return this.apiEntry("/auth/authTmpCode", ...params) }

    whoAmI                  (...params: any[]) { return this.apiEntry("/user/current", ...params) }
    whatCanIDo              (...params: any[]) { return this.apiEntry("/user/privileges", ...params) }
    getUserByName           (...params: any[]) { return this.apiEntry("/user/getByName", ...params) }
    changePassword          (...params: any[]) { return this.apiEntry("/user/changePassword", ...params) }

    getDataBookCategories   (...params: any[]) { return this.apiEntry("/databook/getCategories", ...params) }
    getAllDataBookInfo      (...params: any[]) { return this.apiEntry("/databook/getAllInfo", ...params) }
    getDataBook             (...params: any[]) { return this.apiEntry("/databook/getOne", ...params) }
    addDataBook             (...params: any[]) { return this.apiEntry("/databook/add", ...params) }
    updateDataBook          (...params: any[]) { return this.apiEntry("/databook/update", ...params) }
    trashDataBook           (...params: any[]) { return this.apiEntry("/databook/trash", ...params) }
    logDataBookUpdate       (...params: any[]) { return this.apiEntry("/databook/logDataUpdate", ...params) }

    getDataDef              (...params: any[]) { return this.apiEntry("/dataDef/get", ...params) }
    getDataDefs             (...params: any[]) { return this.apiEntry("/dataDef/getMany", ...params) }
    addDataDef              (...params: any[]) { return this.apiEntry("/dataDef/add", ...params) }
    updateDataDef           (...params: any[]) { return this.apiEntry("/dataDef/update", ...params) }
    searchDataDef           (...params: any[]) { return this.apiEntry("/search/dataDef", ...params) }

    getDataItems            (...params: any[]) { return this.apiEntry("/dataItem/getMany", ...params) }
    bulkGetDataItems        (...params: any[]) { return this.apiEntry("/dataItem/bulkGetMany", ...params) }
    updateDataItems         (...params: any[]) { return this.apiEntry("/dataItem/updateMany", ...params) }

    getNav                  (...params: any[]) { return this.apiEntry("/site/getNav", ...params) }
    updateNav               (...params: any[]) { return this.apiEntry("/site/updateNav", ...params) }
    getPage                 (...params: any[]) { return this.apiEntry("/site/getPage", ...params) }
    addPage                 (...params: any[]) { return this.apiEntry("/site/addPage", ...params) }
    updatePage              (...params: any[]) { return this.apiEntry("/site/updatePage", ...params) }
    trashPage               (...params: any[]) { return this.apiEntry("/site/trashPage", ...params) }

    addTopic                (...params: any[]) { return this.apiEntry("/site/addTopic", ...params) }
    getTopic                (...params: any[]) { return this.apiEntry("/site/getTopic", ...params) }
    updateTopic             (...params: any[]) { return this.apiEntry("/site/updateTopic", ...params) }
    trashTopic              (...params: any[]) { return this.apiEntry("/site/trashTopic", ...params) }

    addNewsStream           (...params: any[]) { return this.apiEntry("/newsstream/create", ...params) }
    updateNewsStream        (...params: any[]) { return this.apiEntry("/newsstream/update", ...params) }
    getNewsStream           (...params: any[]) { return this.apiEntry("/newsstream/get", ...params) }
    getNewsStreamByTitle    (...params: any[]) { return this.apiEntry("/newsstream/getByTitle", ...params) }
    addNewsStreamItem       (...params: any[]) { return this.apiEntry("/newsstream_item/create", ...params) }
    updateNewsStreamItem    (...params: any[]) { return this.apiEntry("/newsstream_item/update", ...params) }
    getLatestNewsStreamItems(...params: any[]) { return this.apiEntry("/newsstream_item/getLatest", ...params) }
    getPaginatedNewsStreamItems(...params: any[]) { return this.apiEntry("/newsstream_item/getPaginated", ...params) }
    getLatestOneNewsStreamItems(...params: any[]) { return this.apiEntry("/newsstream_item/getLatestOne", ...params) }

    rql                     (...params: any[]) { return this.apiEntry("/rql/one", ...params) }
    rqlDaily                (...params: any[]) { return this.apiEntry("/rql/daily", ...params) }
    rqlDailyBatch           (...params: any[]) { return this.apiEntry("/rql/dailyBatch", ...params) }
    rqlMinutely             (...params: any[]) { return this.apiEntry("/rql/minutely", ...params) }

    xdataDaily              (...params: any[]) { return this.apiEntry("/xdata/daily", ...params) }

    addUser                 (...params: any[]) { return this.apiEntry("/admin/addUser", ...params) }
    resetPassword           (...params: any[]) { return this.apiEntry("/admin/resetPassword", ...params) }
    changeUserRoles         (...params: any[]) { return this.apiEntry("/admin/changeUserRoles", ...params) }
}

const rzApi = new RzCloudApi()

export function createAbortableApi(): IAbortableRzCloudApi {
    const api = new RzCloudApi()
    api.abortController = new AbortController()
    return api
}

export function isAbortError(e: object) {
    return e instanceof DOMException && e.name === 'AbortError'
}

export function config(newOptions: Partial<RzCloudApiOptions>) {
    RzCloudApi.options = { ...RzCloudApi.options, ...newOptions }
}

export default rzApi as IRzCloudApi
