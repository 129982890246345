import _ from 'lodash'
import { useEffect, useRef } from 'react'

export function useOriginalObjectIfUnchanged<T>(obj: T) {
  const objRef = useRef(obj)
  const isChanged = !_.isEqual(objRef.current, obj)
  useEffect(() => {
    if (isChanged) {
      objRef.current = obj
    }
  }, [isChanged, obj, objRef])
  return isChanged ? obj : objRef.current
}
