import { EuiFieldPassword, EuiForm, EuiFormRow } from '@elastic/eui'
import React, { useState, useCallback } from 'react'
import rzApi from '../Services/Api'
import { Dialog } from './Dialogs'

///////////////////////////////////////////////////////////////////////////////
//
// ChangePasswordDialog
//
export interface ChangePasswordDialogProps {
  dismiss: () => void
}

export const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const [state, setState] = useState(() => ({
    originalPwd: "",
    newPwd: "",
    repeatNewPwd: ""
  }))


  const commit = useCallback(async () => {
    if (state.newPwd !== state.repeatNewPwd) {
      return "重复输入的新密码不一样"
    }
    return rzApi.changePassword(state.originalPwd, state.newPwd)
  }, [state])

  return (
    <Dialog title="修改密码"
      commit={commit}
      dismiss={props.dismiss}>
      <EuiForm component="form">
        <EuiFormRow label="旧密码">
          <EuiFieldPassword value={state.originalPwd} onChange={e => setState(s => ({...s, originalPwd: e.target.value}))} />
        </EuiFormRow>
        <EuiFormRow label="新密码">
          <EuiFieldPassword value={state.newPwd} onChange={e => setState(s => ({...s, newPwd: e.target.value}))} />
        </EuiFormRow>
        <EuiFormRow label="重复新密码">
          <EuiFieldPassword value={state.repeatNewPwd} onChange={e => setState(s => ({...s, repeatNewPwd: e.target.value}))} />
        </EuiFormRow>
      </EuiForm>
    </Dialog>
  )
}
