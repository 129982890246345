import { useEffect, useState } from 'react'

export const useHoverObserver = (ele: HTMLElement | null) => {
  const [isHover, setIsHover] = useState(false)
  useEffect(() => {
    const setTrue = () => setIsHover(true)
    const setFalse = () => setIsHover(false)
    ele?.addEventListener('mouseenter', setTrue)
    ele?.addEventListener('mouseleave', setFalse)
    return () => {
      ele?.removeEventListener('mouseenter', setTrue)
      ele?.removeEventListener('mouseleave', setFalse)
    }
  }, [ele])
  return isHover
}
