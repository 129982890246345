import _ from 'lodash'
import numeral from 'numeral'
import { GenericChartData } from '../Chart/GenericChart'
import { GenericSolidField } from '../types'
import { DateHierarchy } from './HierarchicalDate'

export const isHollowFields = (fields: GenericSolidField[]) => {
  return _.every(fields, f => f.values.length === 0)
}

export const hasSecondYAxis = (props: GenericChartData): boolean => {
  return props.legend.index === 'field' && !!props.fields.find(field => field.onSecondYAxis)
}


export const isSeasonType = (props: GenericChartData): boolean => {
  return _.isEqual(props.legend.index, ['year']) && _.isEqual(props.xAxis.index, ['month', 'day'])
}


export const canUseTimeXAxis = (hierarchies: DateHierarchy[] | 'field') => {
  return _.isEqual(hierarchies, ['year', 'month', 'day'])
}

export const formatNumeral = (value: number | undefined | null, format?: string): string => {
  if (value === undefined || value === null) {
    return ''
  } else {
    return numeral(value).format(format || '0.00')
  }
}
