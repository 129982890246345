import { EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import _ from 'lodash'
import { AxisIndex } from '../../types'

export type AxisIndexSelectProps = {
  value?: AxisIndex | null
  placeholder?: string
  onChange?: (value: AxisIndex | null) => void
  compressed?: boolean
  withYear?: boolean
}

type AxisIndexOption = EuiComboBoxOptionOption<AxisIndex>

const FieldOption: AxisIndexOption[] = [{
  label: '数据项',
  options: [{ label: '数据项', value: 'field' }]
}]

const DateHierarchyOption: AxisIndexOption[] = [{
  label: '时间层级',
  options: [
    { label: '年', value: ['year'] },
    { label: '年-季', value: ['year', 'quarter'] },
    { label: '年-月', value: ['year', 'month'] },
    { label: '年-月-日', value: ['year', 'month', 'day'] },
    { label: '季', value: ['quarter'] },
    { label: '月-日', value: ['month', 'day'] },
    { label: '月', value: ['month'] },
    // { label: '日', value: ['day'] }
  ]
}]

const WithYearHierarchyOption: AxisIndexOption[] = [{
  label: '时间层级',
  options: [
    { label: '年', value: ['year'] },
    { label: '年-季', value: ['year', 'quarter'] },
    { label: '年-月', value: ['year', 'month'] },
    { label: '年-月-日', value: ['year', 'month', 'day'] },
  ]
}]

const indexToOptions = (index?: AxisIndex | null): AxisIndexOption[] => {
  if (!index) {
    return []
  } else if (index === 'field') {
    return [{ label: '数据项', value: 'field' }]
  } else {
    const option = DateHierarchyOption[0].options!.find(o => _.isEqual(o.value, index))
    return option ? [option] : []
  }
}

const optionsToIndex = (options: AxisIndexOption[]) => {
  return options[0]?.value || null
}

const AxisIndexSelect = ({
  placeholder,
  value,
  onChange,
  compressed,
  withYear
}: AxisIndexSelectProps) => {
  const options = withYear
    ? WithYearHierarchyOption
    : [...FieldOption, ...DateHierarchyOption]
  return <EuiComboBox
    placeholder={placeholder}
    options={options}
    selectedOptions={indexToOptions(value)}
    onChange={(selectedOptions: AxisIndexOption[]) => onChange && onChange(optionsToIndex(selectedOptions))}
    compressed={compressed}
    singleSelection
  />
}

export default AxisIndexSelect
