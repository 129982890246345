import { EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiText } from '@elastic/eui'
import { useContext } from 'react'
import { GenericVisContext } from '../../GenericVisStore'
import { GenericVisPreference } from '../../types'

const ChartTypeOptions = [
  { text: '折线', value: 'line' },
  { text: '柱形', value: 'bar' },
  { text: '散点', value: 'scatter' },
]

const DailyChart = () => {
  const { pref, actions } = useContext(GenericVisContext)
  return (
    <EuiPanel style={{ overflowY: 'auto' }} paddingSize="s">
      <EuiText>图表</EuiText>
      <EuiForm>
        <EuiFormRow label="标题" display="rowCompressed">
          <EuiFieldText
            value={pref.title}
            onChange={e => actions.setTitle(e.target.value)}
            compressed />
        </EuiFormRow>
        <EuiFormRow label="样式" display="rowCompressed">
          <EuiSelect
            options={ChartTypeOptions}
            value={pref.visType.type}
            onChange={e => actions.setVisType({ type: e.target.value as GenericVisPreference['visType']['type'] })}
            compressed
          />
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  )
}

export default DailyChart
