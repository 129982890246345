import { EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiText } from '@elastic/eui'
import { useContext } from 'react'
import { GenericVisContext } from '../../GenericVisStore'

const DailyTablePanel = () => {
  const state = useContext(GenericVisContext)
  return (
    <EuiPanel style={{ overflowY: 'auto' }} paddingSize="s">
      <EuiText>图表</EuiText>
      <EuiForm>
        <EuiFormRow label="标题" display="rowCompressed">
          <EuiFieldText
            value={state.pref.title}
            onChange={e => state.actions.setTitle(e.target.value)}
            compressed />
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  )
}

export default DailyTablePanel
