import React, { useCallback, useState } from 'react'
import {
  EuiBadge, EuiFlexGroup, EuiFlexItem, EuiHeader, EuiHeaderLink, EuiHeaderLinks, EuiHeaderLogo,
  EuiHeaderSection, EuiHeaderSectionItem, EuiHorizontalRule, EuiLink, EuiPanel, EuiPopover, EuiText
} from '@elastic/eui'
import _ from 'lodash'
import { useHistory } from "react-router-dom"
import { SiteNavCategory, SiteNavItem } from '../Services/Api'
import { useIsLogined, usePrivilege } from '../Stores/UserStore'
import { useRouteClick } from '../utils'
import HeaderUserMenu from './HeaderUserMenu'
import { useSiteStore } from '../Stores/SiteStore'
import { pageStoreActions, useActivePageInfo, usePageStore } from '../Stores/PageStore'

const NavDropDownSection = (
  { title, items, closePanel }: { title: string, items: SiteNavItem[], closePanel: () => void }
) => {
  const history = useHistory()
  return (
    <EuiFlexGroup style={{ maxWidth: 450 }}>
      <EuiFlexItem grow={false} style={{ width: 60 }}>
        <EuiText>{title}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup wrap>
          {
            items.map((item, i) =>
              <EuiFlexItem key={i} grow={false}>
                <EuiLink disabled={!item.PageId && !item.TopicId}
                  onClick={() => {
                    const path = item.PageId ?
                      '/page/' + item.PageId
                      : `/topic/${item.TopicId}`
                    history.push(path)
                    closePanel()
                  }}>
                  {item.Name}
                </EuiLink>
              </EuiFlexItem>
            )
          }
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

type NavDropDownProps = {
  category: SiteNavCategory
}
const NavDropDown = ({ category }: NavDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const button =
    <EuiHeaderLink onClick={() => setIsOpen(!isOpen)}>
      {category.Name}
    </EuiHeaderLink >

  const onClose = () => setIsOpen(false)

  return (
    <EuiPopover
      button={button}
      isOpen={isOpen}
      closePopover={onClose}
      panelPaddingSize="none"
      ownFocus={false}
      anchorPosition="downLeft">
      <EuiPanel paddingSize="m">
        {
          _.flatMap(category.Sections, (sec, i) => <div key={i}>
            <NavDropDownSection title={sec.Name} items={sec.Items} closePanel={onClose} />
            {i < category.Sections.length - 1 ? <EuiHorizontalRule margin="s" /> : null}
          </div>)
        }
      </EuiPanel>
    </EuiPopover>
  )
}

export const Header = () => {
  const history = useHistory()
  const navToMain = useRouteClick('/')
  const siteNav = useSiteStore(state => state.siteNav)
  const isLogined = useIsLogined()
  const hasReadDataBookPrivilege = usePrivilege('ReadDataBook')
  const hasWriteDataPrivilege = usePrivilege('WriteData')

  const isPageLoaded = useActivePageInfo(p => p && !p.loadingError)
  const pageId = usePageStore(s => s.activePageId)

  const openScreenshotPage = useCallback(() => {
    if (pageId) {
      const url = `/#/screenshot/${pageId}`
      window.open(url, '',
        `directories=no,titlebar=no,toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=no,
        width=760,
        height=600`)
    }
  }, [pageId])

  return (
    <EuiHeader position="fixed">
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLogo iconType="logoObservability" onClick={navToMain} href="#">
            润洲数据
          </EuiHeaderLogo>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <EuiBadge color="hollow">{process.env.REACT_APP_VERSION}</EuiBadge>
        </EuiHeaderSectionItem>
      <EuiHeaderSectionItem>
        <EuiHeaderLinks>
          <EuiHeaderLink onClick={navToMain}>首页</EuiHeaderLink>
          {
            siteNav.map((cat, i) => <NavDropDown key={i} category={cat} />)
          }
        </EuiHeaderLinks>
      </EuiHeaderSectionItem>
    </EuiHeaderSection>
    <EuiHeaderSection side="right">
      <EuiHeaderSectionItem>
          <EuiHeaderLinks>
          {
            hasWriteDataPrivilege ? (
              isPageLoaded ? (
                <>
                  <EuiHeaderLink iconType="documentEdit" onClick={() => pageStoreActions.setEditMode(true)}> 编辑页面 </EuiHeaderLink>
                  <EuiHeaderLink iconType="editorComment" onClick={openScreenshotPage}> 生成早报 </EuiHeaderLink>
                </>
              ) : null
            ) : null
          }
          <EuiHeaderLink iconType="dataVisualizer" onClick={() => history.push('/explore')} > 研究数据 </EuiHeaderLink>
          {
            hasReadDataBookPrivilege ?
              <>
                <EuiHeaderLink href="/ExcelAddin/help.pdf">Excel 插件</EuiHeaderLink>
              </> :
              null
          }
        </EuiHeaderLinks>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          {
            isLogined ?
              <HeaderUserMenu /> :
              <EuiHeaderLink onClick={() => history.push('/login')} > 登录 </EuiHeaderLink>
          }
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}
