import produce from 'immer'
import _ from 'lodash'
import { createContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import create from 'zustand'
import { devtools } from 'zustand/middleware'
import rzApi, { WidgetDataDict } from '../Services/Api'
import { generateWidgetId, getWidgetMeta } from '../Widgets/WidgetRegistry'


export const EmptyPage: WidgetDataDict = {
  "_root": ["PageRoot", { children: [] }]
}

export type PageInfo = {
  pageId: string
  title: string
  content: WidgetDataDict
  isEditing: boolean
  isModified: boolean
  isLoading: boolean
  loadingError: string
}

export const PageIdContext = createContext<string>('')

export const usePageStore = create(devtools(() => ({
  topicId: '',
  topicName: '',
  activePageId: '',
  pages: [] as PageInfo[]
}), { name: 'PageStore' }))

type PageStore = ReturnType<typeof usePageStore.getState>

export function useActivePageInfo(): PageInfo | undefined;
export function useActivePageInfo<T>(fn: (info?: PageInfo) => T): T;
export function useActivePageInfo<T>(fn?: (info?: PageInfo) => T): T | PageInfo | undefined {
  return usePageStore(state => {
    const page = _.find(state.pages, p => p.pageId === state.activePageId)
    return fn ? fn(page) : page
  })
}

export function usePageInfo(pageId: string): PageInfo | undefined;
export function usePageInfo<T>(pageId: string, fn: (info?: PageInfo) => T): T;
export function usePageInfo<T>(pageId: string, fn?: (info?: PageInfo) => T): T | PageInfo | undefined {
  return usePageStore(state => {
    const page = _.find(state.pages, p => p.pageId === pageId)
    return fn ? fn(page) : page
  })
}

export function isEmptyPage(page: PageInfo | undefined) {
  if (page) {
    const isEmptyWidget = (widget: [string, any]) => {
      const [type, data] = widget
      if (type === 'Col' || type === 'Row' || type === 'PageRoot') {
        for (let childId of data.children) {
          const childWidget = page.content[childId]
          if (!isEmptyWidget(childWidget)) {
            return false
          }
        }
        return true
      } else {
        return false
      }
    }
    return isEmptyWidget(page.content['_root'])
  } else {
    return true
  }
}

// <PageStoreInitializer /> 可以作为一个组件加入到 Virtual DOM Tree 里
//
export const PageStoreInitializer = ({ pageId: propPageId }: { pageId?: string }) => {
  const history = useHistory()
  const params = useParams<{ topicId?: string, pageId?: string }>()
  const urlTopicId = params.topicId || ''
  const urlPageId = params.pageId || ''

  const topicId = usePageStore(state => state.topicId)
  const firstPageId = usePageStore(state => state.pages[0]?.pageId || '')
  const pageId = propPageId || urlPageId || ''

  useEffect(() => {
    if (topicId && topicId === urlTopicId && !urlPageId && firstPageId) {
      history.replace(`/topic/${topicId}/${firstPageId}`)
    } else if (topicId !== urlTopicId) {
      usePageStore.setState({
        topicId: urlTopicId,
        topicName: '',
        activePageId: pageId,
        pages: []
      })
    } else {
      usePageStore.setState({
        activePageId: pageId
      })
    }
  }, [propPageId, topicId, pageId, urlTopicId, urlPageId, firstPageId, history])

  useEffect(() => {
    if (topicId) {
      loadTopic(topicId)
    }
  }, [topicId])

  useEffect(() => {
    if (!topicId && pageId) {
      loadSoloPage(pageId)
    }
  }, [topicId, pageId])

  useEffect(() => () => clear(), [])

  return null
}

export function getWidgetDesc(widgetType: string, widgetProps: any) {
  return getWidgetMeta(widgetType)?.name || widgetType
}


///////////////////////////////////////////////////////////////////////////////
// Actions
//
function setEditMode(isEditing: boolean) {
  usePageStore.setState(
    produce((state: PageStore) => {
      const page = _.find(state.pages, p => p.pageId === state.activePageId)
      if (page) {
        page.isEditing = isEditing
      }
    }))
}

async function loadTopic(topicId: string) {
  usePageStore.setState({
    topicId,
    topicName: '',
    pages: []
  })
  try {
    const topic = await rzApi.getTopic(topicId)

    if (usePageStore.getState().topicId === topicId) {
      usePageStore.setState({
        topicName: topic.Title,
        pages: topic.Pages.map(p => ({
          pageId: p.Id,
          title: p.Title,
          content: p.Content,
          isEditing: false,
          isModified: false,
          isLoading: false,
          loadingError: ''
        }))
      })
    }
  } catch (e) {
  }
}

// 重新加载页面内容，并更新进 store
async function reloadPageInfo(pageId: string) {
  try {
    const p = await rzApi.getPage(pageId)
    usePageStore.setState(
      produce((state: PageStore) => {
        const page = _.find(state.pages, p => p.pageId === pageId)
        if (page) {
          page.title = p.Title
          page.content = p.Content
          page.isModified = false
        }
      })
    )
  } catch {
  }
}

async function loadSoloPage(pageId: string) {
  usePageStore.setState({
    topicId: '',
    topicName: '',
    activePageId: pageId,
    pages: []
  })

  try {
    const page = await rzApi.getPage(pageId)
    const state = usePageStore.getState()
    if (state.activePageId === pageId && !state.topicId) {
      usePageStore.setState({
        topicName: '',
        activePageId: pageId,
        pages: [{
          pageId: pageId,
          title: page.Title,
          content: page.Content,
          isEditing: false,
          isModified: false,
          isLoading: false,
          loadingError: ''
        }]
      })
    }
  } catch (e) {
    const state = usePageStore.getState()
    if (state.activePageId === pageId && !state.topicId) {
      usePageStore.setState({
        topicName: '',
        pages: [{
          pageId: pageId,
          title: '',
          content: EmptyPage,
          isEditing: false,
          isModified: false,
          isLoading: false,
          loadingError: `错误: ${e.status}: ${e.message}`
        }]
      })
    }
  }
}

function clear() {
  usePageStore.setState({
    topicId: '',
    topicName: '',
    activePageId: '',
    pages: []
  })
}

async function savePageContent(pageId: string) {
  const state = usePageStore.getState()
  const page = _.find(state.pages, p => p.pageId === pageId)
  if (page) {
    usePageStore.setState(produce(
      (state: PageStore) => {
        _.find(state.pages, p => p.pageId === pageId)!.isModified = false
      })
    )
    await rzApi.updatePage(pageId, {
      Content: page.content
    })
  }
}

function getWidgetChildren(pageId: string, widgetId: string) {
  const state = usePageStore.getState()
  const page = _.find(state.pages, p => p.pageId === pageId)
  if (page) {
    const winfo = page.content[widgetId]
    if (!winfo || !winfo[1]?.children) {
      return []
    }
    return winfo[1].children
  } else {
    return []
  }
}

function setWidgetChildren(pageId: string, widgetId: string, children: string[]) {
  const newState = produce(
    (state: PageStore) => {
      const page = _.find(state.pages, p => p.pageId === pageId)
      if (page) {
        const winfo = page.content[widgetId]
        if (!winfo) {
          return
        }
        winfo[1].children = children
        page.isModified = true
      }
    })
  usePageStore.setState(newState, true)
}


function addWidget(pageId: string, containerId: string, widgetType: string, widgetData: any) {
  const widgetId = generateWidgetId(widgetType)
  const newState = produce(
    (state: PageStore) => {
      const page = _.find(state.pages, p => p.pageId === pageId)
      if (page) {
        page.content[widgetId] = [widgetType, widgetData]
        page.content[containerId][1].children.push(widgetId)
        page.isModified = true
      }
    }
  )
  usePageStore.setState(newState, true)
  return widgetId
}

function setWidgetData(pageId: string, widgetId: string, widgetData: any) {
  const newState = produce(
    (state: PageStore) => {
      const page = _.find(state.pages, p => p.pageId === pageId)
      if (page) {
        page.content[widgetId][1] = widgetData
        page.isModified = true
      }
    }
  )
  usePageStore.setState(newState, true)
  return widgetId
}

function removeWidget(pageId: string, widgetId: string) {
  const newState = produce(
    (state: PageStore) => {
      const page = _.find(state.pages, p => p.pageId === pageId)
      if (page) {
        _.forOwn(page.content, ([wtype, data]) => {
          const children = data?.children as string[]
          if (children) {
            const i = children.indexOf(widgetId)
            if (i >= 0) {
              children.splice(i, 1)
            }
          }
        })
        delete page.content[widgetId]
        page.isModified = true
      }
    }
  )
  usePageStore.setState(newState, true)
}

export const pageStoreActions = {
  setEditMode,
  loadTopic,
  reloadPageInfo,
  loadSoloPage,
  clear,
  savePageContent,
  getWidgetChildren,
  setWidgetChildren,
  addWidget,
  setWidgetData,
  removeWidget,
}
