import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer } from '@elastic/eui'
import _ from 'lodash'
import React, { useContext } from 'react'
import {  GenericVisContext, useGenericVisState, useConsolidatedPref } from '../GenericVisStore'
import { WidgetEditorProps } from '../../WidgetRegistry'
import { GenericVisPreview } from '../GenericVis'
import { ChartTemplate, ChartTemplateNameMap, ChartTemplateProps, GenericVisPreference } from '../types'
import ChartPanel from './ChartPanel/ChartPanel'
import DataPanel from './DataPanel/DataPanel'

export const TemplateSelect = () => {
  const { pref, actions } = useContext(GenericVisContext)
  const isFixed = pref.template && (ChartTemplateNameMap[pref.template] as ChartTemplateProps).fixed
  return (
    isFixed
      ? null
      : <EuiKeyPadMenu className="template-select" checkable>
        {
          Object.keys(ChartTemplateNameMap)
            .filter(key => !(ChartTemplateNameMap as any)[key].fixed)
            .map(key => {
              const template = key as ChartTemplate
              return <EuiKeyPadMenuItem
                key={template}
                id={template}
                name={template}
                checkable="single"
                label={ChartTemplateNameMap[template].name}
                onChange={() => actions.switchTemplate(template)}
                isSelected={template === pref.template}
              >
                <EuiIcon type={ChartTemplateNameMap[template].icon} size="l" />
              </EuiKeyPadMenuItem>
            })
        }
      </EuiKeyPadMenu>
  )
}

const GenericEditor = ({ value, setValue }: WidgetEditorProps<GenericVisPreference>) => {
  const state = useGenericVisState(value, setValue)
  const solidData = useConsolidatedPref(state.pref, state.fieldData)

  return (
    <GenericVisContext.Provider value={state}>
      <EuiFlexGroup gutterSize="m" style={{ width: 1200, minHeight: 470, maxHeight: 470 }}>
        <EuiFlexItem grow={3}>
          <DataPanel />
        </EuiFlexItem>
        <EuiFlexItem grow={6}>
          <TemplateSelect />
          <EuiSpacer size="s" />
          <GenericVisPreview data={solidData} size="s" />
        </EuiFlexItem>
        <EuiFlexItem grow={2}>
          <ChartPanel />
        </EuiFlexItem>
      </EuiFlexGroup>
    </GenericVisContext.Provider>
  )
}

export default GenericEditor
