import { EuiEmptyPrompt, EuiLoadingSpinner } from '@elastic/eui'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ThirdPartyPlatform } from '../Services/Api'
import { thirdPartyAuthStoreActions } from '../Stores/ThirdPartyAuthStore'
import { useIsLogined } from '../Stores/UserStore'
import { AutoRedirect } from './LoginPage'

type CustomState = {
  rememberMe?: boolean
  platform: ThirdPartyPlatform
}

const ThirdParthNameMap: { [k in ThirdPartyPlatform]: string } = {
  'dingtalk': '钉钉',
  'wechat': '微信'
}

const parseState = (param: string | null): CustomState | null => {
  try {
    if (param) {
      return JSON.parse(param) as CustomState
    }
  } catch (err) {
    // ignore
  }
  return null
}

const useSearchParams = () => {
  const { search } = useLocation()
  return useMemo(() => {
    const params = new URLSearchParams(search)
    return {
      code: params.get('code'),
      state: parseState(params.get('state'))
    }
  }, [search])
}

const OAuther = () => {
  const { code, state } = useSearchParams()
  const [error, setError] = useState('')
  useEffect(() => {
    const f = async () => {
      try {
        if (code) {
          if (state?.platform) {
            // 第三方平台授权完成后，useThirdPartyAuthStore的status会变成'logged-in'，然后会在AppInit中queryMe
            await thirdPartyAuthStoreActions.auth(code, state.platform, !!state?.rememberMe)
          }
        }
      } catch (err) {
        setError(err.message)
      }
    }
    f()
  }, [code, state])
  return (error
    ? <EuiEmptyPrompt
      iconType="alert"
      color="danger"
      title={<h2>{error}</h2>}
    />
    : <EuiEmptyPrompt
      icon={<EuiLoadingSpinner size="xl" />}
      title={<h2>{`正在使用${state?.platform ? ThirdParthNameMap[state.platform] : ''}登录`}</h2>}
    />
  )
}

const useParamsError = () => {
  const { code, state } = useSearchParams()
  return useMemo(() => {
    if (!code) {
      return '授权码非法'
    }
    if (!state) {
      return '错误的状态值'
    } else {
      if (state.platform !== 'dingtalk') {
        return `不支持的第三方平台：${state}`
      }
    }
    return ''
  }, [code, state])
}

const OAuthPage = () => {
  const isLogined = useIsLogined()
  const error = useParamsError()
  return (
    isLogined
      ? <AutoRedirect />
      : (error
        ? <EuiEmptyPrompt
          iconType="alert"
          color="danger"
          title={<h2>{error}</h2>}
        />
        : <OAuther />
      )
  )
}

export default OAuthPage
