import {
  EuiButton, EuiButtonEmpty, EuiFieldText, EuiFilePicker, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle,
  EuiSpacer, EuiSteps, EuiText, EuiTextArea, EuiTextAreaProps, EuiTextColor
} from '@elastic/eui'
import React, { useState, PropsWithChildren, useCallback, useMemo } from 'react'

export type CommitResult = Promise<string | null | void> | string | null | void
export type ColorType = "warning" | "danger"

///////////////////////////////////////////////////////////////////////////////
//
// Dialog
//
interface DialogProps {
  title: string
  initialFocus?: HTMLElement | (() => HTMLElement) | string
  color?: ColorType
  buttons?: [string, string] | [string]
  style?: React.CSSProperties
  maxWidth?: number
  commit?: () => CommitResult
  dismiss: () => void
}

export const Dialog = (props: PropsWithChildren<DialogProps>) => {
  const [state, setState] = useState({
    error: '',
    isCommitting: false
  })

  const onOk = async () => {
    let error
    if (props.commit) {
      setState({
        error: '',
        isCommitting: true
      })
      try {
        error = props.commit()
        if (error && (error as any).then) {
          error = (await error) || ''
        }
      } catch (e) {
        error = e.message
      }
      setState({
        error: '',
        isCommitting: false
      })
    }
    if (error) {
      setState({
        error: error,
        isCommitting: false
      })
    } else {
      props.dismiss()
    }
  }

  return (
    <EuiModal onClose={props.dismiss} initialFocus={props.initialFocus} style={props.style} maxWidth={props.maxWidth}>
      <EuiModalHeader>
        <EuiModalHeaderTitle><EuiTextColor color={props.color}>{props.title}</EuiTextColor></EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {props.children}
        <EuiSpacer />
        {
          state.error ? <EuiText color="danger"><p>{state.error}</p></EuiText> : null
        }
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButtonEmpty onClick={props.dismiss} color="text">
          {(props.buttons && props.buttons[1]) || 'Cancel'}
        </EuiButtonEmpty>
        <EuiButton onClick={onOk} fill color={props.color} isLoading={state.isCommitting}>
          {(props.buttons && props.buttons[0]) || 'Ok'}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  )
}

///////////////////////////////////////////////////////////////////////////////
//
// ConfirmDialog
//
export interface ConfirmDialogProps {
  title: string
  prompt?: string
  buttons?: [string, string] | [string]
  color?: ColorType
  style?: React.CSSProperties
  commit: () => CommitResult
  dismiss: () => void
}
export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog title={props.title} buttons={props.buttons} color={props.color} style={props.style}
      commit={props.commit} dismiss={props.dismiss}>
      {props.prompt ? <EuiText color={props.color}><p>{props.prompt}</p></EuiText> : null}
    </Dialog>
  )
}

///////////////////////////////////////////////////////////////////////////////
//
// InputTextDialog
//
export interface InputTextDialogProps {
  title: string
  prompt: string
  initialValue?: string
  color?: ColorType
  commit: (value: string) => CommitResult
  dismiss: () => void
}
export const InputTextDialog = (props: InputTextDialogProps) => {
  const [text, setText] = useState(props.initialValue || '')
  return (
    <Dialog title={props.title} initialFocus="[name=inputField]" color={props.color}
      commit={() => props.commit(text)}
      dismiss={props.dismiss}>
      <EuiForm>
        <EuiFormRow label={props.prompt}>
          <EuiFieldText name="inputField" value={text}
                onChange={e => setText(e.target.value) } />
        </EuiFormRow>
      </EuiForm>
    </Dialog>)
}

///////////////////////////////////////////////////////////////////////////////
//
// InputTextDialog
//
export interface InputTextareaDialogProps {
  title: string
  prompt: string
  initialValue?: string
  color?: ColorType
  resize: EuiTextAreaProps['resize']
  commit: (value: string) => CommitResult
  dismiss: () => void
}
export const InputTextareaDialog = (props: InputTextareaDialogProps) => {
  const [text, setText] = useState(props.initialValue || '')
  return (
    <Dialog title={props.title} initialFocus="[name=inputTextarea]" color={props.color}
      commit={() => props.commit(text)}
      dismiss={props.dismiss}>
      <EuiForm>
        <EuiFormRow label={props.prompt}>
          <EuiTextArea
            name="inputTextarea"
            value={text}
            resize={props.resize}
            onChange={ e => setText(e.target.value) } />
        </EuiFormRow>
      </EuiForm>
    </Dialog>)
}

///////////////////////////////////////////////////////////////////////////////
//
// InputFileDialog
//
export interface InputFileDialogProps {
  title: string
  prompt: string
  instructions?: React.ReactNode
  multiple?: boolean
  large?: boolean
  style?: React.CSSProperties
  commit: (file: FileList) => CommitResult
  dismiss: () => void
}
export const InputFileDialog = (props: InputFileDialogProps) => {
  const [files, setFiles] = useState(null as FileList | null)
  const commit = useCallback(() => {
    if (!files || files.length === 0) {
      return "没有选择任何文件"
    }
    props.commit(files)
  }, [props, files])

  return (
    <Dialog title={props.title} style={props.style}
      commit={commit}
      dismiss={props.dismiss}>
      {props.instructions}
      <EuiFilePicker
        multiple={props.multiple}
        initialPromptText={props.prompt}
        onChange={setFiles}
        display={props.large ? 'large' : 'default'}
      />
    </Dialog>)
}

///////////////////////////////////////////////////////////////////////////////
//
// InputFileStepsDialog
//
export interface InputFileStepsDialogProps {
  title: string
  prompt: string
  steps: Array<{ title: string, children: React.ReactNode }>
  multiple?: boolean
  large?: boolean
  style?: React.CSSProperties
  commit: (file: FileList) => CommitResult
  dismiss: () => void
}
export const InputFileStepsDialog = (props: InputFileStepsDialogProps) => {
  const [files, setFiles] = useState(null as FileList | null)
  const commit = useCallback(() => {
    if (!files || files.length === 0) {
      return "没有选择任何文件"
    }
    props.commit(files)
  }, [props, files])

  const steps = useMemo(() => {
    const data = Array.from(props.steps)
    const lastStep = data[data.length - 1]
    data[data.length - 1] = {
      title: lastStep.title,
      children: <EuiFilePicker
        multiple={props.multiple}
        initialPromptText={props.prompt}
        onChange={setFiles}
        display={props.large ? 'large' : 'default'}
      />
    }
    return data
  }, [props])
  return (
    <Dialog title={props.title} style={props.style}
      commit={commit}
      dismiss={props.dismiss}>
      <EuiSteps steps={steps} />
    </Dialog>)
}
