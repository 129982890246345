import { EuiEmptyPrompt } from '@elastic/eui'
import React from 'react'

export interface ErrorPageProps {
  errMsg: string
}

const ErrorPage = (props: ErrorPageProps) => {
  return <EuiEmptyPrompt
    iconType="alert"
    title={
      <h2>{props.errMsg}</h2>
    }
    titleSize="l"
  />
}

export default ErrorPage
