import React, { PropsWithChildren, forwardRef, useState, useImperativeHandle } from 'react';
import { EuiExpression, EuiPopover } from '@elastic/eui';

interface ExpressionEditorProps {
  description?: string
  value?: string
  className?: string
  ownFocus?: boolean
}
export interface IExpressionPopover {
  close(): void
}
const ExpressionEditor_ = (
  props: PropsWithChildren<ExpressionEditorProps>,
  ref: React.Ref<IExpressionPopover>
) => {
  const [isOpen, setIsOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    close: () => setIsOpen(false)
  }))

  return (
    <EuiPopover
      button={
        <EuiExpression className={props.className}
          description={props.description}
          value={props.value}
          isActive={isOpen}
          onClick={() => setIsOpen(true)}
          type="button"
        />
      }
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      ownFocus={props.ownFocus ?? true}
      panelPaddingSize="s"
      anchorPosition="downLeft">
      {props.children}
    </EuiPopover>
  )
}
export default forwardRef<IExpressionPopover, PropsWithChildren<ExpressionEditorProps>>(ExpressionEditor_)
