import { PageStoreInitializer } from '../Stores/PageStore'
import DataPage from './DataPage'

const HomePage = () => {
  return <>
    <PageStoreInitializer pageId="index" />
    <DataPage pageId="index" />
  </>
}

export default HomePage
