import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPopover, EuiSelect, EuiSwitch } from "@elastic/eui"
import { useState } from "react"
import { AggMethod, GenericVisField, VisTable, VisType } from "../../types"
import { ConditionalFormattingOptions } from "../ChartPanel/CustomPanel"
import { AggregateOptions, useVisTypeOptions } from "./FieldEditor"

export type DataFieldExtraProps = {
  field: GenericVisField
  visType: VisType
  onChange: (field: GenericVisField) => void
}

const ExpandedFieldEditor = ({
  field,
  visType,
  onChange
}: DataFieldExtraProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
  const visTypeOptions = useVisTypeOptions(visType)
  return <EuiPopover
    button={<EuiButtonIcon
      aria-label="field-extra-config"
      iconType="controlsHorizontal"
      display="base"
      size='xs'
      color="text"
      onClick={onButtonClick}
    />}
    isOpen={isPopoverOpen}
    closePopover={() => { setIsPopoverOpen(false) }}
  >
    <EuiFlexGroup gutterSize="s" style={{ paddingTop: 6, paddingBottom: 6 }} justifyContent="center">
      <EuiFlexItem grow={true}>
        <EuiFormRow label="数字格式" display="rowCompressed">
          <EuiFieldText
            compressed
            placeholder="0.00"
            value={field.numberFormat}
            onChange={e => onChange({ ...field, numberFormat: e.target.value })}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={true}>
        {visType.type === 'table' ?
          <EuiFormRow label="单元格样式" display="rowCompressed">
            <EuiSelect
              options={ConditionalFormattingOptions}
              value={(field.visType as VisTable)?.conditionalFormatting || 'default'}
              onChange={e => {
                const conditionalFormatting = e.target.value === 'default' ? undefined : e.target.value
                onChange({
                  ...field,
                  visType: {
                    ...field.visType || {},
                    ...{ conditionalFormatting }
                  } as VisTable
                })
              }}
              compressed
            />
          </EuiFormRow> : undefined}
        {visType.type !== 'pie' && visType.type !== 'table' ?
          <EuiFormRow label="显示样式" display="rowCompressed">
            <EuiSelect
              options={visTypeOptions}
              value={field.visType?.type || 'default'}
              onChange={e => onChange({ ...field, visType: e.target.value === 'default' ? undefined : { type: e.target.value as VisType['type'] } })}
              compressed
            />
          </EuiFormRow> : undefined}
      </EuiFlexItem>
    </EuiFlexGroup>
    {visType.type !== 'pie' && visType.type !== 'table' ?
      <EuiFormRow label="Y轴" display="rowCompressed">
        <EuiSwitch
          label="使用右轴"
          checked={!!field.onSecondYAxis}
          onChange={(e) => { onChange({ ...field, onSecondYAxis: e.target.checked }) }}
        />
      </EuiFormRow> : null}
    <EuiFormRow label="聚合方式" display="rowCompressed">
      <EuiSelect
        options={AggregateOptions}
        value={field.aggMethod || 'last'}
        onChange={e => onChange({ ...field, aggMethod: e.target.value as AggMethod })}
        compressed />
    </EuiFormRow>
  </EuiPopover>
}

export default ExpandedFieldEditor
