import { EuiContextMenuItem, EuiContextMenuPanel, EuiHeaderLink, EuiPopover } from '@elastic/eui'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { uiStoreActions } from '../Stores/UiStore'
import { useIsLogined, userStoreActions, useUserStore } from '../Stores/UserStore'


const HeaderUserMenu = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const isLogined = useIsLogined()
  const currentUserDisplayName = useUserStore(state => state.me?.DisplayName)

  return (
    <EuiPopover
      id="contextMenu"
      button={
        <EuiHeaderLink iconType="user" onClick={ () => setIsOpen(!isOpen) }>
          { currentUserDisplayName }
        </EuiHeaderLink >
      }
      isOpen={isOpen}
      closePopover={() => setIsOpen(!isOpen) }
      panelPaddingSize="none"
      anchorPosition="downLeft">
      <EuiContextMenuPanel items={[
        <EuiContextMenuItem
          key="changePassword"
          onClick={uiStoreActions.changePassword}>
          修改密码
        </EuiContextMenuItem>
        ,
        <EuiContextMenuItem
          key="logout"
          onClick={async () => {
            setIsOpen(false)
            if (isLogined) {
              await userStoreActions.signOut()
              history.push("/Login")
            }
          }}>
          退出登录
        </EuiContextMenuItem>
      ]} />
    </EuiPopover>)
}

export default HeaderUserMenu
