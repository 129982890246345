import {
  EuiButtonEmpty, EuiCommentList, EuiCommentProps, EuiFieldNumber, EuiFieldText, EuiFlexGroup,
  EuiFlexItem, EuiFormRow, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiPagination, EuiPanel, EuiRadio, EuiSpacer, EuiText
} from "@elastic/eui"
import _ from "lodash"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import rzApi, { NewsStreamItem } from "../Services/Api"
import { NewsStreamData } from "../Services/WidgetTypes"
import { uiStoreActions } from "../Stores/UiStore"
import { usePrivilege, useUserDisplayNames } from "../Stores/UserStore"
import { withEditBar } from "./EditBar"
import { DEFAULT_DAYS } from "./NewsStream.global"
import { IValueProvider, registerWidget, WidgetEditorProps, WidgetProps } from "./WidgetRegistry"

function useCommentProps(items: NewsStreamItem[], onUpdate: ((quoteId: number) => void) | null): EuiCommentProps[] {
  const userDisplayNames = useUserDisplayNames(items.map(x => x.UpdateBy[0]))
  return _.chain(items)
    .groupBy(item => item.QuoteId ?? item.Id)
    .map(items => items.sort((a, b) => moment(a.UpdateBy[1]).isBefore(moment(b.UpdateBy[1])) ? -1 : 1))
    .sort((a, b) => moment(a[0].UpdateBy[1]).isAfter(moment(b[0].UpdateBy[1])) ? -1 : 1)
    .flatten()
    .map((item, i) => {
      const updateUser = userDisplayNames[i]
      if (item.QuoteId) {
        return {
          username: updateUser,
          timelineAvatarAriaLabel: 'Update',
          timelineAvatar: 'dot',
          timestamp: `${moment.utc(item.UpdateBy[1]).local().format('YYYY-MM-DD HH:mm')}`,
          event: '补充于',
          children: <EuiText size="s" className="pre-text">{item.Content.trim()}</EuiText>,
        }
      } else
        return {
          username: updateUser,
          timelineAvatarAriaLabel: updateUser,
          timestamp: `${moment.utc(item.UpdateBy[1]).local().format('YYYY-MM-DD HH:mm')}`,
          event: '发布于',
          children: <EuiText className="pre-text">{item.Content.trim()}</EuiText>,
          timelineIcon: "editorComment",
          actions: onUpdate
            ? <EuiButtonEmpty onClick={() => onUpdate(item.Id)} size="s">补充说明</EuiButtonEmpty>
            : null
        }
    })
    .value()
}

///////////////////////////////////////////////////////////////////////////////
//
function usePagenatedNewStreamState(id: number, pageSize: number) {
  const [title, setTitle] = useState('')
  const [items, setItems] = useState<NewsStreamItem[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(1)

  useEffect(() => {
    const loadTitle = async () => {
      const newsStream = await rzApi.getNewsStream(id)
      setTitle(newsStream.Title)
    }
    const loadPage = async () => {
      const paginatedItems = await rzApi.getPaginatedNewsStreamItems(id, pageSize, currentPage)
      setItems(paginatedItems.Items)
      setTotalPageCount(paginatedItems.PageCount)
    }
    loadTitle()
    loadPage()
  }, [id, pageSize, currentPage])

  return {
    title, items, totalPageCount, currentPage, setCurrentPage
  }
}

type NewsStreamModalProps = {
  newsstream_id: number
  dismiss: () => void
}

const NewsStreamModal = ({ newsstream_id, dismiss }: NewsStreamModalProps) => {
  const { title, items, totalPageCount, currentPage, setCurrentPage } = usePagenatedNewStreamState(newsstream_id, 5)
  const commentProps = useCommentProps(items, null)
  return <EuiModal onClose={dismiss}>
    <EuiModalHeader>
      <EuiModalHeaderTitle><h1>{title}</h1></EuiModalHeaderTitle>
    </EuiModalHeader>
    <EuiModalBody>
      <EuiPagination
        pageCount={totalPageCount}
        activePage={currentPage}
        onPageClick={setCurrentPage}
      />
      <EuiCommentList
        comments={commentProps}
      />
      <EuiPagination
        pageCount={totalPageCount}
        activePage={currentPage}
        onPageClick={setCurrentPage}
      />
    </EuiModalBody>
  </EuiModal>
}

///////////////////////////////////////////////////////////////////////////////
//
function useNewsStreamState(id: number, days: number) {
  const [title, setTitle] = useState('')
  const [items, setItems] = useState<NewsStreamItem[]>([])

  useEffect(() => {
    const loadTitle = async () => {
      const newsStream = await rzApi.getNewsStream(id)
      setTitle(newsStream.Title)
    }
    const loadItems = async () => {
      setItems(await rzApi.getLatestNewsStreamItems(id, days))
    }
    loadTitle()
    loadItems()
  }, [id, days])

  const onAddItem = useCallback(() =>
    uiStoreActions.inputTextarea({
      title: `发布信息`,
      prompt: '',
      initialValue: '',
      commit: async content => {
        await rzApi.addNewsStreamItem(id, content, null)
        setItems(await rzApi.getLatestNewsStreamItems(id, days))
      }
    }), [setItems, id, days])

  const onUpdateItem = (updatedItemId: number) => {
    uiStoreActions.inputTextarea({
      title: `补充说明`,
      prompt: '',
      initialValue: '',
      commit: async content => {
        await rzApi.addNewsStreamItem(id, content, updatedItemId)
        setItems(await rzApi.getLatestNewsStreamItems(id, days))
      }
    })
  }

  return {
    title, items, onAddItem, onUpdateItem
  }
}

export const NewsStream = React.memo(({ widgetData: { newsstream_id, days } }: WidgetProps<NewsStreamData>) => {
  const { title, items, onAddItem, onUpdateItem } = useNewsStreamState(newsstream_id, days)
  const hasWritePrivilege = usePrivilege('WriteData')
  const commentProps = useCommentProps(items, hasWritePrivilege ? onUpdateItem : null)
  const [isModalVisible, setIsModalVisible] = useState(false)

  return <EuiPanel hasBorder={false} hasShadow={false}>
    <EuiFlexGroup alignItems="baseline">
      <EuiFlexItem grow={false}>
        <EuiText><h3>{title}</h3></EuiText>
      </EuiFlexItem>
      {hasWritePrivilege
        ? <EuiFlexItem grow={false}>
          <EuiButtonEmpty onClick={onAddItem} >发布信息</EuiButtonEmpty>
        </EuiFlexItem>
        : null}
      <EuiFlexItem grow={true}>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={() => { setIsModalVisible(true) }}>查看全部</EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer size="m" />
    <EuiCommentList
      comments={commentProps}
    />
    {isModalVisible ? <NewsStreamModal newsstream_id={newsstream_id} dismiss={() => { setIsModalVisible(false) }} /> : null}
  </EuiPanel >
})

///////////////////////////////////////////////////////////////////////////////
//
function useNewsStreamEditorState(newsStreamId: number, initialDays: number) {
  const [title, setTitle] = useState('')
  const [days, setDays] = useState(initialDays)
  const [createOrRef, setCreateOrRef] = useState<'create' | 'ref'>('create')

  useEffect(() => {
    if (newsStreamId) {
      const loadTitle = async () => {
        const newsStream = await rzApi.getNewsStream(newsStreamId)
        setTitle(newsStream.Title)
      }
      loadTitle()
    }
  }, [newsStreamId])

  const valueProvider: IValueProvider<NewsStreamData> = useMemo(() => ({
    getValue: async () => {
      if (newsStreamId >= 0) {
        await rzApi.updateNewsStream(newsStreamId, title)
        return {
          newsstream_id: newsStreamId,
          days: days || DEFAULT_DAYS
        }
      } else if (createOrRef === 'ref') {
        const newsStream = await rzApi.getNewsStreamByTitle(title)
        if (!newsStream) {
          throw new Error(`主题 ${title} 不存在`)
        }
        return {
          newsstream_id: newsStream.Id,
          days: days || DEFAULT_DAYS
        }
      } else {
        console.assert(createOrRef === 'create')
        const newsStream = await rzApi.addNewsStream(title)
        return {
          newsstream_id: newsStream.Id,
          days: days || DEFAULT_DAYS
        }
      }
    }
  }), [newsStreamId, title, days, createOrRef])

  return {
    title, setTitle, days, setDays, createOrRef, setCreateOrRef, valueProvider
  }
}

const GROUP_NAME_CREATE_OR_REF = "d18242b7-f289-401c-8066-4e024fc4841f"

const NewsStreamEditor = ({ value, setValueProvider }: WidgetEditorProps<NewsStreamData>) => {
  const { title, setTitle, days, setDays, createOrRef, setCreateOrRef, valueProvider } = useNewsStreamEditorState(value.newsstream_id, value.days)

  useEffect(
    () => {
      setValueProvider(valueProvider)
      return () => setValueProvider(null)
    },
    [setValueProvider, valueProvider]
  )

  return <>
    {value.newsstream_id < 0
      ? <EuiFormRow label="">
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiRadio
              id="create"
              name={GROUP_NAME_CREATE_OR_REF}
              label="新建栏目"
              checked={createOrRef === 'create'}
              onChange={(e) => { setCreateOrRef(e.target.checked ? 'create' : 'ref') }}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiRadio
              id="ref"
              name={GROUP_NAME_CREATE_OR_REF}
              label="引用已存在的栏目"
              checked={createOrRef === 'ref'}
              onChange={(e) => { setCreateOrRef(e.target.checked ? 'ref' : 'create') }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      : null}
    <EuiFormRow label="主题">
      <EuiFieldText
        value={title}
        compressed
        onChange={e => setTitle(e.target.value)}
      ></EuiFieldText>
    </EuiFormRow>
    <EuiFormRow label="显示几天内的消息">
      <EuiFieldNumber
        min={1}
        value={days || ''}
        compressed
        onChange={e => setDays(e.target.valueAsNumber)}
      ></EuiFieldNumber>
    </EuiFormRow>
  </>
}

registerWidget(withEditBar({
  widgetType: "NewsStream",
  widget: NewsStream,
  editor: NewsStreamEditor,
  name: "动态信息",
  insertable: true,
  defaultProps: {
    newsstream_id: -1,
    days: DEFAULT_DAYS
  }
}))
