import { EuiButtonEmpty, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer, EuiSwitch, EuiText, EuiTextColor } from '@elastic/eui'
import { useMemo } from 'react'
import FormulaEditor from '../../../../Components/FormulaEditor'
import { AggMethod, GenericVisField, VisTable, VisType } from '../../types'
import { ConditionalFormattingOptions } from '../ChartPanel/CustomPanel'

export const AggregateOptions = [
  { text: '最后一个', value: 'last' },
  { text: '求和', value: 'sum' },
  { text: '第一个', value: 'first' },
  { text: '平均', value: 'mean' }
]

export const useVisTypeOptions = (visType: VisType) => {
  return useMemo(() => {
    if (visType.type === 'table') {
      return [
        { text: '不指定', value: 'default' }
      ]
    } else if (visType.type === 'pie') {
      return [
        { text: '不指定', value: 'default' }
      ]
    } else {
      return [
        { text: '不指定', value: 'default' },
        { text: '折线', value: 'line' },
        { text: '柱形', value: 'bar' },
        { text: '散点', value: 'scatter' }]
    }
  }, [visType])
}

export type FieldEditorProps = {
  field: GenericVisField
  visType: VisType
  onChange: (field: GenericVisField) => void
  onDismiss: () => void
}

const FieldEditor = ({
  field,
  visType,
  onChange,
  onDismiss
}: FieldEditorProps) => {
  const visTypeOptions = useVisTypeOptions(visType)

  const titleWithClose = <EuiFlexGroup justifyContent="spaceBetween" gutterSize="none" alignItems="baseline" >
    <EuiFlexItem><EuiText>数据项属性</EuiText></EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiButtonEmpty
        iconType="arrowLeft"
        onClick={onDismiss}
        flush="left"
        size="xs"
      >
        返回列表
      </EuiButtonEmpty>
    </EuiFlexItem>
  </EuiFlexGroup>

  return (
    <EuiPanel style={{ animation: '0.5s 1 alternate euiModal' }} paddingSize="s" grow={true}>
      <EuiFlexGroup gutterSize="none" direction="column" justifyContent='spaceBetween'>
        <EuiFlexItem>
          {titleWithClose}
        </EuiFlexItem>
        <EuiSpacer size="xs" />
        <EuiForm>
          <EuiFormRow label="名称" display="rowCompressed">
            <EuiFieldText
              compressed
              value={field.label}
              onChange={e => onChange({ ...field, label: e.target.value })}
              // onFocus={e => e.target.select()}
              autoFocus />
          </EuiFormRow>
          <EuiFormRow label={<span>公式<EuiTextColor color="subdued">（Ctrl+/ 搜索数据项）</EuiTextColor></span>} display="rowCompressed">
            <FormulaEditor
              compressed
              initialValue={field.expr}
              onChange={value => {
                onChange({ ...field, expr: value })
              }} />
          </EuiFormRow>
          <EuiFlexGroup gutterSize="s" style={{ paddingTop: 6, paddingBottom: 6 }} justifyContent="center">
            <EuiFlexItem grow={true}>
              <EuiFormRow label="数字格式" display="rowCompressed">
                <EuiFieldText compressed placeholder="0.00" value={field.numberFormat} onChange={e => onChange({ ...field, numberFormat: e.target.value })} />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              {visType.type === 'table' ?
                <EuiFormRow label="单元格样式" display="rowCompressed">
                  <EuiSelect
                    options={ConditionalFormattingOptions}
                    value={(field.visType as VisTable)?.conditionalFormatting || 'default'}
                    onChange={e => {
                      const conditionalFormatting = e.target.value === 'default' ? undefined : e.target.value
                      onChange({
                        ...field,
                        visType: {
                          ...field.visType || {},
                          ...{ conditionalFormatting }
                        } as VisTable
                      })
                    }}
                    compressed
                  />
                </EuiFormRow> : undefined}
              {visType.type !== 'pie' && visType.type !== 'table' ?
                <EuiFormRow label="显示样式" display="rowCompressed">
                  <EuiSelect
                    options={visTypeOptions}
                    value={field.visType?.type || 'default'}
                    onChange={e => onChange({ ...field, visType: e.target.value === 'default' ? undefined : { type: e.target.value as VisType['type'] } })}
                    compressed
                  />
                </EuiFormRow> : undefined}
            </EuiFlexItem>
          </EuiFlexGroup>
          {visType.type !== 'pie' && visType.type !== 'table' ?
            <EuiFormRow label="Y轴" display="rowCompressed">
              <EuiSwitch
                label="使用右轴"
                checked={!!field.onSecondYAxis}
                onChange={(e) => { onChange({ ...field, onSecondYAxis: e.target.checked }) }}
              />
            </EuiFormRow> : null}
          <EuiFormRow label="聚合方式" display="rowCompressed">
            <EuiSelect
              options={AggregateOptions}
              value={field.aggMethod || 'last'}
              onChange={e => onChange({ ...field, aggMethod: e.target.value as AggMethod })}
              compressed />
          </EuiFormRow>
        </EuiForm>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export default FieldEditor
