import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule } from '@elastic/eui'
import _ from 'lodash'
import FormulaEditor from '../../../../Components/FormulaEditor'
import { InitialFieldCount } from '../../../../Pages/ExplorePageStore'
import { GenericVisField, GenericVisPreference, VisType } from '../../types'
import ExpandedFieldEditor from './ExpandedFieldEditor'

type ExpandedDataFieldProps = {
  visType: VisType
  field: GenericVisField
  onChange: (field: GenericVisField) => void
}

const ExpandedDataField = ({
  visType,
  field,
  onChange
}: ExpandedDataFieldProps) => {
  return <>
    <EuiFormRow
      className="rz-compressed-form"
      label="名称"
      display="columnCompressed"
    >
      <EuiFlexGroup direction='row' gutterSize='m'>
        <EuiFlexItem>
          <EuiFieldText
            compressed
            value={field.label}
            onChange={e => onChange({ ...field, label: e.target.value })} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <ExpandedFieldEditor field={field} visType={visType} onChange={onChange} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
    <EuiFormRow
      className="rz-compressed-form"
      label="公式"
      display="columnCompressed"
    >
      <FormulaEditor
        compressed
        isEmptyValid
        initialValue={field.expr}
        onChange={value => {
          onChange({ ...field, expr: value })
        }} />
    </EuiFormRow>
  </>
}

type ExpandedDataFieldsProps = {
  fields: GenericVisPreference['fields']
  visType: VisType
  onChange: (fields: GenericVisPreference['fields']) => void
}

const ExpandedDataFields = ({
  fields,
  visType,
  onChange
}: ExpandedDataFieldsProps) => {
  return <>
    {[...new Array(InitialFieldCount)].map((ignore, i) =>
      <div key={i}>
        <ExpandedDataField
          field={fields[i]}
          visType={visType}
          onChange={field => {
            const newFields = _.clone(fields)
            newFields[i] = field
            onChange(newFields)
          }} />
        <EuiHorizontalRule margin="xs" />
      </div>
    )}
  </>
}

export default ExpandedDataFields
