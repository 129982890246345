import moment from 'moment'

export type DateHierarchy = 'year' | 'quarter' | 'month' | 'week' | 'day'

export const DateHierarchyLevel: { [h in DateHierarchy]: number } = {
  'year': 1,
  'quarter': 2,
  'month': 3,
  'week': 4,
  'day': 5
}

export const DateHierarchyToken: { [h in DateHierarchy]: string } = {
  'year': 'YYYY',
  'quarter': '[Q]Q',
  'month': 'MM',
  'week': 'W',
  'day': 'DD'
}

export const InvalidDate = 'Invalid Date'

export const normalizedDateHierarchies = (hierarchies: DateHierarchy[]) => {
  return hierarchies.slice().sort((a, b) => DateHierarchyLevel[a] - DateHierarchyLevel[b])
}

const guessHierarchies = (str: string): DateHierarchy[] => {
  const parseChunk = (chunks: string[], history: DateHierarchy[] = []): DateHierarchy[] => {
    if (chunks.length === 0) {
      return history
    }
    const chunk = chunks[0]
    if (chunk.length === 4) {
      return parseChunk(chunks.slice(1), [...history, 'year'])
    } else if (chunk.startsWith('Q')) {
      return parseChunk(chunks.slice(1), [...history, 'quarter'])
    } else if (chunk.length === 2) {
      return history.includes('month') ? parseChunk(chunks.slice(1), [...history, 'day'])
        : parseChunk(chunks.slice(1), [...history, 'month'])
    }
    return history
  }
  return parseChunk(str.split(' ')[0].split('-'))
}

export class HierarchicalDate {
  public readonly hierarchies: DateHierarchy[]

  private constructor(public readonly date: Date | undefined, hierarchies: DateHierarchy[]) {
    this.hierarchies = normalizedDateHierarchies(hierarchies)
  }

  private formatDate(date?: Date) {
    date = date || this.date
    return this.isValid() ? moment(date).format('YYYY-MM-DD') : InvalidDate
  }

  static from(date: Date, hierarchies: DateHierarchy[] = ['year', 'month', 'day']) {
    return new HierarchicalDate(date, hierarchies)
  }

  static fromString(str: string, hierarchies?: DateHierarchy[]) {
    if (hierarchies === undefined) {
      hierarchies = guessHierarchies(str)
    }
    const format = normalizedDateHierarchies(hierarchies).map(h => DateHierarchyToken[h]).join('-')
    const date = format ? moment(str, format).toDate() : new Date(InvalidDate)
    return new HierarchicalDate(date, hierarchies)
  }

  isValid() {
    return this.date && !isNaN(this.date.valueOf())
  }

  toString() {
    return `HierarchicalDate(${this.formatDate()}, [${this.hierarchies.join(', ')}])`
  }

  format(hierarchies?: DateHierarchy[]) {
    hierarchies = hierarchies || this.hierarchies
    return this.isValid() ? moment(this.date).format(hierarchies.map(h => DateHierarchyToken[h]).join('-')) : InvalidDate
  }
}
