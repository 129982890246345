import { EuiButtonEmpty, EuiEmptyPrompt } from '@elastic/eui'
import { Fragment } from 'react'
import { pageStoreActions } from '../Stores/PageStore'
import { usePrivilege } from '../Stores/UserStore'
import DelayedRender from './DelayRender'

const PageRootEmptyPrompt = ({ uneditable }: { uneditable?: boolean }) => {
  const hasWritePagesPrivilege = usePrivilege('WritePages')
  return <DelayedRender delay={300}>
    <EuiEmptyPrompt
      iconType="addDataApp"
      iconColor={undefined}
      title={<h2>页面为空</h2>}
      titleSize="xs"
      body={
        <Fragment>
          <p>
            可根据组件信息区域的大小，来创建<b>『盒子』</b>。横向排列的盒子数量最多四个，最少一个。
          </p>
          <p>可使用<b>标题</b>来纵向划分区块。</p>
        </Fragment>
      }
      actions={
        hasWritePagesPrivilege && !uneditable ?
          <EuiButtonEmpty size="s" color="primary" onClick={() => pageStoreActions.setEditMode(true)}>
            编辑页面
          </EuiButtonEmpty>
          : null
      }
    />
  </DelayedRender>
}

export default PageRootEmptyPrompt
