import { DatasetComponentOption } from 'echarts'
import _ from 'lodash'
import { useMemo } from 'react'
import { GenericSolidData, VisBar, VisLine, VisPie, VisScatter } from '../types'
import { hasSecondYAxis, isHollowFields, isSeasonType } from '../utils'
import DataTable from '../utils/DataTable'
import setupColor from './color'
import EChart from './EChart'
import OverlapTitleForSearch from './OverlapTitle'
import series from './series'
import setupTitle from './title'
import toolbox from './toolbox'
import tooltip from './tooltip'
import xAxis from './xAxis'
import yAxis from './yAxis'

const setupDataset = (props: GenericChartData): [DatasetComponentOption, DataTable] => {
  let dt = DataTable.from({
    dateRange: props.dateRange,
    fields: props.fields.map(f => _.pick(f, ['label', 'expr', 'aggMethod', 'values'])),
    xAxis: props.xAxis.index,
    legend: props.legend.index
  })
  const r: [DatasetComponentOption, DataTable] = [dt.toDataset(), dt]
  if (isSeasonType(props)) {
    // ensure there is 01-01 and 12-31 in x axis
    const dataset = r[0] as any
    if (dataset.source[0] && dataset.source[0]['_month-day'] !== '01-01') {
      dataset.source.unshift({ '_month-day': '01-01' })
    }
    if (dataset.source[dataset.source.length - 1] && dataset.source[dataset.source.length - 1]['_month-day'] !== '12-31') {
      dataset.source.push({ '_month-day': '12-31' })
    }
    // set date year for time axis
    for (const row of dataset.source) {
      row['_month-day'] = '2000-' + row['_month-day']
    }
  }
  return r
}

const appendSecondYAxisLabel = (labels: string[], onSecondYAxis: (boolean | undefined)[]): string[] => {
  return labels.map((label, index) => {
    return onSecondYAxis[index] ? (label + '(右轴)') : label
  })
}

const setupLegend = (props: GenericChartData, dataset: any): echarts.EChartsOption['legend'] => {
  if (props.xAxis.index === 'field' && props.legend.index === 'field') {
    // 一维数据只有饼图才显示legend
    if (props.visType.type === 'pie' && dataset.source.length > 1) {
      return { bottom: 0, data: dataset.source.map((r: any) => r._field) }
    } else {
      return { show: false }
    }
  }

  // on second y axis
  let data = dataset.dimensions.slice(1)
  if (hasSecondYAxis(props)) {
    data = appendSecondYAxisLabel(data, props.fields.map(field => field.onSecondYAxis))
  }

  return { bottom: 0, data }
}

export type GenericChartData = Omit<GenericSolidData, 'visType'> & {
  visType: VisLine | VisBar | VisPie | VisScatter
}

const getChartOption = (props: GenericChartData): echarts.EChartsOption => {
  // skip setup option if fields data is not loaded
  if (isHollowFields(props.fields)) {
    return {}
  }

  const [dataset, dt] = setupDataset(props)
  const option: echarts.EChartsOption = {
    dataset,
    toolbox: toolbox(props),
    legend: setupLegend(props, dataset),
    tooltip: tooltip(props),
    xAxis: xAxis(props),
    yAxis: yAxis(props, dataset),
    series: series(props, dataset),
    title: setupTitle(props, dt),
    backgroundColor: '#fff'
  }
  option.color = setupColor(props, option.series)
  return option
}

const useInitOpts = (height: number | undefined) => {
  return useMemo(() => {
    return { height: height ?? 350, locale: 'ZH' }
  }, [height])
}

const useTitle = (option: echarts.EChartsOption) => {
  return useMemo(() => {
    let title = option.title
    if (title) {
      if (Array.isArray(title)) {
        title = title[0]
      }
      return title?.text
    }
  }, [option.title])
}

export type GenericChartProps = {
  data: GenericChartData
  isLoading?: boolean
  height?: number
}

const GenericChart = ({ isLoading, data, height }: GenericChartProps) => {
  const option = getChartOption(data)
  const initOpts = useInitOpts(height)
  const title = useTitle(option)
  return <>
    <EChart
      isLoading={isLoading}
      option={option}
      initOpts={initOpts} />
    <OverlapTitleForSearch title={title} />
  </>
}

export default GenericChart
