const numberUtil = require('echarts/lib/util/number')

export const getPrecision = numberUtil.getPrecision
export const niceNum = numberUtil.nice
export const round = numberUtil.round

export type NiceTicksResult = {
  min: number
  max: number
  interval: number
}

export const niceTicks = (min: number, max: number, ticks: number = 6): NiceTicksResult => {
  const span = max - min
  const interval = niceNum(span / (ticks - 1), true)
  const precision = getPrecision(interval) + 2
  min = round(Math.floor(min / interval) * interval, precision)
  max = round(Math.ceil(max / interval) * interval, precision)
  return {
    min,
    max,
    interval
  }
}

export const niceAlign = (left: NiceTicksResult | undefined, right: NiceTicksResult | undefined) => {
  // Guarder
  if (!left || !right) {
    return [left, right]
  }

  // Align zero axis and ticks
  if (left.max * left.min < 0 && right.max * right.min < 0) {
    const negticks = Math.max(-left.min / left.interval, -right.min / right.interval)
    const posticks = Math.max(left.max / left.interval, right.max / right.interval)
    return [
      {
        min: -negticks * left.interval,
        max: posticks * left.interval,
        interval: left.interval
      },
      {
        min: -negticks * right.interval,
        max: posticks * right.interval,
        interval: right.interval
      },
    ]
  }

  // Only align ticks
  const lticks = (left.max - left.min) / left.interval
  const rticks = (right.max - right.min) / right.interval
  const ticks = Math.max(lticks, rticks)
  return [
    {
      ...left,
      max: left.min + ticks * left.interval
    },
    {
      ...right,
      max: right.min + ticks * right.interval
    }
  ]
}
