import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui'
import EditableTabs from '../Components/EditableTabs'
import { useSubheader } from '../Stores/UiStore'
import ChartPanel from '../Widgets/GenericVisual/Editor/ChartPanel/ChartPanel'
import { DateRangePanel } from '../Widgets/GenericVisual/Editor/DataPanel/DataPanel'
import ExpandedDataFields from '../Widgets/GenericVisual/Editor/DataPanel/ExpandedDataFields'
import { TemplateSelect } from '../Widgets/GenericVisual/Editor/GenericEditor'
import { GenericVisPreview } from '../Widgets/GenericVisual/GenericVis'
import { GenericVisContext, useConsolidatedPref, useGenericVisState } from '../Widgets/GenericVisual/GenericVisStore'
import { GenericVisPreference } from '../Widgets/GenericVisual/types'
import { useExplorePageState } from './ExplorePageStore'

type ExplorePageContentProps = {
  initialValue: GenericVisPreference
  onChange: (value: GenericVisPreference) => void
}

const ExplorePageContent = ({ initialValue, onChange }: ExplorePageContentProps) => {
  const state = useGenericVisState(initialValue, onChange)
  const solidData = useConsolidatedPref(state.pref, state.fieldData)

  return (
    <div style={{ paddingLeft: 12, paddingRight: 12 }}>
      <GenericVisContext.Provider value={state}>
        <EuiFlexGroup gutterSize="m" style={{ minHeight: 470 }}>
          <EuiFlexItem grow={2}>
            <DateRangePanel />
            <EuiPanel paddingSize="s" style={{ overflowY: 'auto', marginTop: 6 }} grow={true}>
              <EuiFlexGroup justifyContent="spaceBetween" gutterSize="none" alignItems="baseline" >
                <EuiFlexItem><EuiText>数据项列表</EuiText></EuiFlexItem>
              </EuiFlexGroup>
              <ExpandedDataFields fields={state.pref.fields} visType={state.pref.visType} onChange={state.actions.setFields} />
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={6}>
            <TemplateSelect />
            <EuiSpacer size="s" />
            <GenericVisPreview data={solidData} size="l" />
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <ChartPanel />
          </EuiFlexItem>
        </EuiFlexGroup>
      </GenericVisContext.Provider>
    </div>
  )
}

const ExplorePage = () => {
  const { state, actions } = useExplorePageState()

  const tabs = <EditableTabs
    tabs={state.tabs}
    activeTabId={state.activeTabId}
    onChange={actions.setActiveId}
    onEdit={actions.setTabs}
  />
  useSubheader(tabs)
  return <>
    {state.activeTabId &&
      <ExplorePageContent
        key={state.activeTabId}
        initialValue={state.prefs[state.activeTabId]}
        onChange={actions.setActivePref} />}
  </>
}

export default ExplorePage
