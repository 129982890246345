import { EuiFieldText, EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiSpacer, EuiText } from '@elastic/eui'
import { useContext } from 'react'
import { GenericVisContext } from '../../GenericVisStore'
import { AxisIndex, VisType } from '../../types'
import AxisIndexSelect from './AxisIndexSelect'

export const ConditionalFormattingOptions = [
  { text: '无', value: 'default' },
  // { text: '渐变填充', value: 'gradientFill' },
  { text: '比例填充', value: 'solidFill' },
  // { text: '色阶', value: 'colorScales' }
]

const ChartTypeOptions = [
  { text: '折线', value: 'line' },
  { text: '柱形', value: 'bar' },
  { text: '散点', value: 'scatter' },
  // TODO: 完成饼图的slicer
  // { text: '饼', value: 'pie' },
  { text: '表格', value: 'table' }
]

const DefaultTableHead = 5

const hasYear = (index: AxisIndex) => {
  return index !== 'field' && index.includes('year')
}

const CustomPanel = () => {
  const {pref, actions} = useContext(GenericVisContext)
  return (
    <EuiPanel style={{ overflowY: 'auto' }} paddingSize="s">
      <EuiText>图表</EuiText>
      <EuiForm>
        <EuiFormRow label="标题" display="rowCompressed">
          <EuiFieldText
            value={pref.title}
            onChange={e => actions.setTitle(e.target.value)}
            compressed />
        </EuiFormRow>
        <EuiFormRow label="样式" display="rowCompressed">
          <EuiSelect
            options={ChartTypeOptions}
            value={pref.visType.type}
            onChange={e => {
              const visType: VisType = {
                type: e.target.value as VisType['type']
              }
              if (visType.type === 'table') {
                visType.head = DefaultTableHead
              }
              actions.setVisType(visType)
            }}
            compressed
          />
        </EuiFormRow>
        <EuiFormRow label="横坐标" display="rowCompressed">
          <AxisIndexSelect
            value={pref.xAxis.index}
            withYear={!hasYear(pref.legend.index)}
            onChange={actions.setXAxisIndex}
            placeholder="年月日"
            compressed />
        </EuiFormRow>
        <EuiSpacer size="s" />
        <EuiFormRow label={pref.visType.type === 'table' ? '纵坐标' : '图例'} display="rowCompressed">
          <AxisIndexSelect
            value={pref.legend.index}
            withYear={!hasYear(pref.xAxis.index)}
            onChange={actions.setLegendIndex}
            placeholder="数据项"
            compressed />
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  )
}

export default CustomPanel
