import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'

export type { ExclusiveUnion } from '@elastic/eui'

const isModifiedEvent = (event: React.MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const isLeftClickEvent = (event: React.MouseEvent) => event.button === 0;

function navigateTo(event: React.MouseEvent, history: ReturnType<typeof useHistory>, to: string) {
  if (event.defaultPrevented) {
    return;
  }
  if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
    return;
  }
  // Prevent regular link behavior, which causes a browser refresh.
  event.preventDefault();

  // Push the route to the history.
  history.push(to);
}

export function useRouteClick(to: string) {
  const history = useHistory()
  return useCallback((event: React.MouseEvent) => navigateTo(event, history, to), [history, to])
}

export function getLatestTradeDay() {
  const workday = moment.utc().startOf('day')
  const day = workday.day()
  if (day === 0) {  // is Sunday
    return workday.subtract(2, 'days')
  } else if (day === 6) { // is Saturday
    return workday.subtract(1, 'days')
  } else {
    return workday
  }
}

export function usePrevious<T = any>(value: T, initialValue: T) {
  const ref = useRef<T>(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useEffectDebugger = (effectHook: React.EffectCallback, dependencies: React.DependencyList, dependencyNames: React.DependencyList = []) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency
        }
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies)
}

export const scrollToBottom = (div: HTMLElement | null) => {
  if (div) {
    div.scrollTop = div.scrollHeight - div.clientHeight 
  }
}
