import { EuiSwitch } from '@elastic/eui'
import { useEffect, useMemo, useState } from 'react'

type DingOauthParams = {
  appid: string
  redirect_uri: string
  state?: string
}

const DingScanListener = ({ appid, redirect_uri, state }: DingOauthParams) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent<string>) => {
      const origin = event.origin
      if (origin === 'https://login.dingtalk.com') {
        const loginTmpCode = event.data
        const url = new URL('https://oapi.dingtalk.com/connect/oauth2/sns_authorize')
        const params = new URLSearchParams()
        params.set('appid', appid)
        params.set('response_type', 'code')
        params.set('scope', 'snsapi_login')
        if (state) {
          params.set('state', state)
        }
        params.set('redirect_uri', redirect_uri)
        params.set('loginTmpCode', loginTmpCode)
        url.search = params.toString()
        window.location.href = url.href
      }
    }
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [appid, redirect_uri, state])
  return <></>
}

const useGotoUrl = ({ appid, redirect_uri, state }: DingOauthParams) => {
  return useMemo(() => {
    const url = new URL('https://oapi.dingtalk.com/connect/oauth2/sns_authorize')
    const params = new URLSearchParams()
    params.set('appid', appid)
    params.set('response_type', 'code')
    params.set('scope', 'snsapi_login')
    params.set('redirect_uri', redirect_uri)
    if (state) {
      params.set('state', state)
    }
    url.search = params.toString()
    return url.href
  }, [appid, redirect_uri, state])
}

type DingLoginQRCodeProps = {
  appid: string
  redirect_uri: string
  state?: string
  style?: string
  width?: number
  height?: number
}

const DingLoginQRCode = ({
  appid,
  redirect_uri,
  state,
  style = "border:none;background-color=#fff;",
  width = 300,
  height = 300
}: DingLoginQRCodeProps) => {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)

  const goto = useGotoUrl({ appid, redirect_uri, state })

  useEffect(() => {
    if (containerEl) {
      const iframe = document.createElement("iframe")
      const url = new URL('https://login.dingtalk.com/login/qrcode.htm')
      const params = new URLSearchParams()
      params.set('goto', goto)
      if (style) {
        params.set('style', style)
      }
      url.search = params.toString()
      iframe.src = url.toString()
      iframe.width = width + 'px'
      iframe.height = height + 'px'
      containerEl.innerHTML = ''
      containerEl.appendChild(iframe)
    }
  }, [containerEl, goto, height, style, width])

  return <div ref={setContainerEl}>
    <DingScanListener appid={appid} redirect_uri={redirect_uri} state={state} />
  </div>
}

const DingLoginForm = () => {
  const [rememberMe, setRemeberMe] = useState(false)
  const appid = process.env.REACT_APP_DING_APPID!
  const redirect_uri = process.env.REACT_APP_DING_REDIRECT_URI!
  const state = useMemo(() =>
    JSON.stringify({
      rememberMe,
      platform: 'dingtalk',
    }), [rememberMe])
  return <>
    <DingLoginQRCode appid={appid} redirect_uri={redirect_uri} state={state} />
    <EuiSwitch
      label="下次自动登录"
      checked={rememberMe}
      onChange={e => setRemeberMe(e.target.checked)}
    />
  </>
}

export default DingLoginForm
