import { useEffect } from 'react'
import create from 'zustand'
import _ from 'lodash'
import { ChangePasswordDialog } from '../Components/ChangePasswordDialog'
import {
  ConfirmDialog, ConfirmDialogProps, InputFileDialog, InputFileDialogProps, InputFileStepsDialog,
  InputFileStepsDialogProps, InputTextareaDialog, InputTextDialog, InputTextDialogProps
} from '../Components/Dialogs'
import { InputWidgetDialog, InputWidgetDialogProps } from '../Components/InputWidgetDialog'

type InputWidgetDialogArgs = Omit<InputWidgetDialogProps, "dismiss">

type ConfirmArgs = Omit<ConfirmDialogProps, "dismiss">
type InputTextArgs = Omit<InputTextDialogProps, "dismiss">
type InputFileArgs = Omit<InputFileDialogProps, "dismiss">
type InputFileStepsArgs = Omit<InputFileStepsDialogProps, "dismiss">

type DialogParams = {
  dlg: (x: any) => JSX.Element,
  args: any
}

export const useUiStore = create(() => ({
  key: 0,
  dialogs: [] as DialogParams[],
  subHeader: null as JSX.Element | null,
  docTitle: null as string | null,
}))

export const useDocTitle = () => {
  return useUiStore(state => state.docTitle)
}

export const useSetDocTitle = (title: string) => {
  useEffect(() => {
    uiStoreActions.setDocTitle(title)
    return () => {
      uiStoreActions.setDocTitle('')
    }
  }, [title])
}

export const useSubheader = (subheader: JSX.Element | null) => {
  useEffect(() => {
    uiStoreActions.setSubheader(subheader)
    return () => {
      uiStoreActions.setSubheader(null)
    }
  }, [subheader])
}

function addDialog(dlg: (x: any) => JSX.Element, args: any) {
  const state = useUiStore.getState()
  const key = state.key + 1
  useUiStore.setState({
    key,
    dialogs: [...state.dialogs, {
      dlg,
      args: { ...args, key, dismiss: () => removeDialog(key) }
    }]
  })
}

function removeDialog(key: number) {
  const state = useUiStore.getState()
  useUiStore.setState({
    dialogs: _.filter(state.dialogs, x => x.args.key !== key)
  })
}

export const uiStoreActions = {
  setDocTitle(title: string | null) {
    useUiStore.setState({ docTitle: title })
    if (title) {
      document.title = `润洲数据 - ${title}`
    } else {
      document.title = '润洲数据'
    }
  },
  setSubheader(subheader: JSX.Element | null) {
    useUiStore.setState({ subHeader: subheader })
  },
  confirm(args: ConfirmArgs) {
    addDialog(ConfirmDialog, args)
  },
  inputText(args: InputTextArgs) {
    addDialog(InputTextDialog, args)
  },
  inputTextarea(args: InputTextArgs) {
    addDialog(InputTextareaDialog, args)
  },
  inputFileList(args: InputFileArgs) {
    addDialog(InputFileDialog, args)
  },
  inputFileListSteps(args: InputFileStepsArgs) {
    addDialog(InputFileStepsDialog, args)
  },
  inputWidget(args: InputWidgetDialogArgs) {
    addDialog(InputWidgetDialog, args)
  },
  changePassword() {
    addDialog(ChangePasswordDialog, {})
  }
}