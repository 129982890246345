import { useState, useEffect } from 'react'
import { BehaviorSubject } from 'rxjs'
import { useOriginalObjectIfUnchanged } from '../Utils/Miscs'
import { createHub, useHubIsConnected } from '../Utils/RemoteMessageHub'

const hub = createHub('/api/subscription', true)

const priceSubscriptions = new Map<string, BehaviorSubject<number>>()

function getPrice(code: string) {
  let priceSubject = priceSubscriptions.get(code)
  if (!priceSubject) {
    priceSubject = new BehaviorSubject(NaN)
    priceSubscriptions.set(code, priceSubject)
  }
  return priceSubject
}

hub.on('OnPrice', (code, price) => {
  getPrice(code).next(price)
})

function getCurrentPrices(codes: string[]) {
  return new Map(codes.map(code => [code, getPrice(code).value]))
}

export function useSubscriptionHubState() {
  return useHubIsConnected(hub)
}

export function useRealtimePrices(codes: string[]) {
  const [value, setValue] = useState(() => getCurrentPrices(codes))
  const refedCodes = useOriginalObjectIfUnchanged(codes)
  useEffect(() => {
    refedCodes.forEach(c => subscribePrice(c))
    const subscriptions = refedCodes.map(
      code => getPrice(code).subscribe(() => setValue(getCurrentPrices(refedCodes)))
    )
    return () => {
      for (const s of subscriptions) {
        s.unsubscribe()
      }
      refedCodes.forEach(c => unsubscribePrice(c))
    }
  }, [refedCodes])
  return value
}

///////////////////////////////////////////////////////////////////////////////
// Actions
//
async function connectHub() {
  await hub.connect()
}

async function disconnectHub() {
  await hub.disconnect()
}

function subscribePrice(code: string) {
  hub.send('SubscribePrice', code)
}

function unsubscribePrice(code: string) {
  hub.send('UnsubscribePrice', code)
}

export const subscriptionActions = {
  connectHub,
  disconnectHub,
  subscribePrice,
  unsubscribePrice
}